import React from "react";
import IndicatorContentOnlineHistory from "@/pages/admin/dashboard/indicator-content-online-history.tsx";
import { Col, Row, Space } from "antd";
import IndicatorContentOnline from "@/pages/admin/dashboard/indicator-content-online.tsx";

const Page: React.FC = () => {
  return (
    <Space style={{ width: "100%", overflowY: "auto", overflowX: "hidden" }} direction="vertical">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <IndicatorContentOnline />
        </Col>
        <Col span={24}>
          <IndicatorContentOnlineHistory />
        </Col>
      </Row>
    </Space>
  );
};

export default Page;
