import React from "react";
import { BankOutlined, UserOutlined } from "@ant-design/icons";

type EmbedIconProps = {
  type: "department" | "position";
  style?: React.CSSProperties;
};

const OrgStructureIcon: React.FC<EmbedIconProps> = ({ type, ...props }) => {
  switch (type) {
    case "department":
      return <BankOutlined {...props} />;
    case "position":
      return <UserOutlined {...props} />;
    default:
      throw new Error("Unsupported type");
  }
};

export default OrgStructureIcon;
export type { EmbedIconProps };
