import React from "react";
import { Result, theme } from "antd";

const Page: React.FC = () => {
  const {
    token: { sizeMD, colorBgContainer, borderRadius },
  } = theme.useToken();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `${sizeMD}px`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: colorBgContainer,
      }}
    >
      <Result
        status={"warning"}
        title={"Ты находишься в админке"}
        subTitle={
          "Будь осторожен и внимателен, любая ошибка может привести к непредвиденным последствиям"
        }
      />
    </div>
  );
};

export default Page;
