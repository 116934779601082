import React, { useEffect, useState } from "react";
import { Col, Flex, Row, Space } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import useMe from "@/entities/me/lib/use";
import axios from "@/axios";
import { Dashboard } from "@/models";
import { AxiosRequestConfig } from "axios";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { ProForm, ProFormProps } from "@ant-design/pro-components";
import { debounce } from "lodash";
import IndicatorsSelectModal from "@/pages/space/manage/dashboards/indicators-select-modal.tsx";
import { indicators } from "@/pages/space/manage/dashboards/indicators.tsx";

const Page: React.FC = () => {
  const me = useMe();

  const [error, setError] = useState<Error | null>(null);

  const [form] = ProForm.useForm<Dashboard>();

  const defaultDashboard: Partial<Dashboard> = {
    member_id: me.id,
    settings: {
      latest_added_members: { is_visible: false },
      completed_education_members: { is_visible: false },
      education_ending_tomorrow: { is_visible: false },
      overdue_education: { is_visible: false },
      employee_attendance: {
        is_visible: true,
        interval: 30,
        group_by: "day",
      },
      general_education_statistics: {
        is_visible: true,
        interval: 30,
      },
      system_users: { is_visible: true },
      education_statistics_by_department: {
        is_visible: true,
        interval: 30,
      },
    },
  };

  const request: ProFormProps<Dashboard>["request"] = async () => {
    const axiosConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/dashboards/search",
      data: {
        filters: [{ field: "member_id", operator: "=", value: me.id }],
      },
    };

    return await axios
      .request<OrionRestIndexResponse<Dashboard>>(axiosConfig)
      .then(async ({ data }) => {
        if (data.data.length > 1) {
          throw new Error(
            "Поддержка нескольких дашбордов в данный момент недоступна.",
          );
        } else if (data.data.length === 1) {
          return data.data[0];
        } else {
          return await axios
            .post<OrionRestCreateResponse<Dashboard>>(
              "/api/dashboards",
              defaultDashboard,
            )
            .then(({ data }) => data.data);
        }
      })
      .catch((err) => {
        setError(err);
        throw err;
      });
  };

  const onFinish = async () => {
    const values = form.getFieldsValue(true);
    if (values.id === undefined) return;
    return await axios
      .put<OrionRestUpdateResponse<Dashboard>>(
        `/api/dashboards/${values.id}`,
        values,
      )
      .then(() => {
        return true;
      })
      .catch((err) => {
        setError(err);
      });
  };

  const onFinishDebounce = debounce(onFinish, 250);

  const settings = ProForm.useWatch("settings", form);
  useEffect(() => {
    if (!settings) return;
    onFinishDebounce();
  }, [settings]);

  if (error) throw error;

  return (
    <Space
      direction={"vertical"}
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <AutoBreadcrumb />
      <ProForm<Dashboard>
        form={form}
        request={request}
        onFinish={onFinish}
        submitter={false}
        layout={"horizontal"}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Flex justify="end">
              <IndicatorsSelectModal />
            </Flex>
          </Col>
          {indicators.map((indicator) => {
            return React.createElement(indicator.component, {
              key: indicator.name,
            });
          })}
        </Row>
      </ProForm>
    </Space>
  );
};

export default Page;
