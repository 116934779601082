import React from "react";
import { FloatButton, Layout as AntLayout } from "antd";
import { Outlet } from "react-router-dom";
import SentryUserFeedbackModal from "@/shared/sentry/ui/user-feedback-modal.tsx";
import { BugOutlined } from "@ant-design/icons";

const Layout: React.FC = () => {
  return (
    <>
      <SentryUserFeedbackModal
        modal
        trigger={<FloatButton icon={<BugOutlined />} />}
      />
      <AntLayout
        style={{
          minHeight: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Outlet />
      </AntLayout>
    </>
  );
};

export default Layout;
