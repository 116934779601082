import axios from "@/axios";
import { UploadRequestOption } from "rc-upload/lib/interface";

const uploadRequest = async (options: UploadRequestOption) => {
  // @ts-ignore
  const name = options.file.name;

  const putObject = (await axios.post(`/api/filesystem/s3`, { name })).data
    .data;

  await axios.put(putObject.url, options.file, {
    authorization: false,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total!,
      );
      options.onProgress!({ percent: percentCompleted });
    },
  });

  const getObject = (await axios.get(`/api/filesystem/s3/${putObject.key}`))
    .data.data;

  options.onSuccess!({
    key: putObject.key,
    url: getObject.url,
    name,
  });
};

export default uploadRequest;
