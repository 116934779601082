import React from "react";
import {
  ProForm,
  ProFormCheckbox,
  ProFormFieldProps,
} from "@ant-design/pro-components";
import { Button, Flex, Typography } from "antd";
import ExportButton from "@/entities/export/ui/button";
import { CheckboxGroupProps } from "antd/es/checkbox";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";

type ReportFormProps = {
  filters: any[];
  scopes?: any[];
  exportType:
    | "export_personnel_changes_for_report"
    | "export_participants_for_report"
    | "export_members_for_report";
  rangeFormField?: { name: ProFormFieldProps["name"]; value: string[] };
  columnsOption: CheckboxGroupProps["options"];
  defaultCheckedColumns?: string[] | undefined;
  onReset?: () => void;
  sorter?: Record<string, any>;
};

const ReportForm: React.FC<ReportFormProps> = ({
  rangeFormField,
  columnsOption,
  defaultCheckedColumns,
  exportType,
  scopes: defaultScopes,
  filters: defaultFilters,
  onReset,
  sorter,
}) => {
  const [form] = ProForm.useForm();
  const columns = ProForm.useWatch("columns", form);
  const [filters, setFilters] = React.useState<any[]>(defaultFilters);
  const [scopes, setScopes] = React.useState<any[]>(defaultScopes ?? []);
  const rangeFieldName = Array.isArray(rangeFormField?.name)
    ? rangeFormField?.name[1]
    : rangeFormField?.name;

  const queryFilters = {
    filters: filters.filter((filter) => {
      if (filter.nested?.length === 0) return false;
      return true;
    }),
    scopes,
    sort: sorter
      ? [sorter]
      : exportType === "export_members_for_report"
      ? [{ field: "created_at", direction: "desc" }]
      : [],
  };

  return (
    <ProForm
      submitter={false}
      preserve={false}
      form={form}
      initialValues={{
        columns:
          defaultCheckedColumns ||
          columnsOption?.flatMap((value) => {
            if (typeof value === "object" && value.value) {
              return [value.value];
            } else {
              return [];
            }
          }),
        [rangeFieldName]: rangeFormField?.value,
      }}
    >
      {rangeFormField && (
        <DateTimeRangePickerWithTimeControls
          name={rangeFieldName}
          label={<Typography.Text strong>Период от - до</Typography.Text>}
          labelCol={{ span: 24 }}
          fieldProps={{
            format: dateSTime,
            allowEmpty: [true, true],
            onChange: (values: any) => {
              // @ts-ignore
              const range = normalizeDateRange(values);

              if (
                Array.isArray(rangeFormField.name) &&
                rangeFormField.name[0] === "scopes"
              ) {
                const updateScopes = scopes.map((scope) => {
                  if (scope.name === rangeFormField.name[1]) {
                    scope.parameters = range;
                  }
                  return scope;
                });
                setScopes(updateScopes);
              } else {
                const updateFilters = filters.map((filter) => {
                  if (filter.field === rangeFormField.name) {
                    if (filter.operator === ">=") {
                      filter.value = range[0];
                    } else if (filter.operator === "<=") {
                      filter.value = range[1];
                    }
                  }
                  return filter;
                });

                setFilters(updateFilters);
              }
            },
          }}
          normalize={normalizeDateRange}
        />
      )}

      <ProFormCheckbox.Group
        name={"columns"}
        label={<Typography.Text strong>Столбцы</Typography.Text>}
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Минимальное количество столбцов - 1",
          },
        ]}
        layout="vertical"
        options={columnsOption}
      />
      <Flex justify={"end"} gap={8}>
        <Button key={"cancel"} onClick={() => onReset?.()}>
          Отменить
        </Button>
        <ExportButton
          type={exportType}
          key={"export-csv"}
          metaFields={{
            type: "csv",
            columns: form.getFieldValue("columns"),
            filters: queryFilters,
          }}
          trigger={
            <Button disabled={!columns || !columns.length} type={"primary"}>
              Выгрузить .csv
            </Button>
          }
        />
        <ExportButton
          type={exportType}
          metaFields={{
            type: "xlsx",
            columns: form.getFieldValue("columns"),
            filters: queryFilters,
          }}
          key={"export-xlsx"}
          trigger={
            <Button disabled={!columns || !columns.length} type={"primary"}>
              Выгрузить .xlsx
            </Button>
          }
        />
      </Flex>
    </ProForm>
  );
};
export default ReportForm;
export type { ReportFormProps };
