import React, { useCallback, useState } from "react";
import {
  Button,
  Col,
  Flex,
  message,
  Modal,
  Row,
  Space,
  Tabs as AntdTabs,
  TabsProps,
  theme,
  Typography,
  UploadFile,
  Spin,
} from "antd";
import { Member, MemberAccessDepartment, MemberPosition } from "@/models";
import useMe from "@/entities/me/lib/use";
import {
  ModalForm,
  ModalFormProps,
  ProDescriptions,
  ProForm,
  ProFormDigit,
  ProFormList,
  ProFormProps,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import { FieldData } from "rc-field-form/es/interface";
import {
  OrionRestBatchCreateResponse,
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useSpace from "@/entities/space/lib/use";
import useQueryTabs from "@/shared/hooks/use-query-tab";
import PoolParticipantTable from "@/entities/pool-participant/ui/table";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import useFeatures from "@/entities/features/lib/use.ts";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import PersonalFormFields from "./personal-form-fields";

const Tabs = styled(AntdTabs)`
  .ant-tabs-content {
    height: 100%;
  }
`;

const SpinWrapper = styled.div`
  height: 100%;
  width: 100%;

  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin-container {
    height: 100%;
  }
`;

type FormData = Omit<Member, "avatar_image_file"> & {
  avatar_image_file: (File & UploadFile)[] | null;
};
type Props = ProFormProps<FormData> & {
  modal?: boolean;
  mode?: "default" | "personal";
  rest: RestProps<Member>;
  memberId?: number;
};

const MemberForm: React.FC<Props> = ({
  modal = false,
  mode = "default",
  rest,
  ...props
}) => {
  const features = useFeatures();
  const { t } = useTranslation();
  const member = useMe();
  const space = useSpace();

  const [form] = ProForm.useForm<FormData>(props.form);
  const [toDeleteOrgStructureAssignments, setToDeleteOrgStructureAssignments] =
    React.useState<MemberPosition[]>([]);
  const [toDeleteAccessedDepartments, setToDeleteAccessedDepartments] =
    React.useState<MemberAccessDepartment["id"][]>([]);
  const memberPositions = ProForm.useWatch("positions", form);
  const accessedDepartments = ProForm.useWatch("accessed_departments", form);

  const [toRemoveFileIds, setToRemoveFileIds] = useState<number[]>([]);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalMember, modalMemberHolder] = Modal.useModal();
  const { token } = theme.useToken();

  const setLoadingState = (isLoading: boolean) => setLoading(isLoading);

  const onDeleteAccessedDepartment = (id: MemberAccessDepartment["id"]) => {
    setToDeleteAccessedDepartments((prev) => [...prev, id]);
  };

  const onAvatarImageRemove = (file: any) => {
    setToRemoveFileIds((prev) => [...prev, file.id]);
  };

  const showUserModalOnCreate = (member: Member) => {
    modalMember.info({
      title: `Информация о созданном пользователе`,
      okText: "Скопировать все и закрыть",
      onOk: () => {
        let copiedData = `ФИО: ${member.full_name}`;

        if (member.email) {
          copiedData += `\nПочта: ${member.email}`;
        }

        if (member.snils) {
          copiedData += `\nСНИЛС: ${member.snils}`;
        }

        if (member.username) {
          copiedData += `\nЛогин: ${member.username}`;
        }
        copy(copiedData);
        message.info("Данные скопированы в буфер обмена");
      },
      width: "60%",
      icon: null,
      content: (
        <Space direction="vertical">
          <ProDescriptions
            column={1}
            dataSource={member}
            layout="horizontal"
            columns={[
              {
                dataIndex: "full_name",
                title: <Typography.Text>ФИО</Typography.Text>,
              },
              {
                dataIndex: "snils",
                hideInDescriptions: !member.snils,
                title: <Typography.Text>СНИЛС</Typography.Text>,
              },
              {
                dataIndex: "email",
                hideInDescriptions: !member.email,
                title: <Typography.Text>Почта</Typography.Text>,
              },
              {
                dataIndex: "username",
                hideInDescriptions: !member.username,
                title: <Typography.Text>Логин</Typography.Text>,
              },
            ]}
          />
        </Space>
      ),
    });
  };

  const findDuplicateOrgStructureEntries = ({
    entries,
    type,
  }: {
    entries: Array<MemberPosition | MemberAccessDepartment>;
    type: "position" | "department";
  }) => {
    const idToIndexMap = {} as Record<string, number>;
    const duplicateIndexes: number[] = [];

    const idKey = type === "position" ? "position_id" : "department_id";

    entries.forEach((item, index) => {
      let id;
      if (type === "position" && idKey === "position_id") {
        id = (item as MemberPosition)[idKey];
      } else if (type === "department" && idKey === "department_id") {
        id = (item as MemberAccessDepartment)[idKey];
      }
      if (!id) return;
      if (id in idToIndexMap) {
        duplicateIndexes.push(index);
      }
      idToIndexMap[id] = index;
    });

    if (duplicateIndexes.length > 0) {
      form.setFields(
        duplicateIndexes.map(
          (index: number): FieldData => ({
            name: [
              type === "position" ? "positions" : "accessed_departments",
              index,
              idKey,
            ],
            errors: [
              type === "position"
                ? t(
                    "Должность дублируется, выберите другое Должность или удалите поле",
                  )
                : t(
                    "Подразделение дублируется, выберите другой Подразделение или удалите поле",
                  ),
            ],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  const syncAvatarImageFile = async (values: FormData) => {
    const { avatar_image_file } = values;
    if (avatar_image_file) {
      for (const toRemoveFileId of toRemoveFileIds) {
        await axios.delete(`/api/files/${toRemoveFileId}`);
        setToRemoveFileIds([]);
      }
      for (let i = 0; i < avatar_image_file.length; i++) {
        const item: any = avatar_image_file[i];
        if (!item.originFileObj || (item.status && item.status !== "done"))
          continue;
        const key = item.response.key;
        await axios
          .post(`/api/files`, {
            key,
            name: item.name,
            type: item.type,
            attached_to_id: values.id,
            attached_to_type: "member",
          })
          .then(({ data }) => (avatar_image_file[i] = data.data))
          .then((file) => {
            return axios.put<OrionRestUpdateResponse<Member>>(
              `/api/members/${values.id}`,
              { ...values, avatar_image_file_id: file.id },
            );
          });
      }
    }
  };

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    initialValues: {
      is_restricted_access: false,
    },
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    defaultProps.onFinish = async (values) => {
      return await axios
        .post<OrionRestCreateResponse<Member>>("/api/members", values)
        .then(async (res) => {
          message.success("Пользователь успешно создан");
          rest.onAfterCreate?.(res.data.data);
          await syncAvatarImageFile({ ...values, id: res.data.data.id });
          showUserModalOnCreate(res.data.data);
          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании пользователя",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Member>>(`/api/members/${rest.recordKey}`, {
          params: {
            includes: [
              { relation: "positions" },
              { relation: "avatar_image_file" },
              { relation: "accessed_departments" },
            ],
          },
        })
        .then((res) => {
          return {
            ...res.data.data,
            avatar_image_file: res.data.data.avatar_image_file
              ? [{ ...res.data.data.avatar_image_file, status: "done" }]
              : null,
          } as FormData;
        });
    };
    defaultProps.onFinish = async (values) => {
      if (
        values.positions &&
        findDuplicateOrgStructureEntries({
          entries: values.positions,
          type: "position",
        })
      ) {
        return false;
      }
      if (
        values.accessed_departments &&
        findDuplicateOrgStructureEntries({
          entries: values.accessed_departments,
          type: "department",
        })
      ) {
        return false;
      }
      setIsSubmitButtonLoading(true);

      return axios
        .put<OrionRestUpdateResponse<Member>>(
          `/api/members/${rest.recordKey}`,
          { ...values },
        )
        .catch((err) => {
          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
            setActiveTab(
              Object.keys(err.response.data.errors)[0].startsWith("education")
                ? "education"
                : "personal",
            );
          }

          throw err;
        })
        .then(async (res) => {
          if (toDeleteOrgStructureAssignments?.length) {
            await axios
              .delete(`/api/member-positions/batch`, {
                data: {
                  resources: toDeleteOrgStructureAssignments.map((el) => el.id),
                },
              })
              .then(() => {
                setToDeleteOrgStructureAssignments([]);
              });
          }
          if (toDeleteAccessedDepartments?.length) {
            await axios
              .delete(`/api/member-access-departments/batch`, {
                data: {
                  resources: toDeleteAccessedDepartments,
                },
              })
              .then(() => {
                setToDeleteAccessedDepartments([]);
              });
          }
          return res;
        })
        .then(async (res) => {
          if (memberPositions) {
            const toCreateOrgStructureAssignments = memberPositions.filter(
              (el) => !el.id,
            );
            if (toCreateOrgStructureAssignments.length > 0) {
              await axios.post<OrionRestBatchCreateResponse<MemberPosition>>(
                `/api/member-positions/batch`,
                {
                  resources: toCreateOrgStructureAssignments.map((el) => {
                    return {
                      id: el.id ?? null,
                      member_id: el.member_id ?? null,
                      position_id: el.position_id ?? null,
                    };
                  }),
                },
              );
            }
          }

          if (accessedDepartments) {
            const toCreateAccessedDepartments = accessedDepartments.filter(
              (el) => !el.id,
            );
            if (toCreateAccessedDepartments.length === 0) return res;

            await axios.post<OrionRestBatchCreateResponse<MemberPosition>>(
              `/api/member-access-departments/batch`,
              {
                resources: toCreateAccessedDepartments.map((el) => {
                  return {
                    member_id: el.member_id ?? null,
                    department_id: el.department_id ?? null,
                  };
                }),
              },
            );
          }

          return res;
        })
        .then(async (res) => {
          await syncAvatarImageFile({ ...values, id: res.data.data.id });
          return res;
        })
        .then(async (res) => {
          // @ts-ignore
          form.setFieldsValue(await defaultProps.request());
          message.success("Пользователь успешно обновлен");
          rest.onAfterUpdate?.(res.data.data);
          if (res.data.data.id === member.id) {
            member.refresh();
          }
          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении пользователя: ${messageText}`);

          return false;
        })
        .finally(() => setIsSubmitButtonLoading(false));
    };
  }

  // -------------------------------- Pre Render -------------------------------- //

  const FormComponent = modal ? ModalForm<FormData> : ProForm<FormData>;
  props = deepmerge(defaultProps, props, overrideProps);

  // -------------------------------- Type Modal -------------------------------- //

  if (modal) {
    const triggerText = rest.type === "create" ? "Создать" : "Редактировать";
    const disabled = rest.type === "update" && rest.recordKey === undefined;

    const modalFormProps: Partial<ModalFormProps<Member>> = {
      title:
        rest.type === "create"
          ? "Создание пользователя"
          : "Редактирование пользователя",
      trigger: (
        <Button type={"primary"} disabled={disabled}>
          {triggerText}
        </Button>
      ),
      modalProps: {
        destroyOnClose: true,
      },
    };

    props = deepmerge(modalFormProps, props);
  }

  // -------------------------------- Render -------------------------------- //

  const employeeEditableSelf =
    mode === "personal"
      ? !(
          space.is_allow_employee_edit_self_member ||
          member.permissions.includes("member:update")
        )
      : rest.type === "update"
      ? !member.permissions.includes("member:update")
      : !member.permissions.includes("member:create");

  const employeeEditableOrgStructure =
    rest.type === "update"
      ? !member.permissions.includes("member:update")
      : !member.permissions.includes("member:create");

  const Submitter: React.FC<{ disabled?: boolean }> = useCallback(
    ({ disabled }) => {
      return (
        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          <Button
            disabled={disabled}
            type={"primary"}
            loading={isSubmitButtonLoading}
            onClick={async () => {
              try {
                await form.validateFields();
              } catch (error: any) {
                const errorFieldName = error.errorFields[0].name[0];
                if (errorFieldName.startsWith("education")) {
                  setActiveTab("education");
                } else if (errorFieldName.startsWith("positions")) {
                  setActiveTab("positions");
                } else if (activeTab !== "personal") {
                  setActiveTab("personal");
                }
                return;
              }
              form.submit();
            }}
          >
            {rest.type === "create" ? "Создать" : "Сохранить"}
          </Button>
        </Flex>
      );
    },
    [isSubmitButtonLoading, form],
  );

  const PositionsList: React.FC = useCallback(() => {
    return (
      <ProFormList
        label={rest.type === "create" && "Работа"}
        min={rest.type === "create" ? 1 : 0}
        max={5}
        name={"positions"}
        initialValue={rest.type === "create" ? [{}] : undefined}
        creatorRecord={{
          // @ts-ignore
          member_id: rest.recordKey ?? undefined,
        }}
        creatorButtonProps={{
          creatorButtonText: "Добавить запись о работе",
          type: "primary",
          block: false,
          icon: false,
          style: {
            marginLeft: "4px",
            display: !employeeEditableOrgStructure ? "block" : "none",
          },
        }}
        copyIconProps={false}
        deleteIconProps={false}
        onAfterRemove={async (index) => {
          if (
            memberPositions &&
            typeof index === "number" &&
            memberPositions[index].id
          ) {
            setToDeleteOrgStructureAssignments((prev) => [
              ...prev,
              memberPositions[index],
            ]);
          }
        }}
      >
        {(_, index, action) => {
          const position = memberPositions?.[index];
          const disabled =
            member.is_restricted_access && position?.position_id
              ? !member.accessed_positions.includes(position.position_id)
              : employeeEditableOrgStructure;
          return (
            <ProForm.Group>
              <ProFormDigit name={"id"} hidden />
              <ProFormDigit name={"member_id"} hidden />
              <Flex style={{ width: "100%" }} align="start">
                <Row
                  style={{ width: "100%" }}
                  gutter={token.paddingXXS}
                  align={index === 0 ? "middle" : "top"}
                >
                  <Col span={23}>
                    <OrgStructureTreeSelect
                      disabled={disabled}
                      labelWithPath
                      label={t("Должность")}
                      name={"position_id"}
                      rules={[{ required: true }]}
                      disabledDepartments
                    />
                  </Col>

                  <Col span={1}>
                    <Button
                      disabled={
                        disabled ||
                        (rest.type === "create" &&
                          memberPositions?.length === 1)
                      }
                      icon={<DeleteOutlined />}
                      style={{ marginTop: token.marginXXS }}
                      size="small"
                      type="text"
                      title="Удалить"
                      onClick={() => action.remove(index)}
                    />
                  </Col>
                </Row>
              </Flex>
            </ProForm.Group>
          );
        }}
      </ProFormList>
    );
  }, [memberPositions, employeeEditableOrgStructure, member]);

  const tabs: TabsProps["items"] = [
    ...(mode !== "personal" &&
    rest.type === "update" &&
    member.permissions.includes("pool_participant:view")
      ? [
          {
            key: "pools",
            style: { height: "100%" },
            label: "Назначенное обучение",
            children: (
              <PoolParticipantTable
                hasQueryParams
                memberId={Number(rest.recordKey)}
                options={{
                  setting: {
                    draggable: false,
                    showListItemOption: false,
                  },
                  reload: false,
                  density: false,
                }}
                columnsState={{
                  persistenceKey: `pool-participant-member-table`,
                }}
                toolBarActionsProps={{
                  create: { triggerProps: { style: { display: "none" } } },
                  remove: { children: "Удалить обучение" },
                }}
                columns={[
                  { dataIndex: "registry_number" },
                  {
                    dataIndex: ["pool", "course", "id"],
                    render: (dom, { pool: { course } }) =>
                      course.status == "deleted" ? (
                        <Typography.Text>{dom}</Typography.Text>
                      ) : (
                        <Link to={`/manage/courses/${course.id}`}>{dom}</Link>
                      ),
                    hideInSetting: true,
                    disable: true,
                  },
                  {
                    dataIndex: ["pool", "id"],
                    render: (dom, { pool: { course, ...pool } }) =>
                      course.status === "deleted" ? (
                        <Typography.Text>{dom}</Typography.Text>
                      ) : (
                        <Link
                          to={`/manage/courses/${course.id}/pools/${pool.id}`}
                        >
                          {dom}
                        </Link>
                      ),
                    hideInSetting: true,
                    disable: true,
                  },
                  { dataIndex: "status" },
                  { dataIndex: "progress_percent" },
                  { dataIndex: "starts_at" },
                  { dataIndex: "started_at" },
                  { dataIndex: "ends_at" },
                  { dataIndex: "ended_at" },
                ]}
              />
            ),
          },
        ]
      : []),

    {
      key: "personal",
      label: "Личная информация",
      style: { height: "100%" },
      forceRender: true,
      children: (
        <Space
          direction="vertical"
          style={{
            padding: token.paddingMD,
            height: "100%",
            overflowY: "auto",
            paddingTop: 0,
            width: "100%",
          }}
        >
          <ProForm
            style={{ height: "100%" }}
            disabled={employeeEditableSelf}
            form={form}
            grid={true}
            {...props}
          >
            <Submitter disabled={employeeEditableSelf} />
            <PersonalFormFields
              setLoadingState={setLoadingState}
              rest={rest}
              mode={mode}
              onAvatarImageRemove={onAvatarImageRemove}
              onDeleteAccessedDepartment={onDeleteAccessedDepartment}
            />
          </ProForm>
        </Space>
      ),
    },
    {
      key: "positions",
      label: "Работа",
      style: { height: "100%" },
      forceRender: true,
      children: (
        <Space
          direction={"vertical"}
          style={{
            width:
              mode === "personal"
                ? "calc(100vw - 32px)"
                : "calc(100vw - 200px - 32px)",
            maxWidth:
              mode === "personal"
                ? "calc(1920px - 32px)"
                : "calc(1920px - 200px - 32px)",
            overflowY: "auto",
            height: "100%",
            padding: token.paddingMD,
            paddingTop: 0,
          }}
        >
          <ProForm
            disabled={employeeEditableOrgStructure}
            form={form}
            style={{ width: "100%" }}
            grid={true}
            {...props}
          >
            <Submitter disabled={employeeEditableOrgStructure} />
            <Row gutter={token.paddingXS} style={{ width: "100%" }}>
              <Col span={24}>
                <PositionsList />
              </Col>
            </Row>
          </ProForm>
        </Space>
      ),
    },
    {
      key: "education",
      label: "Образование",
      style: { height: "100%" },
      forceRender: true,
      children: (
        <Space
          direction="vertical"
          style={{
            padding: token.paddingMD,
            overflowY: "auto",
            height: "100%",
            paddingTop: 0,
            width: "100%",
          }}
        >
          <ProForm
            disabled={employeeEditableSelf}
            form={form}
            grid={true}
            {...props}
          >
            <Submitter disabled={employeeEditableSelf} />
            <Row gutter={token.paddingXS} style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={12}>
                <ProFormText
                  label="Уровень образования"
                  name="education_level"
                  rules={[{ required: false, max: 255 }]}
                  hidden={rest.type !== "update"}
                />
                <ProFormText
                  label="Серия диплома"
                  name="education_diploma_series"
                  rules={[{ required: false, max: 255, min: 10 }]}
                  hidden={rest.type !== "update"}
                />
                <ProFormText
                  label="Номер диплома"
                  name="education_diploma_number"
                  rules={[{ required: false }]}
                  hidden={rest.type !== "update"}
                />
                <ProFormText
                  label="Фамилия в дипломе"
                  name="education_diploma_last_name"
                  rules={[{ required: false, max: 255 }]}
                  hidden={rest.type !== "update"}
                />
              </Col>
            </Row>
          </ProForm>
        </Space>
      ),
    },

    ...(mode === "personal" && rest.type === "update"
      ? [
          {
            key: "settings",
            label: "Настройки",
            style: { height: "100%" },
            children: (
              <Space
                direction="vertical"
                style={{
                  padding: token.paddingMD,
                  height: "100%",
                  overflowY: "auto",
                  paddingTop: 0,
                  width: "100%",
                }}
              >
                <ProForm form={form} grid={true} {...props}>
                  <Submitter />
                  <Row gutter={token.paddingXS} style={{ width: "100%" }}>
                    <Col xs={24} sm={24} md={12}>
                      <ProFormSelect
                        label="Тема"
                        name="theme"
                        options={[
                          { label: "Светлая", value: "light" },
                          { label: "Темная", value: "dark" },
                          { label: "Авто", value: "auto" },
                        ]}
                      />
                    </Col>
                  </Row>
                </ProForm>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const [activeTab, setActiveTab] = useQueryTabs(
    rest.type === "create"
      ? undefined
      : mode === "personal" ||
        !member.permissions.includes("pool_participant:view")
      ? "personal"
      : "pools",
    tabs,
  );

  return (
    <SpinWrapper>
      <Spin spinning={loading}>
        {rest.type === "create" && (
          <>
            {modalMemberHolder}
            <FormComponent
              form={form}
              grid={true}
              {...props}
              submitter={{
                resetButtonProps: false,
                searchConfig: {
                  submitText: "Создать",
                },
              }}
            >
              <PersonalFormFields
                setLoadingState={setLoadingState}
                mode={mode}
                rest={rest}
                onAvatarImageRemove={onAvatarImageRemove}
                onDeleteAccessedDepartment={onDeleteAccessedDepartment}
              />
              {features.isEnabled("restrict_accesses") &&
                member.is_restricted_access && <PositionsList />}
            </FormComponent>
          </>
        )}
        {rest.type === "update" && (
          <Tabs
            activeKey={activeTab}
            items={tabs}
            destroyInactiveTabPane={false}
            onChange={setActiveTab}
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </Spin>
    </SpinWrapper>
  );
};
export default MemberForm;
