import useFeatures from "@/entities/features/lib/use";
import useMe from "@/entities/me/lib/use";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import RolesSearchSelect from "@/entities/roles/select";
import useSpace from "@/entities/space/lib/use";
import ProFormPhone from "@/shared/ant-design-pro-components/form-phone/ui/component";
import ProFormSnils from "@/shared/ant-design-pro-components/form-snils/ui/component";
import { date, dateNormalize } from "@/shared/dayjs/lib/formats";
import emailPattern from "@/shared/patterns/lib/email-pattern";
import {
  ProForm,
  ProFormCheckbox,
  ProFormDatePicker,
  ProFormDigit,
  ProFormList,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { Button, Col, Flex, message, Row, theme } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Member, MemberAccessDepartment, RolePermission } from "@/models";
import { useCallback, useEffect, useState } from "react";
import UploadWithPreview from "@/entities/file/ui/upload-with-preview";
import { statusValueEnum } from "../lib/status-value-enum";
import axios from "@/axios";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { RestProps } from "@/shared/rest/lib/types";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";

type PersonalFormFieldsProps = {
  rest: RestProps<Member>;
  mode: "default" | "personal";
  onAvatarImageRemove: (file: any) => void;
  onDeleteAccessedDepartment: (id: MemberAccessDepartment["id"]) => void;
  setLoadingState: (isLoading: boolean) => void;
};

const PersonalFormFields: React.FC<PersonalFormFieldsProps> = ({
  rest,
  mode,
  onAvatarImageRemove,
  onDeleteAccessedDepartment,
  setLoadingState,
}) => {
  const form = ProForm.useFormInstance();
  const { token } = theme.useToken();
  const features = useFeatures();
  const space = useSpace();
  const member = useMe();
  const [uploadListType, setUploadListType] = useState<
    "text" | "picture-card" | undefined
  >(rest.type === "create" ? "picture-card" : undefined);
  const [incorrectImage, setIncorrectImage] = useState(false);
  const accessedDepartments = ProForm.useWatch("accessed_departments", form);
  const isRestrictedAccess = ProForm.useWatch("is_restricted_access", form);
  const roleId = ProForm.useWatch("role_id", form);
  const [roleHasPermissions, setRoleHasPermissions] = useState(false);
  const avatarImageFile = ProForm.useWatch("avatar_image_file", form);

  useEffect(() => {
    if (avatarImageFile && avatarImageFile.length > 0) {
      setUploadListType("text");
    } else {
      setUploadListType("picture-card");
    }
  }, [avatarImageFile]);

  const generateLogin = async () => {
    const generated = await axios
      .post<{ data: string }>(
        "/api/members/generate-username",
        form.getFieldsValue(),
      )
      .then(({ data }) => data.data)
      .catch((err) => {
        if (err.response.status === 422) {
          message.error(
            err.response.data.message ?? "Ошибка при генерации логина",
          );
          setValidationErrorsToFormFields(form, err.response.data.errors);
        }

        return "";
      });
    form.setFieldsValue({ username: generated });
  };

  const onRemove = (file: any) => {
    if (incorrectImage) {
      setIncorrectImage(false);
    }
    if (file.id) onAvatarImageRemove(file);
  };

  useEffect(() => {
    if (
      roleId &&
      member.permissions.includes("role:view") &&
      features.isEnabled("restrict_accesses")
    ) {
      setLoadingState(true);
      axios
        .post<OrionRestIndexResponse<RolePermission>>(
          `/api/role-permissions/search`,
          {
            filters: [{ field: "role_id", operator: "=", value: roleId }],
          },
        )
        .then(({ data }) => setRoleHasPermissions(data.data.length > 0))
        .finally(() => setLoadingState(false));
    }
  }, [roleId]);

  const employeeEditableSelf =
    mode === "personal"
      ? !(
          space.is_allow_employee_edit_self_member ||
          member.permissions.includes("member:update")
        )
      : rest.type === "update"
      ? !member.permissions.includes("member:update")
      : !member.permissions.includes("member:create");

  const employeeEditableOrgStructure =
    rest.type === "update"
      ? !member.permissions.includes("member:update")
      : !member.permissions.includes("member:create");

  const AvatarUploadButton: React.FC = useCallback(() => {
    return (
      <UploadWithPreview
        type="button"
        name="avatar_image_file"
        label="Фотография профиля"
        max={1}
        fieldProps={{
          listType: uploadListType,
          onRemove,
          accept: ".png, .jpg, .jpeg",
        }}
        title="Загрузить"
        rules={[
          { required: false },
          () => ({
            validator(_, files) {
              if (files) {
                for (const file of files) {
                  if (incorrectImage) {
                    return Promise.reject(
                      new Error("Файл поврежден или не является изображением"),
                    );
                  }
                  if (!file.type) {
                    return Promise.reject(
                      new Error("Не удалось определить тип файла"),
                    );
                  }

                  if (file.originFileObj) {
                    const img = new Image();
                    img.src = URL.createObjectURL(file.originFileObj!);
                    img.onerror = () => {
                      if (!incorrectImage) {
                        setIncorrectImage(true);
                      }
                    };
                  }

                  const isImage = [
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                  ].includes(file.type);

                  if (!isImage) {
                    return Promise.reject(
                      new Error(
                        "Недопустимый тип файла: Можно загружать файлы с расширением PNG, JPG или JPEG",
                      ),
                    );
                  }
                }
              }
              if (incorrectImage) {
                setIncorrectImage(false);
              }

              return Promise.resolve();
            },
          }),
        ]}
      />
    );
  }, [incorrectImage, uploadListType]);

  return (
    <Row
      gutter={token.paddingXS}
      style={{ width: "100%", height: "calc(100% - 32px)" }}
    >
      <Col
        xs={24}
        sm={24}
        md={rest.type === "update" ? 12 : 24}
        style={{ height: "100%" }}
      >
        <ProFormText
          label={"Фамилия"}
          name={"last_name"}
          rules={[
            { max: 255, required: true },
            {
              pattern: /^[\p{L}-]*$/u,
              message: "Некорректное значение поля Фамилия",
            },
          ]}
          transform={(value) => value.trim()}
        />

        <ProFormText
          label={"Имя"}
          name={"first_name"}
          transform={(value) => value.trim()}
          rules={[
            { max: 255, required: true },
            {
              pattern: /^[\p{L}-]*$/u,
              message: "Некорректное значение поля Имя",
            },
          ]}
        />

        <ProFormText
          transform={(value) => value.trim()}
          label={"Отчество"}
          name={"middle_name"}
          rules={[
            { max: 255, required: false },
            {
              pattern: /^[\p{L}-]*$/u,
              message: "Некорректное значение поля Отчество",
            },
          ]}
        />
        <ProFormDatePicker
          label="Дата рождения"
          name="birth_date"
          rules={[
            { required: features.isEnabled("apk_usernames_and_passwords") },
          ]}
          hidden={
            !features.isEnabled("apk_usernames_and_passwords") &&
            rest.type !== "update"
          }
          fieldProps={{
            format: date,
            style: { width: "100%" },
            needConfirm: false,
          }}
          normalize={dateNormalize}
        />
        <ProFormRadio.Group
          label="Пол"
          name="gender"
          rules={[{ required: false }]}
          hidden={rest.type !== "update"}
          options={[
            { value: "male", label: "Мужской" },
            { value: "female", label: "Женский" },
          ]}
          fieldProps={{ optionType: "button", buttonStyle: "solid" }}
        />

        <ProFormText
          label="Гражданство"
          name="citizenship"
          rules={[{ required: false, max: 255 }]}
          hidden={rest.type !== "update"}
        />
        {rest.type === "update" && <AvatarUploadButton />}
      </Col>

      <Col
        xs={24}
        sm={24}
        md={rest.type === "update" ? 12 : 24}
        style={{ height: "100%" }}
      >
        <ProFormSnils
          disabled={employeeEditableSelf}
          label={"СНИЛС"}
          name={"snils"}
          style={{ padding: `0 8px` }}
          rules={[{ required: false }]}
        />
        <ProFormPhone
          disabled={employeeEditableSelf}
          label={"Телефон"}
          name={"phone"}
          style={{ padding: `0 8px` }}
          rules={[{ required: false }]}
          hidden={rest.type !== "update"}
        />
        <ProFormText
          label={"Почта"}
          name={"email"}
          rules={[
            {
              required: space.authentication_types.includes("email+password"),
            },
            {
              type: "email",
              message: "Атрибут email должен быть действительным email",
            },
            { pattern: emailPattern, message: "Некорректный формат почты" },
          ]}
        />
        <Flex vertical style={{ position: "relative", width: "100%" }}>
          <Button
            type="link"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
              padding: 0,
              paddingBottom: 4,
            }}
            onClick={generateLogin}
          >
            Сгенерировать
          </Button>
          <ProFormText
            label={"Логин"}
            name={"username"}
            rules={[
              {
                required:
                  space.authentication_types.includes("username+password"),
                max: 255,
              },
            ]}
          />
        </Flex>
        {(member.permissions.includes("member:update") ||
          member.permissions.includes("member:create")) &&
          mode === "default" && (
            <ProFormSelect
              label="Статус"
              name="status"
              rules={[{ required: rest.type === "update" }]}
              hidden={rest.type !== "update"}
              valueEnum={statusValueEnum}
            />
          )}
        <RolesSearchSelect
          label="Роль"
          name="role_id"
          hidden={!member.permissions.includes("member:manage_accesses")}
          rules={[
            {
              required: member.permissions.includes("member:manage_accesses"),
            },
          ]}
        />

        {features.isEnabled("restrict_accesses") &&
          roleHasPermissions &&
          member.permissions.includes("member:manage_accesses") && (
            <>
              <ProFormCheckbox
                rules={[{ required: true }]}
                name="is_restricted_access"
              >
                Изоляция доступа по подразделениям
              </ProFormCheckbox>
              {isRestrictedAccess && (
                <ProFormList
                  creatorButtonProps={{
                    block: false,
                    type: "default",
                    creatorButtonText: "Добавить",
                    style: {
                      marginLeft: "4px",
                      display: !employeeEditableOrgStructure ? "block" : "none",
                    },
                  }}
                  name="accessed_departments"
                  creatorRecord={{
                    member_id:
                      rest.type === "update" ? rest.recordKey : undefined,
                  }}
                  copyIconProps={false}
                  deleteIconProps={false}
                  onAfterRemove={async (index) => {
                    if (
                      accessedDepartments &&
                      typeof index === "number" &&
                      accessedDepartments[index].id
                    ) {
                      onDeleteAccessedDepartment(accessedDepartments[index].id);
                    }
                  }}
                >
                  {(_, index, action) => {
                    const department = accessedDepartments?.[index];

                    const disabled =
                      member.is_restricted_access && department?.department_id
                        ? !member.accessed_departments.includes(
                            department.department_id,
                          )
                        : employeeEditableOrgStructure;
                    return (
                      <>
                        <ProFormDigit name={"id"} hidden />
                        <ProFormDigit name={"member_id"} hidden />
                        <Flex style={{ width: "100%" }} align="start">
                          <Row
                            style={{ width: "100%" }}
                            gutter={token.paddingXXS}
                            align={"top"}
                          >
                            <Col span={23}>
                              <OrgStructureTreeSelect
                                disabled={
                                  action.getCurrentRowData()?.id || disabled
                                }
                                departmentOnly
                                rules={[{ required: true }]}
                                labelWithPath
                                name={"department_id"}
                              />
                            </Col>
                            <Col span={1}>
                              <Button
                                disabled={disabled}
                                icon={<DeleteOutlined />}
                                style={{ marginTop: token.marginXXS }}
                                size="small"
                                type="text"
                                title="Удалить"
                                onClick={() => action.remove(index)}
                              />
                            </Col>
                          </Row>
                        </Flex>
                      </>
                    );
                  }}
                </ProFormList>
              )}
            </>
          )}
        {rest.type === "create" && <AvatarUploadButton />}
      </Col>
    </Row>
  );
};

export default PersonalFormFields;
