import React from "react";
import {
  ProDescriptions,
  ProList,
  ProListProps,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { PoolParticipant } from "@/models";
import useMe from "@/entities/me/lib/use";
import { Grid, theme, Typography } from "antd";
import "@/widgets/my-education/ui/list.css";
import { deepmerge } from "deepmerge-ts";
import { dateSTime, toISOString } from "@/shared/dayjs/lib/formats";
import CardHeader from "@/widgets/my-education/ui/card-header";
import dayjs from "dayjs";

type Entity = PoolParticipant;
type PoolParticipantsListProps = Pick<
  ProListProps<Entity>,
  "headerTitle" | "onItem"
>;

const { useBreakpoint } = Grid;

const baseImageUrl = "/default-course-image.jpg";

const MyEducationList: React.FC<PoolParticipantsListProps> = ({ ...props }) => {
  const me = useMe();
  const { token } = theme.useToken();
  const screens = useBreakpoint();

  const columnsCount = screens.xxl ? 4 : 3;
  const rowsCount = 2;
  const gutter = token.padding;
  const imageHeight = "200px";

  const defaultProps: ProListProps<Entity> = {
    request: ({ pageSize: limit, current: page }) => {
      const now = toISOString(dayjs());

      return axios
        .post(
          "/api/pool-participants/search",
          {
            includes: [{ relation: "pool.course.cover_image_file" }],
            filters: [
              { field: "member_id", operator: "=", value: me.id },
              { field: "pool.status", operator: "=", value: "started" },
              {
                type: "and",
                nested: [
                  { field: "ends_at", operator: ">", value: now, type: "or" },
                  {
                    field: "is_unlimited",
                    operator: "=",
                    value: true,
                    type: "or",
                  },
                ],
              },
              { field: "starts_at", operator: "<", value: now },
            ],
            sort: [
              { field: "status_order_index", direction: "asc" },
              { field: "created_at", direction: "desc" },
            ],
          },
          { params: { limit, page } },
        )
        .then((response) => ({
          data: response.data.data,
          total: response.data.meta.total,
          success: true,
        }));
    },
    pagination: {
      align: "center",
      pageSize: columnsCount * rowsCount,
      showSizeChanger: false,
      style: { background: token.colorBgBase, padding: token.padding },
    },
  };

  props = deepmerge(defaultProps, props);

  return (
    <ProList<PoolParticipant>
      ErrorBoundary={false}
      className={"my-education-list"}
      style={{
        margin: "0 auto",
        width: screens.sm && !screens.md ? "80%" : "100%",
      }}
      grid={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4, gutter }}
      ghost
      metas={{
        title: {
          render: (_, { status, pool }) => {
            const imageUrl =
              pool?.course?.cover_image_file?.url ?? baseImageUrl;
            return (
              <CardHeader
                imageHeight={imageHeight}
                imageUrl={imageUrl}
                status={status}
              />
            );
          },
          dataIndex: ["pool", "course", "name"],
        },
        content: {
          render: (_, record) => {
            return (
              <ProDescriptions<PoolParticipant>
                column={1}
                size={"small"}
                layout="vertical"
                dataSource={record}
                columns={[
                  {
                    style: { padding: 0 },
                    title: <Typography.Text strong>Прогресс</Typography.Text>,
                    dataIndex: "progress_percent",
                    valueType: "progress",
                    fieldProps: {
                      percent: Number(record.progress_percent).toFixed(2),
                    },
                  },
                  {
                    title: <Typography.Text strong>Обучение</Typography.Text>,
                    dataIndex: ["pool", "course", "name"],
                    render: (_, { pool }) => {
                      return (
                        <Typography.Text ellipsis={{ tooltip: true }}>
                          {pool?.course?.name}
                        </Typography.Text>
                      );
                    },
                  },
                  {
                    title: <Typography.Text strong>Начало</Typography.Text>,
                    dataIndex: "starts_at",
                    valueType: "dateTime",
                    fieldProps: { format: dateSTime },
                  },
                  {
                    title: <Typography.Text strong>Завершение</Typography.Text>,
                    dataIndex: "ends_at",
                    valueType: "dateTime",
                    fieldProps: { format: dateSTime },
                  },
                ]}
              />
            );
          },
        },
      }}
      {...props}
    />
  );
};

export default MyEducationList;
export type { PoolParticipantsListProps };
