import React, { useState } from "react";
import { FloatButton } from "antd";
import { useAuth } from "react-oidc-context";
import { CustomerServiceOutlined, CloseOutlined } from "@ant-design/icons";
import SupportDropdown from "@/entities/support/ui/dropdown";

const LayoutSupportButton: React.FC = () => {
  const auth = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <>
      {!auth.isAuthenticated && (
        <SupportDropdown
          placement="topLeft"
          onOpenChange={(open) => {
            setDropdownVisible(open);
          }}
          triggerElement={
            <FloatButton
              shape="square"
              icon={
                dropdownVisible ? (
                  <CloseOutlined />
                ) : (
                  <CustomerServiceOutlined />
                )
              }
              type="primary"
            />
          }
        />
      )}
    </>
  );
};

export default LayoutSupportButton;
