import React from "react";
import PoolParticipantForm, {
  PoolParticipantFormProps,
} from "@/entities/pool-participant/ui/form";
import { deepmerge } from "deepmerge-ts";
import { PoolParticipant } from "@/models";
import axios from "@/axios";
import { message } from "antd";

type PoolParticipantBatchUpdateFormProps = PoolParticipantFormProps & {
  poolParticipantsIds: PoolParticipant["id"][];
  afterUpdate?: (pool: PoolParticipant) => void;
};

const PoolParticipantBatchUpdateForm: React.FC<
  PoolParticipantBatchUpdateFormProps
> = ({ poolParticipantsIds, afterUpdate, ...props }) => {
  const defaultProps: Partial<PoolParticipantBatchUpdateFormProps> = {
    onFinish: (values) => {
      const resources: { [key: string]: PoolParticipant } = {};
      poolParticipantsIds.forEach((poolParticipantId) => {
        resources[String(poolParticipantId)] = values;
      });

      return axios
        .patch<{ data: PoolParticipant }>(`/api/pool-participants/batch`, {
          resources,
        })
        .then((res) => {
          message.success("Период обучения успешно изменен");
          afterUpdate?.(res.data.data);
          return Promise.resolve(true);
        })
        .catch((error) => {
          message.error(error.response.data.message);
        });
    },
    fieldsProps: {
      poolId: { hidden: true },
      membersIds: { hidden: true },
    },
  };

  return <PoolParticipantForm {...deepmerge(defaultProps, props)} />;
};

export default PoolParticipantBatchUpdateForm;
export type { PoolParticipantBatchUpdateFormProps };
