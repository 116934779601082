import React, { useState } from "react";
import { LearningMatrixEntry } from "@/models";
import {
  ProForm,
  ProFormDigit,
  ProFormList,
  ProFormProps,
} from "@ant-design/pro-components";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import { deepmerge } from "deepmerge-ts";
import { Col, Flex, message, Row, theme } from "antd";
import CourseInput from "@/entities/course/ui/select-with-external.tsx";
import axios from "@/axios";
import { AxiosRequestConfig } from "axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { FieldData } from "rc-field-form/es/interface";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import { useTranslation } from "react-i18next";

type CourseList = (Partial<LearningMatrixEntry> & {
  course_id_with_type: string;
})[];

type Record = {
  org_structure_id_with_type: string;
  course_list: CourseList;
};

type LearningMatrixEntryFormBatchProps = ProFormProps<Record> & {
  rest: {
    type: "update" | "create";
    recordKeys?: number[];
    onAfterFinish: () => void;
  };
};

const LearningMatrixEntryFormBatch: React.FC<
  LearningMatrixEntryFormBatchProps
> = ({ rest, ...props }) => {
  const [form] = ProForm.useForm<Record>();
  const courseList = ProForm.useWatch("course_list", form);
  const { t } = useTranslation();

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const [toRemoveLearningMatrixRecordIds, setToRemoveLearningMatrixRecordIds] =
    useState<number[]>([]);

  const { token } = theme.useToken();

  const checkDublicateCourse = (courses: CourseList) => {
    const indexesCourseList = {
      doubleCourseIndex: [],
    } as any;

    courses.forEach((item, index) => {
      if (!item.course_id_with_type) return;
      if (item.course_id_with_type in indexesCourseList) {
        indexesCourseList.doubleCourseIndex.push(index);
      }
      indexesCourseList[item.course_id_with_type] = index;
    });

    if (indexesCourseList.doubleCourseIndex.length > 0) {
      form.setFields(
        indexesCourseList.doubleCourseIndex.map(
          (index: number): FieldData => ({
            name: ["course_list", index, "course_id_with_type"],
            errors: ["Курс дублируется, выберите другой курс или удалите поле"],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  const getLearningMatrixRecordsByFilter = async (filter: {
    [key: string]: any;
  }) => {
    const axiosConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/learning-matrix-entries/search",
      ...axiosConfigAdapter(
        {
          pageSize: 20,
        },
        {},
        filter,
      ),
    };

    return await axios
      .request<OrionRestIndexResponse<LearningMatrixEntry>>(axiosConfig)
      .then(({ data }) => data.data)
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const defaultProps: Partial<typeof props> = {
    submitter: {
      searchConfig: {
        submitText: "Сохранить",
        resetText: "Отменить",
      },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  defaultProps.onFinish = async (values) => {
    const { course_list: courseList, org_structure_id_with_type } = values;

    const [org_structure_type, org_structure_id] =
      org_structure_id_with_type.split("#");

    const newValues = {
      org_structure_id: Number(org_structure_id),
      org_structure_type,
    };

    /** Check duplicate courses */

    if (checkDublicateCourse(courseList)) {
      return;
    }

    setIsSubmitButtonLoading(true);

    /** Create and update matrix records */

    const resourcesToCreate: LearningMatrixEntry[] = [];
    const resourcesToUpdate: { [key: string]: LearningMatrixEntry } = {};

    courseList.forEach((course) => {
      const [type, id] = course.course_id_with_type.split("#");
      const { retry_month_count } = course;

      const record = {
        ...newValues,
        course_id: Number(id),
        course_type: type,
        retry_month_count: retry_month_count ?? null,
      } as LearningMatrixEntry;

      if (course.id) {
        resourcesToUpdate[course.id] = record;
      } else {
        resourcesToCreate.push(record);
      }
    });

    /** Create matrix records */

    if (resourcesToCreate.length > 0) {
      await axios
        .post("/api/learning-matrix-entries/batch", {
          resources: resourcesToCreate,
        })
        .then((res) => {
          message.success("Записи успешно созданы");
        })
        .catch((error) => {
          console.log(error);
          message.error(
            error.response.data.message ?? "Ошибка при создании записей",
          );
        });
    }

    /** Update matrix records */

    if (Object.keys(resourcesToUpdate).length > 0) {
      await axios
        .patch("/api/learning-matrix-entries/batch", {
          resources: resourcesToUpdate,
        })
        .then((res) => {
          message.success("Записи успешно обновлены");
        })
        .catch((error) => {
          console.log(error);
          message.error(
            error.response.data.message ?? "Ошибка при обновлении записей",
          );
        });
    }

    /** Delete matrix records */

    if (toRemoveLearningMatrixRecordIds.length > 0) {
      await axios
        .delete(`/api/learning-matrix-entries/batch`, {
          data: { resources: toRemoveLearningMatrixRecordIds },
        })
        .then(() => {
          message.success("Записи успешно удалены");
          setToRemoveLearningMatrixRecordIds([]);
        })
        .catch((error) => {
          console.log(error);
          message.error(
            error.response.data.message ?? "Ошибка при удалении записей",
          );
        });
    }

    setIsSubmitButtonLoading(false);
    rest.onAfterFinish();

    return Promise.resolve(true);
  };

  /** REST Type Update request */

  if (rest.type === "update") {
    defaultProps.request = async () => {
      const filter = {
        id: rest.recordKeys,
      };

      const learningMatrixRecord =
        (await getLearningMatrixRecordsByFilter(filter)) ?? [];

      return {
        org_structure_id_with_type: `${learningMatrixRecord[0].org_structure_type}#${learningMatrixRecord[0].org_structure_id}`,
        course_list: learningMatrixRecord.map((item) => ({
          course_id_with_type: `${item.course_type}#${item.course_id}`,
          retry_month_count: item.retry_month_count,
          id: item.id,
        })),
      };
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <>
      <ProForm<Record> {...props}>
        <Flex style={{ width: "100%" }} justify="space-between">
          <Row gutter={token.paddingMD} style={{ width: "100%" }}>
            <Col span={24}>
              <OrgStructureTreeSelect
                label={`${t("Подразделение")} / ${t("Должность")}`}
                name={"org_structure_id_with_type"}
                rules={[{ required: true }]}
                labelWithPath
                withType
              />
            </Col>
          </Row>
        </Flex>

        <ProFormList
          style={{ marginTop: token.marginMD }}
          containerStyle={{
            /**         formGroup height
             *               |   max number formGroup no scroll
             *               |        |  add button height
             *               |        |     |
             *               |        |     |
             */
            maxHeight: "calc(87.5px * 5 + 32px)",
            overflow: "auto",
          }}
          alwaysShowItemLabel
          initialValue={rest.type === "create" ? [{}] : undefined}
          creatorButtonProps={{
            creatorButtonText: "Добавить еще",
            type: "primary",
            block: false,
            icon: false,
          }}
          copyIconProps={false}
          deleteIconProps={{ tooltipText: "Удалить" }}
          onAfterRemove={async (index) => {
            if (
              courseList &&
              typeof index === "number" &&
              courseList[index].id
            ) {
              setToRemoveLearningMatrixRecordIds((prevState) => [
                ...prevState,
                courseList[index].id!,
              ]);
            }
          }}
          name="course_list"
          min={1}
          max={20}
        >
          <ProForm.Group>
            <CourseInput
              rules={[{ required: true }]}
              width={"lg"}
              isActiveFilter
              label="Курс"
              name="course_id_with_type"
            />
            <ProFormDigit
              width={"sm"}
              min={1}
              label="Повторное обучение (мес)"
              name="retry_month_count"
            />
          </ProForm.Group>
        </ProFormList>
      </ProForm>
    </>
  );
};

export default LearningMatrixEntryFormBatch;
export type { LearningMatrixEntryFormBatchProps };
