import { Space as BaseSpace } from "@/models";

export const authenticationTypeEnum = {
  "email+password": "Почта и пароль",
  "username+password": "Логин и пароль",
};

export type AuthenticationTypesItem = keyof typeof authenticationTypeEnum;

export type AuthenticationTypes = AuthenticationTypesItem[];

export interface Space extends BaseSpace {
  authentication_types: AuthenticationTypes;
}
