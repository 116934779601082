import { useState } from "react";
import SupportChatModal from "../ui/chat-modal";

const useSupportChatModal = () => {
  const [open, setOpen] = useState(false);

  const modalHolder = (
    <SupportChatModal
      open={open}
      onCancel={() => setOpen(false)}
      onAfterClose={() => setOpen(false)}
    />
  );

  return [
    {
      open: () => {
        setOpen(true);
      },
    },
    modalHolder,
  ] as const;
};

export default useSupportChatModal;
