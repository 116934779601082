import React from "react";
import { useParams } from "react-router-dom";

const BreadcrumbItem: React.FC = () => {
  const { support_chat_iid } = useParams();

  return <>№ {support_chat_iid}</>;
};

export default BreadcrumbItem;
