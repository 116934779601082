import React from "react";
import { message } from "antd";
import {
  ProForm,
  ProFormDateTimePicker,
  ProFormDigit,
  ProFormProps,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { RestProps } from "@/shared/rest/lib/types";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { dateSTime, dateSTimeNormalize } from "@/shared/dayjs/lib/formats";
import { deepmerge } from "deepmerge-ts";
import { FeatureFlagsPackage, License } from "@/models";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import statusValueEnum from "@/entities/license/lib/status-value-enum";
import { AxiosRequestConfig } from "axios";

type LicenseUpdateProps = ProFormProps<License> & {
  rest: RestProps<License>;
};

const LicenseForm: React.FC<LicenseUpdateProps> = ({ rest, ...props }) => {
  const [form] = ProForm.useForm<License>();

  let defaultProps: Partial<typeof props> = { preserve: false };

  const overrideProps: Partial<typeof props> = { form };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Создать лицензию" } },
    });
    defaultProps.onFinish = async (values) => {
      return axios
        .post<OrionRestCreateResponse<License>>(`/api/licenses`, values)
        .then((res) => res.data.data)
        .then(async (values) => {
          message.success("Лицензия успешно создана");
          rest.onAfterCreate?.(values);
          return true;
        })
        .catch((err) => {
          console.error(err);
          message.error(
            err.response.data.message ?? "Ошибка при создании лицензии",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Сохранить" } },
    });
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<License>>(`/api/licenses/${rest.recordKey}`)
        .then((res) => res.data.data);
    };
    defaultProps.onFinish = async (values) => {
      return axios
        .put<OrionRestUpdateResponse<License>>(
          `/api/licenses/${rest.recordKey}`,
          values,
        )
        .then((res) => {
          message.success("Лицензия успешно обновлена");
          rest.onAfterUpdate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении лицензии: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        });
    };
  }

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  return (
    <ProForm<License> {...props}>
      <ProFormText name={"name"} label={"Название"} rules={[{ max: 255 }]} />
      <ProFormSelect
        name={"status"}
        label={"Статус"}
        valueEnum={statusValueEnum as any}
        rules={[{ required: false }]}
      />
      <ProFormDateTimePicker
        name={"expires_at"}
        label={"Дата-время истечения лицензии"}
        rules={[{ required: true }]}
        fieldProps={{
          format: dateSTime,
          style: { width: "100%" },
          needConfirm: false,
        }}
        normalize={dateSTimeNormalize}
      />
      {rest.type === "create" && (
        <ProFormText
          required={false}
          rules={[{ required: false }]}
          name={"space_uuid"}
          label="UUID Пространства"
        />
      )}
      <ProFormDigit
        label={"Максимальное количество активных участников"}
        name={"max_active_members"}
        min={1}
      />
      <ProFormDigit
        label={"Максимальный объем используемой памяти (байты)"}
        name={"max_memory_usage"}
      />
      <ProFormSelect
        name={"feature_flags_package_id"}
        label={"Пакет флагов функциональности"}
        mode={"single"}
        request={async (params) => {
          const config: AxiosRequestConfig = {
            method: "POST",
            url: `/api/feature-flags-packages/search`,
            data: {
              filters: [],
            },
          };

          if (params.keyWords) {
            config.data.filters.push({
              field: "name",
              operator: "ilike",
              value: `%${params.keyWords}%`,
            });
          }

          return axios
            .request<OrionRestIndexResponse<FeatureFlagsPackage>>(config)
            .then((response) => {
              return response.data.data.map((record) => {
                return { label: record.name, value: record.id };
              });
            });
        }}
        rules={[{ required: true }]}
        showSearch
      />
    </ProForm>
  );
};

export default LicenseForm;
export type { LicenseUpdateProps };
