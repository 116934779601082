import useMe from "@/entities/me/lib/use";
import useSpace from "@/entities/space/lib/use";
import Spin from "@/shared/components/spin";

type Props = {
  children: React.ReactNode;
};

const ContextDepsLoader: React.FC<Props> = ({ children }) => {
  const space = useSpace();
  const me = useMe();

  if (space.isLoading || me.isLoading) {
    return <Spin />;
  }

  return <>{children}</>;
};

export default ContextDepsLoader;
