import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="Не найдено"
      extra={[
        <Button key="home" onClick={() => navigate("/")}>
          Домой
        </Button>,
      ]}
    />
  );
};

export default NotFoundPage;
