import React from "react";
import { Course, PoolTemplate } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import ProTable, {
  ProTableProps as BaseProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import PoolTemplateDeleteButton from "@/entities/pool-template/ui/delete-button";
import PoolTemplateModalForm from "@/entities/pool-template/ui/modal-form.tsx";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import CourseSearchSelect from "@/entities/course/ui/select";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";
import ArchivedCourseIcon from "@/entities/course/ui/archived-icon";
import DeletedCourseIcon from "@/entities/course/ui/deleted-icon";

type Record = PoolTemplate;

type Params = Partial<PoolTemplate>;

type TableProps = BaseProTableProps<Record, Params>;

type PoolTemplateTableProps = TableProps & {
  courseId?: Course["id"];
};

const PoolTemplateTable: React.FC<PoolTemplateTableProps> = ({
  courseId,
  ...props
}) => {
  const member = useMe();
  const { parentHeight, ref } = useParentHeight("table");
  const [someSelected, setSomeSelected] = React.useState(false);
  const [total, setTotal] = React.useState<number>();

  const request: TableProps["request"] = async (params, sort, filter) => {
    const config = {
      method: "POST",
      url: "/api/pool-templates/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    if (courseId) {
      config.data.filters.push({
        field: "course_id",
        operator: "=",
        value: courseId,
      });
    } else {
      config.data.includes.push({ relation: "course" });
    }

    const data = await axios
      .request<OrionRestIndexResponse<PoolTemplate>>(config)
      .then((res) => res.data);

    setTotal(data.meta.total);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    request,
    toolBarRender: (action, { selectedRowKeys }) => {
      if (action === undefined) {
        throw new Error("action is undefined");
      }
      if (!selectedRowKeys) {
        throw new Error("selectedRowKeys is undefined");
      }

      const toUpdateRecordKey =
        selectedRowKeys.length === 1 ? Number(selectedRowKeys?.[0]) : undefined;
      return [
        (member.permissions.includes("pool:create") ||
          member.permissions.includes("pool:update")) && (
          <PoolTemplateDeleteButton
            key={"delete"}
            recordKeys={selectedRowKeys.map(Number)}
            onAfterDelete={() => {
              action.reload();
              action.clearSelected?.();
            }}
          />
        ),
        (member.permissions.includes("pool:create") ||
          member.permissions.includes("pool:update")) && (
          <>
            <PoolTemplateModalForm
              key={`update-${toUpdateRecordKey}`}
              courseId={courseId}
              rest={{
                type: "update",
                recordKey: toUpdateRecordKey,
                onAfterUpdate: () => {
                  action.reload();
                },
              }}
            />
          </>
        ),
        (member.permissions.includes("pool:create") ||
          member.permissions.includes("pool:update")) && (
          <PoolTemplateModalForm
            key={"create"}
            courseId={courseId}
            rest={{
              type: "create",
              onAfterCreate: () => {
                action.reload();
              },
            }}
          />
        ),
      ];
    },
    options: false,
    style: {
      width: "calc(100vw - 200px - 32px)",
      maxWidth: "calc(1920px - 200px - 32px)",
    },
    scroll: { y: parentHeight + (total ? 0 : 40) - (someSelected ? 225 : 165) },
    pagination: { showSizeChanger: true },
    rowSelection: {
      onChange: (selectedRowKeys) => {
        setSomeSelected(selectedRowKeys.length > 0);
      },
    },
    columns: [
      {
        title: "Курс",
        dataIndex: "course_id",
        renderText: (_, { course }) => {
          return (
            <>
              {course?.name}{" "}
              {course?.status === "archived" && <ArchivedCourseIcon />}
              {course?.status === "deleted" && <DeletedCourseIcon />}
            </>
          );
        },
        hideInTable: !!courseId,
        renderFormItem(_, config) {
          return (
            <CourseSearchSelect
              params={{ initialValue: config.value }}
              label={null}
              {...config}
              mode="multiple"
            />
          );
        },
      },
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
        renderText: (text) => (
          <span style={{ boxSizing: "border-box", wordBreak: "break-word" }}>
            {text}
          </span>
        ),
      },
      {
        title: "Дата и время создания",
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        defaultSortOrder: "descend",
        sorter: true,
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "created_at"],
        },
        renderFormItem(_, config) {
          return (
            <DateTimeRangePickerWithTimeControls
              name={["range", "created_at"]}
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <ProTable<PoolTemplate> {...props} />
    </div>
  );
};

export default PoolTemplateTable;
export type { PoolTemplateTableProps };
