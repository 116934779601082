import React from "react";
import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import axios from "@/axios";
import { Course } from "@/models";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";

type CourseSearchSelectProps = Partial<ProFormSelectProps> & {
  isActiveFilter?: boolean;
};

const CourseSearchSelect: React.FC<CourseSearchSelectProps> = ({
  isActiveFilter,
  ...selectProps
}) => {
  const request: typeof selectProps.request = async (params, props) => {
    return axios
      .post<{ data: Course[] }>("/api/courses/search", {
        filters: [
          {
            field: "name",
            operator: "ilike",
            value: `%${params?.keyWords ?? ""}%`,
          },
          ...(isActiveFilter
            ? [
                {
                  field: "status",
                  operator: "=",
                  value: "active",
                },
              ]
            : []),
        ],
      })
      .then(async (res) => {
        const data: any = [];

        res.data.data.forEach((course) => {
          data.push({
            label: course.name,
            value: course.id,
          });
        });

        const initialValue = params.initialValue;

        if (initialValue && Array.isArray(initialValue) && !params?.keyWords) {
          const missingValues: number[] = initialValue.filter(
            (value) => !data.some((option: any) => option.value === value),
          );

          if (missingValues.length > 0) {
            const missingData = await axios
              .post<OrionRestIndexResponse<Course>>("/api/courses/search", {
                filters: [
                  {
                    type: "and",
                    nested: missingValues.map((value) => ({
                      type: "or",
                      field: "id",
                      operator: "=",
                      value,
                    })),
                  },
                ],
              })
              .then((res) =>
                res.data.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );

            data.push(...missingData);
          }
        }

        if (
          props?.fieldProps.value &&
          !Array.isArray(props.fieldProps.value) &&
          !data.some((option: any) => option.value === props.fieldProps.value)
        ) {
          const currentData = await axios
            .get(`/api/courses/${props.fieldProps.value}`)
            .then(({ data }) => ({
              label: data.data.name,
              value: data.data.id,
            }));
          data.unshift(currentData);
        }

        return data;
      });
  };

  return (
    <ProFormSelect
      showSearch
      label={"Курс"}
      request={request}
      {...selectProps}
    />
  );
};

export default CourseSearchSelect;
export type { CourseSearchSelectProps };
