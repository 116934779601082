import baseAxios, {
  AxiosInstance as BaseAxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig as BaseAxiosRequestConfig,
  AxiosResponse as BaseAxiosResponse,
  InternalAxiosRequestConfig as BaseInternalAxiosRequestConfig,
} from "axios";
import { message } from "antd";
import { User, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { getAllLocalStorageItems } from "./shared/helpers/get-all-local-storage-items";

const SHOW_SERVER_IS_DOWN_MESSAGE_ON_RETRY_COUNT = 10;

interface AxiosRequestConfig<D = any> extends BaseAxiosRequestConfig<D> {
  authorization?: boolean;
  retryCount?: number;
}

interface InternalAxiosRequestConfig<D = any>
  extends BaseInternalAxiosRequestConfig<D>,
    Omit<AxiosRequestConfig<D>, "headers"> {}

interface AxiosResponse<T = any, D = any> extends BaseAxiosResponse<T, D> {
  config: InternalAxiosRequestConfig<D>;
}

interface AxiosInstance extends BaseAxiosInstance {
  get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R>;

  post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R>;

  put<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R>;

  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
}

const axios: AxiosInstance = baseAxios.create({});

export const getUser = async () => {
  const localStorageItems = getAllLocalStorageItems();

  const userItem = localStorageItems.find(({ key }) =>
    key.startsWith("oidc.user:"),
  );

  if (!userItem?.value) return null;

  return User.fromStorageString(userItem.value);
};

const refreshUser = async () => {
  const user = await getUser();
  if (!user) return false;

  const manager = new UserManager({
    authority: user.profile.iss,
    // @ts-ignore
    client_id: user.profile.aud,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: false,
  });

  return manager.signinSilent().then((user) => {
    return user !== null ? Promise.resolve(user) : Promise.reject();
  });
};

axios.interceptors.request.use((config) => {
  if (!config.retryCount) config.retryCount = 1;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (
      response.config.retryCount &&
      response.config.retryCount >= SHOW_SERVER_IS_DOWN_MESSAGE_ON_RETRY_COUNT
    ) {
      message.destroy();
    }
    return response;
  },
  async (error) => {
    switch (error.response?.status) {
      case 429:
        message.warning("Слишком много запросов, попробуйте позже.");
        break;
      case 403:
        return error.config.retryCount++ === 1
          ? refreshUser()
              .then(() => axios(error.config))
              .catch(() => new Promise((_, reject) => {
                localStorage.clear();
                window.location.reload();
                reject(error);
              }))
          : new Promise((_, reject) => {
              localStorage.clear();
              window.location.reload();
              reject(error);
            });
      case 503:
        if (
          error.config.retryCount++ ===
          SHOW_SERVER_IS_DOWN_MESSAGE_ON_RETRY_COUNT
        ) {
          message.info(
            "Сервер в данный момент недоступен, попробуйте позже",
            0,
          );
        }
        return new Promise((resolve) => setTimeout(resolve, 1000)).then(() =>
          axios(error.config),
        );
    }

    return Promise.reject(error);
  },
);

axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const withoutAuthorizationUrls = [
    "/api/.well-known/base-host",
    "/api/.well-known/client",
    "/api/features",
    "/api/.well-known/mode",
    "/api/.well-known/version",
    "/api/.well-known/oidc",
    "/api/.well-known/base-host",
  ];

  const authorization =
    config.url!.startsWith("/api") &&
    !withoutAuthorizationUrls.includes(config.url!);

  if (authorization) {
    const user = await getUser();
    if (user) {
      config.headers.Authorization = `${user.token_type} ${user.access_token}`;
    }
  }

  return config;
});

export default axios;
