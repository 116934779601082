import { ReactNode } from "react";
import { Import } from "@/models.ts";
import { Tag } from "antd";

export const statusValueEnum: Record<Import["status"], ReactNode> = {
  pending: <Tag>Ожидание</Tag>,
  to_process: <Tag color="magenta">В очереди</Tag>,
  in_process: <Tag color="processing">В процессе</Tag>,
  processed: <Tag color="success">Успешно</Tag>,
  failed: <Tag color="red">Ошибка</Tag>,
};
