import React from "react";
import { Scorm } from "@/models";
import { Alert, Button, Flex, Grid, Result, Space, Typography } from "antd";

type BaseScormDescriptionsProps = {
  dataSource: Scorm;
};
type ScormDescriptionsProps = BaseScormDescriptionsProps & {
  onScormStart?: () => void;
};
const { useBreakpoint } = Grid;
const ScormDescriptions: React.FC<ScormDescriptionsProps> = ({
  dataSource: scorm,
  onScormStart,
}) => {
  const screens = useBreakpoint();

  switch (scorm.status) {
    case "pending":
      return (
        <Result
          status={"warning"}
          title={"Пакет еще не загружен"}
          subTitle={"Пожалуйста, обратитесь к администратору"}
        />
      );
    case "active":
      break;
    default:
      return (
        <Result
          status={"error"}
          title={"Пакет не активен"}
          subTitle={"Пожалуйста, дождитесь активации пакета"}
        />
      );
  }

  return (
    <Flex
      gap={8}
      vertical
      style={{
        height: `calc(100% ${!screens.lg ? "- 32px - 8px" : ""})`,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Space direction="vertical">
        <Typography.Title level={5} style={{ margin: 0 }}>
          {scorm.name}
        </Typography.Title>
        <Typography.Text>{scorm.description}</Typography.Text>
        <Alert
          type="info"
          message={`  Это SCORM пакет, для его начала/продолжения прохождения нажмите кнопку
          "Запустить урок"`}
        />
      </Space>
      <Flex style={{ flex: "auto" }} justify="center" align="center">
        <Button
          block={!screens.md}
          onClick={() => onScormStart?.()}
          type="primary"
        >
          Запустить урок
        </Button>
      </Flex>
    </Flex>
  );
};

export default ScormDescriptions;
export type { ScormDescriptionsProps };
