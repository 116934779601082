import { Exam } from "@/models";
import { ProSchema } from "@ant-design/pro-utils/es/typing";
import { Tag } from "antd";

const examStatusValueEnum: ProSchema<Exam>["valueEnum"] = {
  not_started: { text: <Tag>Не начат</Tag> },
  launched: { text: <Tag color="processing">В процессе</Tag> },
  to_check: { text: <Tag>На проверке</Tag> },
  checking: { text: <Tag>Проверяется</Tag> },
  checked: { text: <Tag>Проверен</Tag> },
  completed: { text: <Tag color="success">Успешно</Tag> },
  failed: { text: <Tag color="error">Неудача</Tag> },
};

export default examStatusValueEnum;
