import React from "react";
import { Spin as AntdSpin } from "antd";
import type { SpinProps } from "antd/es/spin";

interface Props extends SpinProps {
  //
}

const Spin: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
      }}
    >
      <AntdSpin size="large" {...props} />
    </div>
  );
};

export default Spin;
