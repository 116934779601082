import { ProDescriptions } from "@ant-design/pro-components";
import { Alert, Space, Typography, theme } from "antd";
import OneCHRMIntegrationAlert from "./onec-hrm-integration-alert";

export interface Secrets {
  username: string;
  password: string;
}

export interface ServiceAccountInfo extends Secrets {
  url: string;
  type?: string;
}

type SecretsDescriptionsProps = {
  dataSource: ServiceAccountInfo;
};

const SecretsDescriptions: React.FC<SecretsDescriptionsProps> = ({
  dataSource,
}) => {
  const { token } = theme.useToken();
  return (
    <Space direction="vertical" size={16}>
      <Alert
        message="Сохраните все данные. После закрытия диалога
    данные значения будут недоступны."
        type="warning"
        showIcon
      />
      {dataSource.type === "1c:hrm" && (
        <OneCHRMIntegrationAlert
          style={{ marginBottom: token.marginLG }}
          showIcon
        />
      )}
      <ProDescriptions
        column={1}
        dataSource={dataSource}
        layout="horizontal"
        columns={[
          {
            dataIndex: "authority",
            title: <Typography.Text>Адрес пространства</Typography.Text>,
            render: (_, { url }) => (
              <Typography.Paragraph copyable>{url}</Typography.Paragraph>
            ),
          },
          {
            dataIndex: "username",
            title: <Typography.Text>Логин</Typography.Text>,
            render: (_, { username }) => (
              <Typography.Paragraph copyable>{username}</Typography.Paragraph>
            ),
          },
          {
            dataIndex: "password",
            title: <Typography.Text>Пароль</Typography.Text>,
            render: (_, { password }) => (
              <Typography.Paragraph copyable>{password}</Typography.Paragraph>
            ),
          },
        ]}
      />
    </Space>
  );
};

export default SecretsDescriptions;
