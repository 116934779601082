import React from "react";
import SupportChat, { SupportChatProps } from "@/entities/support/ui/chat";
import { ModalProps } from "antd/lib";
import { Modal as AntdModal, Grid, theme, Typography } from "antd";
import styled from "styled-components";

const Modal = styled(AntdModal)`
  .ant-modal-close {
    top: 6px;
    right: 6px;
    color: #fff;
    opacity: 0.6;
  }
  .ant-modal-close: hover {
    color: #fff;
    opacity: 1;
  }
`;

type SupportChatModalProps = ModalProps & SupportChatProps;

const SupportChatModal: React.FC<SupportChatModalProps> = ({
  supportChatId,
  onAfterClose,
  ...modalProps
}) => {
  const { token } = theme.useToken();
  const supportModalChatWidth = 375;
  const supportModalChatHeight = 500;
  const screens = Grid.useBreakpoint();
  const isMobile = !screens.md;

  return (
    <Modal
      destroyOnClose
      footer={null}
      title={
        <Typography.Text style={{ color: token.colorWhite }}>
          Чат с поддержкой
        </Typography.Text>
      }
      open
      zIndex={9999}
      mask={false}
      maskClosable={false}
      style={{
        right: isMobile ? 0 : token.margin,
        bottom: isMobile ? 0 : token.margin,
        position: "fixed",
        top: "auto",
        padding: 0,
        margin: 0,
        maxWidth: "none",
      }}
      width={isMobile ? "100vw" : supportModalChatWidth}
      styles={{
        wrapper: { pointerEvents: "none" },
        content: {
          padding: 0,
          maxHeight: "100vh",
        },
        header: {
          padding: token.paddingXS,
          margin: 0,
          backgroundColor: token.colorPrimary,
          borderRadius: isMobile
            ? 0
            : `${token.borderRadiusLG}px ${token.borderRadiusLG}px 0 0`,
        },
      }}
      bodyProps={{ style: { backGround: "red" } }}
      {...modalProps}
    >
      <div
        style={{
          height: isMobile ? "calc(100vh - 40px)" : supportModalChatHeight,
          maxHeight: isMobile ? "calc(100vh - 40px)" : "calc(100vh - 54px)",
          borderRadius: token.borderRadius,
          backgroundColor: token.colorBgContainer,
        }}
      >
        <SupportChat
          onAfterClose={onAfterClose}
          supportChatId={supportChatId}
          isInModal
          isMobile={isMobile}
        />
      </div>
    </Modal>
  );
};

export default SupportChatModal;
