import { FieldData } from "rc-field-form/es/interface";

export const setValidationErrorsToFormFields = (form: any, errors: any) => {
  form.setFields(
    Object.entries(errors).map(
      ([key, value]): FieldData => ({
        name: key,
        errors: value as string[],
      }),
    ),
  );
};
