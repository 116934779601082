import arraysEqual from "@/shared/helpers/arrays-equal";
import { ProDescriptionsProps } from "@ant-design/pro-descriptions";
import { deepmerge } from "deepmerge-ts";

const deepmergeProDescriptionsProps = <DataType extends Record<string, any>>(
  defaultProps: ProDescriptionsProps<DataType> = {},
  props: ProDescriptionsProps<DataType> = {},
  overrideProps: ProDescriptionsProps<DataType> = {},
): ProDescriptionsProps<DataType> => {
  let columnsProps = props.columns || [];
  const defaultColumnsProps = defaultProps.columns || [];
  columnsProps = columnsProps.map((column, index) => {
    const defaultColumn =
      defaultColumnsProps.find((defaultColumn) => {
        return (
          (typeof defaultColumn.dataIndex === "object" &&
            typeof column.dataIndex === "object" &&
            arraysEqual(defaultColumn.dataIndex, column.dataIndex)) ||
          defaultColumn.dataIndex === column.dataIndex
        );
      }) ?? {};

    return {
      ...deepmerge(defaultColumn, column),
      dataIndex: column.dataIndex,
    } as any;
  });
  props = deepmerge(defaultProps, props);
  props.columns = columnsProps;

  return { ...props, ...overrideProps };
};

export default deepmergeProDescriptionsProps;
