import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useSearchParams } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const ContextAuthCleanUrlParams: React.FC<Props> = ({ children }) => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (auth.isLoading) return;
    // remove state, session_state, code from query params
    ["state", "session_state", "code"].map((key) => searchParams.delete(key));
    setSearchParams(searchParams);
  }, [auth.isLoading]);

  return <>{children}</>;
};

export default ContextAuthCleanUrlParams;
