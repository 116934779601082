import React from "react";
import axios from "@/axios";
import { Exam, Test } from "@/models";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useSWR from "swr";
import { Button, Spin, theme } from "antd";
import { useNavigate } from "react-router-dom";

type ExamButtonProps = {
  poolParticipantId?: string;
  testId: Test["id"];
};

const ExamButton: React.FC<ExamButtonProps> = ({
  poolParticipantId,
  testId,
}) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const { data: exam, ...examsRequest } = useSWR(
    [`/api/exams/search`, poolParticipantId, testId, "button"],
    async ([url, pool_participant_id, test_id]) => {
      return axios
        .post<OrionRestIndexResponse<Exam>>(
          url,
          {
            filters: [
              { field: "pool_participant_id", value: pool_participant_id },
              { field: "test_participant.test_id", value: test_id },
            ],
            sort: [{ field: "attempt_number", direction: "desc" }],
          },
          {
            params: { per_page: 1, page: 1 },
          },
        )
        .then((response) => response.data.data[0] ?? null);
    },
    { refreshInterval: 1000 },
  );

  if (examsRequest.error) throw examsRequest.error;
  if (examsRequest.isLoading) return <Spin />;

  const examStatusButtonTextEnum: { [key in Exam["status"]]?: string } = {
    not_started: "Начать",
    launched: "Продолжить",
    completed: "Сдан",
  };

  const onExamStart = async () => {
    if (exam!.status === "not_started") {
      await axios.put(`/api/exams/${exam!.id}`, { status: "launched" });
    }

    const { pathname, search } = window.location;
    // prettier-ignore
    navigate(`/education/${poolParticipantId}/exams/${exam!.id}?pool_participant_url=${pathname}${search}`);
  };

  // prettier-ignore
  if (!exam || (exam.status !== "launched" && exam.status !== "not_started" && exam.status !== "completed")) return null;

  return (
    <>
      {exam && (
        <Button
          onClick={onExamStart}
          type={"primary"}
          block
          disabled={exam.status === "completed"}
          style={{
            background:
              exam.status === "completed" ? token.blue1 : token.colorPrimary,
          }}
        >
          {examStatusButtonTextEnum[exam.status]}
        </Button>
      )}
    </>
  );
};

export default ExamButton;
