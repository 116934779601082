import React, { useEffect, useState } from "react";
import ThemeContext, { ThemeValue } from "@/shared/theme/lib/context.tsx";
import useBrowserTheme from "@/shared/hooks/use-browser-theme";

type ThemeContextProviderProps = {
  children: React.ReactNode;
};

const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({
  children,
}) => {
  const [theme, _setTheme] = useState<ThemeValue>("light");
  const [colorScheme, setColorScheme] = useState<"light" | "dark">("light");
  const browserTheme = useBrowserTheme();

  useEffect(() => {
    if (theme === "auto") {
      setColorScheme(browserTheme);
    } else {
      setColorScheme(theme);
    }
  }, [theme, browserTheme]);

  const setTheme = (themeAlgorithm?: ThemeValue): void => {
    if (!themeAlgorithm) {
      themeAlgorithm = localStorage.getItem("theme") as ThemeValue;
    }

    if (!themeAlgorithm) {
      themeAlgorithm = "auto";
    }

    if (!["light", "dark", "auto"].includes(themeAlgorithm)) {
      throw new Error("Invalid theme algorithm");
    }
    _setTheme(themeAlgorithm);
    localStorage.setItem("theme", themeAlgorithm);
  };

  useEffect(() => {
    localStorage.removeItem("themeAlgorithm");
    setTheme();
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        value: colorScheme,
        mode: theme,
        set: setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
export type { ThemeContextProviderProps };
