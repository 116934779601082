import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import PoolTemplateTable from "@/entities/pool-template/ui/table";

const Page: React.FC = () => {
  return (
    <Flex gap={8} vertical style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div style={{ height: "calc(100% - 30px)" }}>
        <PoolTemplateTable
          hasQueryParams
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          columnsState={{
            persistenceKey: "pool-templates-table",
          }}
          columns={[
            {
              dataIndex: "course_id",
              render: (dom, { course_id, course }) => {
                if (course?.status === "deleted") return dom;
                return <Link to={`/manage/courses/${course_id}`}>{dom} </Link>;
              },
              hideInSetting: true,
              disable: true,
            },
            { dataIndex: "name" },
            { dataIndex: "created_at" },
          ]}
        />
      </div>
    </Flex>
  );
};

export default Page;
