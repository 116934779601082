import React, { useEffect, useState } from "react";
import { Button, Flex, theme } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import {
  ColumnsState,
  ProForm,
  ProFormGroup,
  ProFormProps,
  ProFormSelect,
  ProTableProps,
} from "@ant-design/pro-components";
import PoolParticipantTable from "@/entities/pool-participant/ui/table";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import useQueryState from "@/shared/hooks/use-query-state";
import ConsolidatedFormFields from "@/entities/report/ui/consolidated-form-fields";
import { Report as BaseReport } from "@/models";
import IndividualFormFields from "@/entities/report/ui/individual-form-fields";
import MemberSelect from "@/entities/member/ui/select";
import LatestAddedMembersFormFields from "@/entities/report/ui/latest-added-members-form-fields";
import LatestAddedMembersTable from "@/entities/report/ui/latest-added-members-table";
import { Link } from "react-router-dom";
import CompletedEducationMembersFormFields from "@/entities/report/ui/completed-education-members-fields";
import EducationEndingTomorrowMembersFormFields from "@/entities/report/ui/education-ending-tomorrow-fields";
import EducationEndingTomorrowTable from "@/entities/report/ui/education-ending-tomorrow-table";
import OverdueEducationTable from "@/entities/report/ui/overdue-education-table";
import EducationStatisticsByDepartmentFormFields from "@/entities/report/ui/education-statistics-by-department-fields";
import ReportButton from "@/entities/report/ui/button";
import RepeatedEducationFormFields from "@/entities/report/ui/repeated-education-fields";
import RepeatedEducationTable from "@/entities/report/ui/repeated-education-table";
import CompanyPersonnelChangesTable from "@/entities/report/ui/company-personnel-changes-table";
import CompanyPersonnelChangesFormFields from "@/entities/report/ui/company-personnel-changes-fields";
import useMe from "@/entities/me/lib/use";
import { useTranslation } from "react-i18next";
import useValueEnum from "@/entities/report/lib/value-enum.ts";
import { uniq } from "lodash";
import { Report } from "@/entities/report/lib/model";

type ReportFormProps = ProFormProps<Report>;

type ReportProTableProps = ProTableProps<Report, Partial<Report>>;

const Page: React.FC = () => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [form] = ProForm.useForm<Report>();
  const [tableSearchForm] = ProForm.useForm<Report>();
  const [filters, setFilters] = React.useState<Record<string, any>[]>([]);
  const [sorter, setSorter] = React.useState<Record<string, any>>();

  const report = ProForm.useWatch("report", form);
  const member_id = ProForm.useWatch("member_id", form);

  const positions = ProForm.useWatch(["positions", "position_id"], form);
  const memberPositions = ProForm.useWatch(
    ["member", "positions", "position_id"],
    form,
  );

  useEffect(() => {
    if (positions || memberPositions) {
      localStorage.setItem(
        "position_id",
        JSON.stringify(positions || memberPositions),
      );
    }
  }, [positions, memberPositions]);

  useEffect(() => {
    if (
      report === "consolidated" ||
      report === "repeated_education" ||
      report === "education_statistics_by_department"
    ) {
      form.setFieldsValue({
        member: {
          positions: {
            position_id: JSON.parse(
              localStorage.getItem("position_id") || "[]",
            ),
          },
        },
      } as any);
    }
    if (report === "latest_added_members") {
      form.setFieldsValue({
        positions: {
          position_id: JSON.parse(localStorage.getItem("position_id") || "[]"),
        },
      } as any);
    }
  }, [report]);

  const [scopes, setScopes] = React.useState<Record<string, any>[]>([]);
  const [collapsed, setCollapsed] = React.useState(true);
  const [selectedReport, setSelectedReport] = useQueryState("report", {
    defaultValue: "consolidated",
    valueType: "string",
  });
  const member = useMe();

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const [columnsState, setColumnsState] = React.useState<string[] | undefined>(
    undefined,
  );

  const defaultProps: ReportFormProps = {
    form,
    preserve: true,
    submitter: false,
    initialValues: {
      report: selectedReport ?? "consolidated",
      member: {
        positions: {
          position_id: JSON.parse(localStorage.getItem("position_id") || "[]"),
        },
      },
      positions: {
        position_id: JSON.parse(localStorage.getItem("position_id") || "[]"),
      },
    },
  };
  const showColumnsNumber = columnsState
    ? Object.keys(columnsState).length
    : undefined;

  const defaulColumnWidth = 180;

  const setLoading = (isLoading: boolean) =>
    setIsSubmitButtonLoading(isLoading);

  const setDefaultFilters = () => {
    const filters: Record<string, any>[] = [];
    switch (report) {
      case "completed_education_members": {
        filters.push({
          field: "status",
          operator: "=",
          value: "completed",
        });
        break;
      }
      case "education_ending_tomorrow":
      case "overdue_education": {
        const now = new Date().toISOString();
        let nowDateAfter24h = new Date();
        nowDateAfter24h.setDate(nowDateAfter24h.getDate() + 1);
        const nowAfter24h = nowDateAfter24h.toISOString();
        filters.push(
          { field: "status", operator: "!=", value: "completed" },
          ...(report === "education_ending_tomorrow"
            ? [
                { field: "ends_at", operator: ">", value: now },
                { field: "ends_at", operator: "<", value: nowAfter24h },
              ]
            : [{ field: "ends_at", operator: "<", value: now }]),
        );
        break;
      }
      case "education_statistics_by_department": {
        filters.push({
          type: "and",
          nested: [
            {
              type: "or",
              field: "status",
              operator: "=",
              value: "not_started",
            },
            {
              type: "or",
              field: "status",
              operator: "=",
              value: "in_progress",
            },
          ],
        });
        break;
      }
    }
    return filters;
  };

  defaultProps.onFinish = async (values) => {
    setLoading(true);
    setCollapsed(true);
    const { report, ...rest } = values;
    const scopes: Record<string, any>[] = [];
    const filters: Record<string, any>[] = [];
    const flattenFilter = (data: Record<string, any>, parentKey = "") => {
      for (const [key, value] of Object.entries(data)) {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;

        if (value === null && value === undefined) continue;

        if (Array.isArray(value) && value.length > 0) {
          filters.push({
            type: "and",
            nested: value.map((valueItem) => {
              return {
                type: "or",
                field: fullKey,
                operator: "=",
                value: valueItem,
              };
            }),
          });
        } else if (key === "range") {
          Object.entries(value).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              if (value[0]) {
                filters.push({
                  field: key,
                  operator: ">=",
                  value: value[0],
                });
              }
              if (value[1]) {
                filters.push({
                  field: key,
                  operator: "<=",
                  value: value[1],
                });
              }
            }
          });
        } else if (key === "positions") {
          const positionsIdsFlat = value.position_id.flatMap((v: any) => {
            if (typeof v === "string") {
              return v.split(",").reduce((acc, id) => {
                if (!isNaN(Number(id))) acc.push(Number(id));
                return acc;
              }, [] as number[]);
            }
            return [v];
          });

          const uniquePositionsIds = uniq(positionsIdsFlat);

          const field =
            (parentKey ? `${parentKey}.${key}` : key) + ".position_id";

          if (uniquePositionsIds.length) {
            filters.push({
              type: "and",
              nested: uniquePositionsIds.map((id) => ({
                type: "or",
                field: field,
                operator: "=",
                value: id,
              })),
            });
          }
        } else if (key === "scopes") {
          Object.entries(value).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              scopes.push({
                name: key,
                parameters: value,
              });
            }
          });
        } else if (typeof value === "object" && value !== null) {
          flattenFilter(value, fullKey);
        } else if (typeof value === "string") {
          filters.push({
            field: fullKey,
            operator: "ilike",
            value: `%${value}%`,
          });
        } else if (typeof value === "number") {
          filters.push({ field: fullKey, operator: "=", value });
        }
      }
    };

    flattenFilter(rest);

    setScopes(scopes);

    setFilters([...filters, ...setDefaultFilters()]);
    tableSearchForm.submit();
  };

  const defaultTableProps: ReportProTableProps = {
    search: {
      form: tableSearchForm,
      style: { display: "none" },
    },
    rowSelection: false,
    onLoad: () => {
      setLoading(false);
    },
    onRequestError: () => {
      setLoading(false);
    },
    onChange(_, filters, sorter) {
      if (Array.isArray(sorter)) return;
      if (!sorter.order) {
        return setSorter(undefined);
      }
      const direction = sorter.order === "ascend" ? "asc" : "desc";
      const field = sorter.columnKey!.toString().replace(/[-,–—]/g, ".");

      setSorter({ field, direction });
    },
    columnsState: {
      defaultValue:
        report === "consolidated"
          ? {
              registry_number: { show: false },
              "member,role_id": { show: false },
              "member,status": { show: false },
              "pool,id": { show: false },
              starts_at: { show: false },
              ends_at: { show: false },
              started_at: { show: false },
              ended_at: { show: false },
              "member,full_name": { show: true },
              "pool,course,id": { show: true },
              "member,positions,position_id": { show: true },
              status: { show: true },
              progress_percent: { show: true },
            }
          : undefined,
      onChange: (map) => {
        const columnsState: Record<string, ColumnsState> = {};
        Object.entries(map).forEach(([key, value]) => {
          key = key.replace(/[-,–—]/g, ".");
          columnsState[key] = value;
        });
        const newMap = Object.entries(columnsState).flatMap(([key, value]) => {
          if (value.show) {
            return [key];
          } else {
            return [];
          }
        });
        setColumnsState(newMap);
      },
    },
    style: {
      width: "calc(100vw - 200px - 32px)",
      maxWidth: "calc(1920px - 200px - 32px)",
      height: "100%",
    },
    options: {
      setting: {
        draggable: false,
        showListItemOption: false,
      },
      reload: false,
      density: false,
    },
  };

  useEffect(() => {
    setFilters(setDefaultFilters());
    if (
      report === "consolidated" ||
      report === "repeated_education" ||
      report === "education_statistics_by_department" ||
      report === "latest_added_members"
    ) {
      const filters: any[] = [];
      const value = JSON.parse(localStorage.getItem("position_id") || "[]");
      const positionsIdsFlat = value.flatMap((v: any) => {
        if (typeof v === "string") {
          return v.split(",").reduce((acc, id) => {
            if (!isNaN(Number(id))) acc.push(Number(id));
            return acc;
          }, [] as number[]);
        }
        return [v];
      });

      const uniquePositionsIds = uniq(positionsIdsFlat);

      const field =
        report === "latest_added_members"
          ? "positions.position_id"
          : "member.positions.position_id";

      if (uniquePositionsIds.length) {
        filters.push({
          type: "and",
          nested: uniquePositionsIds.map((id) => ({
            type: "or",
            field: field,
            operator: "=",
            value: id,
          })),
        });
      }
      setFilters(filters);
      tableSearchForm.submit();
    }
  }, [report]);

  return (
    <Flex
      vertical
      gap={token.paddingXS}
      style={{
        width: "calc(100vw - 200px - 32px)",
        height: "100%",
        maxWidth: "calc(1920px - 200px - 32px)",
        margin: "0 auto",
      }}
    >
      <AutoBreadcrumb />
      <div
        style={{
          height: "calc(100% - 30px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Flex
          style={{
            width: "100%",
            padding: token.paddingSM,
            backgroundColor: token.colorBgContainer,
            borderRadius: token.borderRadiusLG,
          }}
        >
          <ProForm style={{ width: "100%" }} grid {...defaultProps}>
            <Flex justify="space-between" style={{ width: "100%" }}>
              <ProFormSelect
                label="Тип отчета"
                name="report"
                colProps={{ span: 8 }}
                onChange={(value) => {
                  setSelectedReport(String(value));
                  setFilters([]);
                  setSorter(undefined);
                  setColumnsState(undefined);
                  form.resetFields();
                  form.setFieldValue("report", value);
                }}
                allowClear={false}
                rules={[{ required: true }]}
                valueEnum={useValueEnum()}
              />
              {report === "individual_education_progress" && (
                <MemberSelect
                  rules={[{ required: true }]}
                  colProps={{ span: 8 }}
                  name={"member_id"}
                  label="ФИО"
                />
              )}
              <Flex gap={token.paddingSM} align="center" wrap="wrap">
                <Button onClick={() => form.resetFields()}>Очистить</Button>
                <Button
                  loading={isSubmitButtonLoading}
                  type="primary"
                  onClick={() => form.submit()}
                >
                  Применить
                </Button>
                <Button type="link" onClick={() => setCollapsed(!collapsed)}>
                  Фильтр {collapsed ? <DownOutlined /> : <UpOutlined />}
                </Button>
              </Flex>
            </Flex>
            <ProFormGroup collapsible collapsed={collapsed}>
              {report === "consolidated" && <ConsolidatedFormFields />}
              {report === "individual_education_progress" && (
                <IndividualFormFields />
              )}
              {report === "latest_added_members" && (
                <LatestAddedMembersFormFields />
              )}
              {report === "completed_education_members" && (
                <CompletedEducationMembersFormFields />
              )}
              {(report === "education_ending_tomorrow" ||
                report === "overdue_education") && (
                <EducationEndingTomorrowMembersFormFields />
              )}
              {report === "education_statistics_by_department" && (
                <EducationStatisticsByDepartmentFormFields />
              )}
              {report === "repeated_education" && (
                <RepeatedEducationFormFields />
              )}
              {report === "company_personnel_changes" && (
                <CompanyPersonnelChangesFormFields />
              )}
            </ProFormGroup>
          </ProForm>
        </Flex>
        <div style={{ flex: "auto", overflow: "hidden" }}>
          {report === "consolidated" && (
            <PoolParticipantTable
              toolBarRender={() => {
                return [
                  <ReportButton
                    sorter={sorter}
                    exportType="export_participants_for_report"
                    filters={filters}
                    defaultCheckedColumns={
                      columnsState
                        ? columnsState
                        : [
                            "member.full_name",
                            "member.positions.position_id",
                            "pool.course.id",
                            "status",
                            "progress_percent",
                          ]
                    }
                    rangeFormField={{
                      name: "starts_at",
                      value: form.getFieldValue(["range", "starts_at"]),
                    }}
                    columnsOption={[
                      { label: "Пользователь", value: "member.full_name" },
                      { label: "Статус", value: "member.status" },
                      { label: "Статус в потоке", value: "status" },
                      { label: "Роль", value: "member.role_id" },
                      {
                        label: t("Должность"),
                        value: "member.positions.position_id",
                      },
                      { label: "Курс", value: "pool.course.id" },
                      { label: "Поток", value: "pool.id" },
                      { label: "Фактическая дата начала", value: "started_at" },
                      { label: "Планируемая дата начала", value: "starts_at" },
                      {
                        label: "Фактическая дата окончания",
                        value: "ended_at",
                      },
                      { label: "Планируемая дата окончания", value: "ends_at" },
                      {
                        label: "Прогресс прохождения",
                        value: "progress_percent",
                      },
                      { label: "Номер в реестре", value: "registry_number" },
                    ]}
                  />,
                ];
              }}
              params={{ filters: filters }}
              scroll={{
                x:
                  showColumnsNumber === undefined
                    ? 1000
                    : showColumnsNumber * defaulColumnWidth,
              }}
              pagination={{
                pageSize: 100,
              }}
              columns={[
                {
                  dataIndex: ["member", "full_name"],
                  render: (dom, { member_id }) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${member_id}`}>{dom}</Link>
                      );
                    }
                    return dom;
                  },
                },
                { dataIndex: ["member", "role_id"] },
                {
                  dataIndex: ["member", "status"],
                  tooltip: true,
                  title: () => "Статус пользователя",
                },
                {
                  dataIndex: ["member", "positions", "position_id"],
                  tooltip: true,
                  title: () => t("Должность"),
                },
                {
                  dataIndex: ["pool", "course", "id"],
                  render: (dom, { pool: { course_id, course } }) => {
                    if (course?.status === "deleted") return dom;
                    return (
                      <Link to={`/manage/courses/${course_id}`}>{dom}</Link>
                    );
                  },
                },
                {
                  dataIndex: "registry_number",
                  title: () => "Номер в реестре",
                  tooltip: true,
                },
                {
                  dataIndex: ["pool", "id"],
                  render: (dom, { pool_id, pool: { course_id, course } }) => {
                    if (course?.status === "deleted") return dom;
                    return (
                      <Link
                        to={`/manage/courses/${course_id}/pools/${pool_id}`}
                      >
                        {dom}
                      </Link>
                    );
                  },
                },
                {
                  dataIndex: "status",
                  title: () => "Статус в потоке",
                  tooltip: true,
                },
                {
                  dataIndex: "progress_percent",
                  title: () => "Прогресс прохождения",
                  tooltip: true,
                },
                {
                  dataIndex: "starts_at",
                  title: () => "Планируемая дата начала",
                  tooltip: true,
                },
                {
                  dataIndex: "started_at",
                  title: () => "Фактическая дата начала",
                  tooltip: true,
                },
                {
                  dataIndex: "ends_at",
                  title: () => "Планируемая дата завершения",
                  tooltip: true,
                },
                {
                  dataIndex: "ended_at",
                  title: () => "Фактическая дата завершения",
                  tooltip: true,
                },
              ]}
              {...(defaultTableProps as any)}
            />
          )}
          {report === "individual_education_progress" && member_id && (
            <PoolParticipantTable
              toolBarRender={() => {
                return [
                  <ReportButton
                    sorter={sorter}
                    exportType="export_participants_for_report"
                    filters={filters}
                    defaultCheckedColumns={columnsState}
                    rangeFormField={{
                      name: "starts_at",
                      value: form.getFieldValue(["range", "starts_at"]),
                    }}
                    columnsOption={[
                      { label: "Курс", value: "pool.course.id" },
                      { label: "Поток", value: "pool.id" },
                      { label: "Статус в потоке", value: "status" },
                      {
                        label: "Прогресс прохождения",
                        value: "progress_percent",
                      },
                      { label: "Фактическая дата начала", value: "started_at" },
                      { label: "Планируемая дата начала", value: "starts_at" },
                      {
                        label: "Фактическая дата окончания",
                        value: "ended_at",
                      },
                      { label: "Планируемая дата окончания", value: "ends_at" },
                    ]}
                  />,
                ];
              }}
              params={{ filters: filters }}
              memberId={member_id}
              scroll={{
                x:
                  showColumnsNumber === undefined
                    ? 1600
                    : showColumnsNumber * defaulColumnWidth,
              }}
              pagination={{
                pageSize: 100,
              }}
              columns={[
                {
                  dataIndex: ["pool", "course", "id"],
                  render: (dom, { pool: { course_id, course } }) => {
                    if (course?.status === "deleted") return dom;
                    return (
                      <Link to={`/manage/courses/${course_id}`}>{dom}</Link>
                    );
                  },
                },
                {
                  dataIndex: ["pool", "id"],
                  render: (dom, { pool_id, pool: { course_id, course } }) => {
                    if (course?.status === "deleted") return dom;
                    return (
                      <Link
                        to={`/manage/courses/${course_id}/pools/${pool_id}`}
                      >
                        {dom}
                      </Link>
                    );
                  },
                },
                {
                  dataIndex: "status",
                  title: () => "Статус в потоке",
                  tooltip: true,
                },
                {
                  dataIndex: "progress_percent",
                  title: () => "Прогресс прохождения",
                  tooltip: true,
                },
                {
                  dataIndex: "starts_at",
                  title: () => "Планируемая дата начала",
                  tooltip: true,
                },
                {
                  dataIndex: "started_at",
                  title: () => "Фактическая дата начала",
                  tooltip: true,
                },
                {
                  dataIndex: "ends_at",
                  title: () => "Планируемая дата окончания",
                  tooltip: true,
                },
                {
                  dataIndex: "ended_at",
                  title: () => "Фактическая дата окончания",
                  tooltip: true,
                },
              ]}
              {...(defaultTableProps as any)}
            />
          )}
          {report === "latest_added_members" && (
            <LatestAddedMembersTable
              params={{ filters: filters }}
              toolBarRender={() => {
                return [
                  <ReportButton
                    sorter={sorter}
                    exportType="export_members_for_report"
                    filters={filters}
                    defaultCheckedColumns={columnsState}
                    rangeFormField={{
                      name: "created_at",
                      value: form.getFieldValue(["range", "created_at"]),
                    }}
                    columnsOption={[
                      { label: "Пользователь", value: "full_name" },
                      {
                        label: t("Должность"),
                        value: "positions.position_id",
                      },
                      { label: "Дата добавления", value: "created_at" },
                    ]}
                  />,
                ];
              }}
              columns={[
                {
                  dataIndex: "full_name",
                  render: (text, record) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${record.id}`}>{text}</Link>
                      );
                    }
                    return text;
                  },
                },
                {
                  dataIndex: ["positions", "position_id"],
                  title: () => t("Должность"),
                  tooltip: true,
                },
                {
                  dataIndex: "created_at",
                  title: () => "Дата добавления",
                  tooltip: true,
                },
              ]}
              {...(defaultTableProps as any)}
            />
          )}
          {report === "completed_education_members" && (
            <PoolParticipantTable
              toolBarRender={() => {
                return [
                  <ReportButton
                    sorter={sorter}
                    exportType="export_participants_for_report"
                    filters={filters}
                    defaultCheckedColumns={columnsState}
                    rangeFormField={{
                      name: "ended_at",
                      value: form.getFieldValue(["range", "ended_at"]),
                    }}
                    columnsOption={[
                      { label: "Пользователь", value: "member.full_name" },
                      { label: "Поток", value: "pool.id" },
                      {
                        label: "Фактическая дата завершения",
                        value: "ended_at",
                      },
                    ]}
                  />,
                ];
              }}
              params={{ filters: filters }}
              pagination={{
                pageSize: 100,
              }}
              columns={[
                {
                  dataIndex: ["member", "full_name"],
                  render: (dom, { member_id }) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${member_id}`}>{dom}</Link>
                      );
                    }
                    return dom;
                  },
                },
                {
                  dataIndex: ["pool", "id"],
                  render: (dom, { pool: { course, course_id, id } }) => {
                    if (course?.status === "deleted") return dom;
                    return (
                      <Link to={`/manage/courses/${course_id}/pools/${id}`}>
                        {dom}
                      </Link>
                    );
                  },
                },
                {
                  dataIndex: "ended_at",
                  title: () => "Фактическая дата завершения",
                  tooltip: true,
                  width: 200,
                },
              ]}
              {...(defaultTableProps as any)}
            />
          )}
          {report === "education_ending_tomorrow" && (
            <EducationEndingTomorrowTable
              params={{ filters: filters }}
              toolBarRender={() => {
                return [
                  <ReportButton
                    sorter={sorter}
                    exportType="export_participants_for_report"
                    filters={filters}
                    defaultCheckedColumns={columnsState}
                    rangeFormField={{
                      name: "ends_at",
                      value: form.getFieldValue(["range", "ends_at"]),
                    }}
                    columnsOption={[
                      { label: "Пользователь", value: "member.full_name" },
                      { label: "Поток", value: "pool.id" },
                      {
                        label: "Планируемая дата завершения",
                        value: "ends_at",
                      },
                    ]}
                  />,
                ];
              }}
              columns={[
                {
                  dataIndex: ["member", "full_name"],
                  render: (text, record) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${record.member!.id}`}>
                          {text}
                        </Link>
                      );
                    }
                    return text;
                  },
                },
                {
                  dataIndex: ["pool", "name"],
                  renderText: (text, { pool }) => {
                    if (pool?.course?.status === "deleted") return text;
                    return (
                      <Link
                        to={`/manage/courses/${pool!.course_id}/pools/${
                          pool!.id
                        }`}
                      >
                        {text}
                      </Link>
                    );
                  },
                },
                {
                  dataIndex: "ends_at",
                  title: () => "Планируемая дата завершения",
                  tooltip: true,
                },
              ]}
              {...(defaultTableProps as any)}
            />
          )}
          {report === "overdue_education" && (
            <OverdueEducationTable
              params={{ filters: filters }}
              toolBarRender={() => {
                return [
                  <ReportButton
                    sorter={sorter}
                    exportType="export_participants_for_report"
                    filters={filters}
                    defaultCheckedColumns={columnsState}
                    rangeFormField={{
                      name: "ends_at",
                      value: form.getFieldValue(["range", "ends_at"]),
                    }}
                    columnsOption={[
                      { label: "Пользователь", value: "member.full_name" },
                      { label: "Поток", value: "pool.id" },
                      {
                        label: "Планируемая дата завершения",
                        value: "ends_at",
                      },
                    ]}
                  />,
                ];
              }}
              columns={[
                {
                  dataIndex: ["member", "full_name"],
                  render: (text, record) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${record.member!.id}`}>
                          {text}
                        </Link>
                      );
                    }
                    return text;
                  },
                },
                {
                  dataIndex: ["pool", "name"],
                  renderText: (text, { pool }) => {
                    if (pool?.course?.status === "deleted") return text;
                    return (
                      <Link
                        to={`/manage/courses/${pool!.course_id}/pools/${
                          pool!.id
                        }`}
                      >
                        {text}
                      </Link>
                    );
                  },
                },
                {
                  dataIndex: "ends_at",
                  title: () => "Планируемая дата завершения",
                  tooltip: true,
                },
              ]}
              {...(defaultTableProps as any)}
            />
          )}
          {report === "education_statistics_by_department" && (
            <PoolParticipantTable
              toolBarRender={() => {
                return [
                  <ReportButton
                    sorter={sorter}
                    exportType="export_participants_for_report"
                    filters={filters}
                    defaultCheckedColumns={columnsState}
                    columnsOption={[
                      { label: "Пользователь", value: "member.full_name" },
                      {
                        label: t("Должность"),
                        value: "member.positions.position_id",
                      },
                      { label: "Поток", value: "pool.id" },
                      {
                        label: "Прогресс прохождения",
                        value: "progress_percent",
                      },
                    ]}
                  />,
                ];
              }}
              params={{ filters: filters }}
              columns={[
                {
                  dataIndex: ["member", "full_name"],
                  render: (dom, { member_id }) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${member_id}`}>{dom}</Link>
                      );
                    }
                    return dom;
                  },
                },
                {
                  dataIndex: ["member", "positions", "position_id"],
                  title: () => t("Должность"),
                  tooltip: true,
                },
                {
                  dataIndex: ["pool", "id"],
                  render: (dom, { pool: { id, course_id, course } }) => {
                    if (course?.status === "deleted") return dom;
                    return (
                      <Link to={`/manage/courses/${course_id}/pools/${id}`}>
                        {dom}
                      </Link>
                    );
                  },
                },
                {
                  dataIndex: "progress_percent",
                  title: () => "Прогресс прохождения",
                  tooltip: true,
                  width: 150,
                },
              ]}
              {...(defaultTableProps as any)}
            />
          )}
          {report === "repeated_education" && (
            <RepeatedEducationTable
              toolBarRender={() => {
                return [
                  <ReportButton
                    sorter={sorter}
                    exportType="export_participants_for_report"
                    filters={filters}
                    scopes={scopes}
                    rangeFormField={{
                      name: ["scopes", "whereReEducationAtBetween"],
                      value: form.getFieldValue([
                        "scopes",
                        "whereReEducationAtBetween",
                      ]),
                    }}
                    defaultCheckedColumns={columnsState}
                    columnsOption={[
                      { label: "Пользователь", value: "member.full_name" },
                      {
                        label: t("Должность"),
                        value: "member.positions.position_id",
                      },
                      { label: "Поток", value: "pool.id" },
                      { label: "Курс", value: "pool.course.id" },
                      {
                        label: "Фактическая дата завершения",
                        value: "ended_at",
                      },
                      {
                        label: "Дата прохождения повторного обучения",
                        value: "re_education_at",
                      },
                    ]}
                  />,
                ];
              }}
              params={{
                filters: filters,
                scopes: scopes,
              }}
              scroll={{
                x:
                  showColumnsNumber === undefined
                    ? 800
                    : showColumnsNumber * defaulColumnWidth,
              }}
              columns={[
                {
                  dataIndex: ["member", "full_name"],
                  render: (text, record) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${record.member!.id}`}>
                          {text}
                        </Link>
                      );
                    }
                    return text;
                  },
                },
                {
                  dataIndex: ["member", "positions", "position_id"],
                  title: () => t("Должность"),
                },
                {
                  dataIndex: ["pool", "id"],
                  render: (_, { pool: { id, course_id, course, name } }) => {
                    if (course?.status === "deleted") return name;
                    return (
                      <Link to={`/manage/courses/${course_id}/pools/${id}`}>
                        {name}
                      </Link>
                    );
                  },
                },
                {
                  dataIndex: ["pool", "course", "id"],
                  render: (dom, { pool: { course_id, course } }) => {
                    if (course?.status === "deleted") return dom;
                    return (
                      <Link to={`/manage/courses/${course_id}`}>{dom}</Link>
                    );
                  },
                },
                {
                  dataIndex: "ended_at",
                  title: () => "Фактическая дата завершения",
                  tooltip: true,
                },
                {
                  dataIndex: "re_education_at",
                  title: () => "Дата прохождения повторного обучения",
                  tooltip: true,
                },
              ]}
              {...(defaultTableProps as any)}
            />
          )}
          {report === "company_personnel_changes" && (
            <CompanyPersonnelChangesTable
              toolBarRender={() => {
                return [
                  <ReportButton
                    sorter={sorter}
                    exportType="export_personnel_changes_for_report"
                    filters={filters}
                    rangeFormField={{
                      name: "created_at",
                      value: form.getFieldValue(["range", "created_at"]),
                    }}
                    defaultCheckedColumns={columnsState}
                    columnsOption={[
                      { label: "Пользователь", value: "member.full_name" },
                      { label: t("Должность"), value: "position_id" },
                      { label: "Дата назначения", value: "created_at" },
                      { label: "Дата увольнения", value: "deleted_at" },
                    ]}
                  />,
                ];
              }}
              params={{ filters: filters }}
              scroll={{
                x:
                  showColumnsNumber === undefined
                    ? 800
                    : showColumnsNumber * defaulColumnWidth,
              }}
              columns={[
                {
                  dataIndex: ["member", "full_name"],
                  render: (dom, { member_id }) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${member_id}`}>{dom}</Link>
                      );
                    }
                    return dom;
                  },
                },
                { dataIndex: "position_id" },
                { dataIndex: "created_at" },
                { dataIndex: "deleted_at" },
              ]}
              {...(defaultTableProps as any)}
            />
          )}
        </div>
      </div>
    </Flex>
  );
};

export default Page;
