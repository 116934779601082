import React from "react";
import { Flex, Space } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import AutomationTable from "@/entities/automation/ui/table";

const Page: React.FC = () => {
  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div style={{ height: "calc(100% - 30px)" }}>
        <AutomationTable />
      </div>
    </Flex>
  );
};

export default Page;
