
  export const invertColor = (hex: string): string => {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
  
    if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
    }
  
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
  
    const brightness = (r * 0.299 + g * 0.587 + b * 0.114);
  
    return brightness > 186 ? '#000000' : '#FFFFFF';
  };