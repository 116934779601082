import React from "react";
import BroadcastMessageTable from "@/entities/broadcast-messages/ui/table.tsx";
import useParentHeight from "@/shared/hooks/use-parent-height";

const Page: React.FC = () => {
  const [someSelected, setSomeSelected] = React.useState(false);
  const { parentHeight, ref } = useParentHeight();

  return (
    <div ref={ref} style={{ height: "100%", width: "100%" }}>
      <BroadcastMessageTable
        hasQueryParams
        style={{ width: "100%" }}
        scroll={{
          y: parentHeight - (someSelected ? 227 : 162),
        }}
        columns={[
          { dataIndex: "id" },
          { dataIndex: "type" },
          { dataIndex: "text" },
          { dataIndex: "starts_at" },
          { dataIndex: "ends_at" },
          { dataIndex: "actions" },
        ]}
      />
    </div>
  );
};
export default Page;
