import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import useMe from "@/entities/me/lib/use";
import PoolTable from "@/entities/pool/ui/table";

const Page: React.FC = () => {
  const navigate = useNavigate();
  const member = useMe();

  return (
    <Flex gap={8} vertical style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div style={{ height: "calc(100% - 30px)" }}>
        <PoolTable
          hasQueryParams
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          scroll={{ x: 1200 }}
          columnsState={{
            persistenceKey: "pools-table",
          }}
          columns={[
            {
              dataIndex: "course_id",
              render: (dom, { course_id, course }) => {
                if (course?.status === "deleted") return dom;
                return <Link to={`/manage/courses/${course_id}`}>{dom}</Link>;
              },
              hideInSetting: true,
              disable: true,
            },
            {
              dataIndex: "name",
              render: (dom, { course_id, course, id: pool_id }) => {
                if (course?.status === "deleted") return dom;
                if (member.permissions.includes("pool_participant:view")) {
                  return (
                    <Link to={`/manage/courses/${course_id}/pools/${pool_id}`}>
                      {dom}
                    </Link>
                  );
                }
                return dom;
              },
              hideInSetting: true,
              disable: true,
            },
            { dataIndex: "key" },
            { dataIndex: "status" },
            { dataIndex: "participants_count" },
            { dataIndex: "starts_at" },
            { dataIndex: "ends_at" },
            { dataIndex: "created_at" },
          ]}
          rest={{
            onAfterCreate: (pool) => {
              navigate(
                `/manage/courses/${pool.course_id}/pools/${pool.id}?pool_participant_create=true`,
              );
            },
          }}
        />
      </div>
    </Flex>
  );
};

export default Page;
