import React from "react";
import { Button } from "antd";

const Page: React.FC = () => {
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          throw new Error("Test error");
        }}
      >
        Throw error
      </Button>
    </>
  );
};

export default Page;
