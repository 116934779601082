import React from "react";
import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import axios from "@/axios";
import { deepmerge } from "deepmerge-ts";
import { PoolParticipant } from "@/models";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useSpace from "@/entities/space/lib/use";

type SelectProps = ProFormSelectProps;

type PoolParticipantRegistryNumberProps = SelectProps;

const PoolParticipantRegistryNumberSelect: React.FC<
  PoolParticipantRegistryNumberProps
> = ({ ...selectProps }) => {
  const space = useSpace();
  const defaultSelectProps: SelectProps = {
    debounceTime: 500,
    showSearch: true,
    request: async (params) => {
      return axios
        .post<OrionRestIndexResponse<PoolParticipant>>(
          "/api/pool-participants/search",
          {
            filters: [
              {
                field: "registry_number",
                operator: "ilike",
                value: `%${params?.keyWords ?? ""}%`,
              },
              {
                field: "registry_number",
                operator: "!=",
                value: null,
              },
            ],
          },
        )
        .then((r) =>
          r.data.data.map((d) => ({
            label: d.registry_number!,
            value: d.registry_number!,
          })),
        );
    },
  };
  return <ProFormSelect {...deepmerge(defaultSelectProps, selectProps)} />;
};

export default PoolParticipantRegistryNumberSelect;
export type { PoolParticipantRegistryNumberProps };
