import { ReactNode } from "react";
import { Import } from "@/models.ts";

export const typeValueEnum: Record<Import["type"], ReactNode> = {
  members: "Пользователи",
  registry: "Номера из реестра",
  learning_matrix: "Матрица обучения",
  questions: "Вопросы",
  course: "Курс",
};
