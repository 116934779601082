import React from "react";
import type { RouteObject } from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFoundPage from "@/shared/pages/not-found";
import { AutoRouterContextProvider } from "@/shared/auto-router/lib/context";
import FileSystem, {
  FileSystemItemDirectory,
  FileSystemItemFile,
} from "@/shared/auto-router/lib/filesystem.ts";
import {
  DIRECTORY_SEPARATOR,
  PAGE_FILE_BASE_NAME,
  PAGES_WRAPPER_BASE_NAMES,
} from "@/shared/auto-router/lib/constants";
import { clone } from "lodash";
import ErrorRouter from "@/shared/pages/error-router";

type AutoRouterProps = {
  basePath?: string;
};

const DEFAULT_BASE_PATH = "/";

/** Docs: https://vitejs.dev/guide/features#glob-import */
const routerDirGlob = import.meta.glob<React.FC>(
  /** In string, before template string not allowed */
  "@/pages/**/{page,layout,context}.tsx",
  {
    eager: true,
    import: "default",
  },
);

const routerDirFS = Object.keys(routerDirGlob)
  .reduce<FileSystem>((res, path) => {
    res.insert(path);
    return res;
  }, new FileSystem())
  .changeDir("/src/pages");

const createPages = (
  fileSystem: FileSystem,
  basePath: string,
  path: string = "",
): RouteObject[] => {
  const groups = fileSystem.items.filter(
    (fileSystemItem) => fileSystemItem.type === "directory",
  ) as FileSystemItemDirectory[];

  let result = groups.map<RouteObject>((group) => {
    const childrenFs = new FileSystem();
    childrenFs.items = group.children;

    return {
      children: createPages(childrenFs, basePath, `${path}/${group.name}`),
    };
  });

  const page = fileSystem.items.find(
    (fileSystemItem) =>
      fileSystemItem.type === "file" &&
      fileSystemItem.baseName === PAGE_FILE_BASE_NAME,
  ) as FileSystemItemFile | undefined;

  if (page !== undefined) {
    result.push({
      index: true,
      errorElement: <ErrorRouter />,
      Component: routerDirGlob[page.path],
    });
  }

  for (const WRAPPER_BASE_NAME of PAGES_WRAPPER_BASE_NAMES) {
    const wrapperFile = fileSystem.items.find(
      (fileSystemItem) =>
        fileSystemItem.type === "file" &&
        fileSystemItem.baseName === WRAPPER_BASE_NAME,
    ) as FileSystemItemFile | undefined;
    if (wrapperFile !== undefined) {
      result = [
        {
          errorElement: <ErrorRouter />,
          Component: routerDirGlob[wrapperFile.path],
          children: result,
        },
      ];
    }
  }

  const toRoutePath = (path: string) => {
    if (path === "") return "/";

    /** Remove base path from current path */
    path = path.replace(new RegExp(`^${basePath}`), "");

    return (
      path
        /** if path contains "[<any>]" then replace with ":<any>" */
        .replace(/\[([^\]]+)\]/g, ":$1")
    );
  };

  result = [
    {
      errorElement: <ErrorRouter />,
      path: toRoutePath(path),
      children: result,
    },
  ];

  return result;
};

const normalizePath = (path: string) => {
  if (path !== DEFAULT_BASE_PATH) {
    if (path.endsWith(DIRECTORY_SEPARATOR)) {
      path = path.slice(0, -1);
    }
    if (!path.startsWith(DIRECTORY_SEPARATOR)) {
      path = `${DIRECTORY_SEPARATOR}${path}`;
    }
  }

  return path;
};

const AutoRouter: React.FC<AutoRouterProps> = ({
  basePath = DEFAULT_BASE_PATH,
}) => {
  basePath = normalizePath(basePath);

  return (
    <AutoRouterContextProvider value={{ basePath }}>
      <RouterProvider
        router={createBrowserRouter([
          ...createPages(clone(routerDirFS), basePath),
          {
            path: "*",
            element: <NotFoundPage />,
          },
        ])}
      />
    </AutoRouterContextProvider>
  );
};

export default AutoRouter;
