import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import axios from "@/axios";
import { Spin } from "antd";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { Member } from "@/models";

const BreadcrumbItem: React.FC = () => {
  const { member_id } = useParams();
  const {
    data: member,
    error,
    isLoading,
  } = useSWR(`/api/members/${member_id!}`, async (url) => {
    return axios
      .get<OrionRestShowResponse<Member>>(url)
      .then((res) => res.data.data);
  });

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!member) throw new Error("Member not found");

  return <>{member.full_name}</>;
};

export default BreadcrumbItem;
