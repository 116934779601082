import React from "react";
import { Outlet } from "react-router-dom";
import LayoutInfo from "@/pages/layout-info.tsx";
import LayoutBroadcastMessage from "@/pages/layout-broadcast-message";
import { Flex } from "antd";

const Layout: React.FC = () => {
  return (
    <>
      <Flex vertical style={{ height: "100vh" }}>
        <LayoutBroadcastMessage />
        <LayoutInfo />
        <div style={{ flex: "auto", overflow: "hidden" }}>
          <Outlet />
        </div>
      </Flex>
    </>
  );
};

export default Layout;
