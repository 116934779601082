import React from "react";
import { Version } from "@/entities/version/lib/type.ts";

type VersionContextValue = {
  value: Version;
  isLoading: boolean;
};

const VersionContext = React.createContext<VersionContextValue | undefined>(
  undefined,
);

export default VersionContext;
export type { VersionContextValue };
