import { theme, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const DeletedCourseIcon: React.FC = () => {
  const { token } = theme.useToken();
  return (
    <Tooltip title="Удаленный">
      <DeleteOutlined style={{ color: token.colorError }} />
    </Tooltip>
  );
};

export default DeletedCourseIcon;
