import React from "react";
import { DeleteRestProps } from "@/shared/rest/lib/types";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { message, Modal } from "antd";
import { LearningMatrixEntry } from "@/models";

type LearningMatrixEntryDeleteButtonProps = {
  recordKeys: LearningMatrixEntry["id"][];
  onAfterDelete?: () => void;
};

const LearningMatrixEntryDeleteButton: React.FC<
  LearningMatrixEntryDeleteButtonProps
> = ({ recordKeys, onAfterDelete }) => {
  const [modal, modalHolder] = Modal.useModal();

  const onDelete = async () => {
    modal.warning({
      title: "Удалить записи матрицы",
      content: "Выбранная запись/записи будет удалена из системы",
      icon: null,
      width: "40%",
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () =>
        await axios
          .request({
            method: "DELETE",
            url: `/api/learning-matrix-entries/batch`,
            data: { resources: recordKeys },
          })
          .then(() => {
            onAfterDelete?.();
            message.success("Записи матрицы удалены");
          })
          .catch(() => {
            message.error("Не удалось удалить записи матрицы");
          }),
    });
  };

  const disabled = recordKeys.length === 0;

  return (
    <>
      {modalHolder}
      <Button type={"primary"} danger onClick={onDelete} disabled={disabled}>
        Удалить
      </Button>
    </>
  );
};

export default LearningMatrixEntryDeleteButton;
export type { LearningMatrixEntryDeleteButtonProps };
