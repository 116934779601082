import React from "react";
import { ButtonProps } from "antd/es/button";
import { Pool } from "@/models";
import { Button, Modal, message } from "antd";
import axios from "@/axios";
import useDeletingErrorsModal from "@/entities/automation/hooks/use-deleting-errors-modal";

type PoolRemoveButtonProps = ButtonProps & {
  poolId?: Pool["id"];
  poolsIds?: Pool["id"][];
  afterRemove?: () => void;
};

const PoolRemoveButton: React.FC<PoolRemoveButtonProps> = ({
  poolId,
  poolsIds = [],
  afterRemove,
  ...props
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [showDeletingErrorsModal, deletingErrorsModal] =
    useDeletingErrorsModal();

  if (poolId && !poolsIds.includes(poolId)) {
    poolsIds.push(poolId!);
  }

  const poolsWord = poolsIds.length > 1 ? "потоки" : "поток";

  const onClick = () => {
    modal.confirm({
      title: `Вы уверены, что хотите удалить ${poolsWord}?`,
      okText: "Удалить",
      okType: "danger",
      cancelText: "Отмена",
      onOk: async () =>
        await axios
          .delete(`/api/pools/batch`, {
            data: { resources: poolsIds },
          })
          .then(() => {
            afterRemove?.();
          })
          .catch((err) => {
            message.error(err.response.data.message ?? "Ошибка удаления");
            if (err.response.status === 400) {
              console.log(err.response.data);
              return showDeletingErrorsModal(
                err.response.data.message,
                err.response.data.data,
              );
            }
          }),
    });
  };

  return (
    <>
      {contextHolder}
      <React.Fragment key={"restriction-modal-holder"}>
        {deletingErrorsModal}
      </React.Fragment>
      <Button
        disabled={poolsIds.length === 0}
        danger
        type="primary"
        onClick={onClick}
        {...props}
      >
        Удалить
      </Button>
    </>
  );
};

export default PoolRemoveButton;
export type { PoolRemoveButtonProps };
