import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { theme } from "antd";
import { GlobalToken } from "antd/es/theme/interface";
import styled from "styled-components";
import React from "react";

const ProTableStyled = styled(ProTable<any, any>)<
  ProTableProps<any, any> & {
    token: GlobalToken;
  }
>`
  width: 100%;
  height: 100%;
  padding: 0;

  .ant-pro-card-body {
    padding: 0;
  }

  .ant-pagination {
    padding: ${({ token }) => token.sizeMD}px;
  }

  .ant-table-pagination.ant-pagination {
    margin: 0;
  }

  .ant-pro-table-list-toolbar-container {
    padding: ${({ token }) => token.sizeMD}px;
  }
` as <DataType, Params = Partial<DataType>, ValueType = "text">(
  props: ProTableProps<DataType, Params, ValueType> & { token: GlobalToken },
) => React.ReactElement;

function ProTableForAdmin<DataType extends Record<string, any>>(
  props: ProTableProps<DataType, Partial<DataType>>,
) {
  const { token } = theme.useToken();

  return <ProTableStyled<DataType> token={token} {...props} />;
}

export default ProTableForAdmin;
