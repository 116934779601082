export const DIRECTORY_SEPARATOR = "/";

export const PAGE_FILE_BASE_NAME = "page";
export const LAYOUT_FILE_BASE_NAME = "layout";
export const CONTEXT_FILE_BASE_NAME = "context";

/** Последовательность имеет значение */
export const PAGES_WRAPPER_BASE_NAMES = [
  LAYOUT_FILE_BASE_NAME,
  CONTEXT_FILE_BASE_NAME,
];

export const ROUTER_DIR_ALLOWED_FILE_BASE_NAMES = [
  PAGE_FILE_BASE_NAME,
  ...PAGES_WRAPPER_BASE_NAMES,
];
