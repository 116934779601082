import {  dateSTimeNormalize } from "@/shared/dayjs/lib/formats";

export const normalizeDateRange: typeof dateSTimeNormalize = (
  value,
  prevValue,
  allValues,
) => {
  if (value)
    return [
      dateSTimeNormalize?.(value[0], prevValue, allValues),
      dateSTimeNormalize?.(value[1], prevValue, allValues),
    ];
};
