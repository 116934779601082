import React from "react";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { Alert, message, Modal, Space } from "antd";
import { TreeNode } from "@/models";
import { ProForm, ProFormCheckbox } from "@ant-design/pro-components";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

type OrgStructureDeleteButtonProps = {
  recordKey: TreeNode["id"];
  onAfterDelete?: () => void;
  iconColor: string;
  type: "department" | "position";
};

const OrgStructureDeleteButton: React.FC<OrgStructureDeleteButtonProps> = ({
  recordKey,
  onAfterDelete,
  iconColor,
  type,
}) => {
  const { t } = useTranslation();
  const [modal, modalHolder] = Modal.useModal();
  const [form] = ProForm.useForm();

  const onDelete = async () => {
    modal.confirm({
      title: "Удаление элемента орг.структуры",
      content: (
        <Space direction="vertical" onClick={(e) => e.stopPropagation()}>
          <Alert
            showIcon
            type="error"
            description={`Данное ${t("подразделение")} / ${t(
              "должность",
            )} может быть использовано в автоназначении, в записях матрицы обучения, в записях о работе. Его удаление приведет к удалению всех связанных записей.`}
          />
          <ProForm
            form={form}
            submitter={false}
            preserve={false}
            initialValues={{ confirmation: true }}
          >
            <ProFormCheckbox
              name="confirmation"
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error("Вы должны подтвердить удаление"),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              Я уверен, что хочу удалить данный элемент орг.структуры
            </ProFormCheckbox>
          </ProForm>
        </Space>
      ),
      icon: null,
      width: "60%",
      okText: "Удалить",
      okButtonProps: {
        danger: true,
        type: "primary",
      },
      autoFocusButton: "cancel",
      cancelText: "Отменить",
      closable: true,
      onOk: async () => {
        try {
          await form.validateFields();
        } catch (error) {
          return Promise.reject(error);
        }
        await axios
          .request({
            method: "DELETE",
            url: `/api/${type}s/${recordKey}`,
          })
          .then(() => {
            onAfterDelete?.();
            const messageText =
              type === "department"
                ? t("Подразделение успешно удалено")
                : t("Должность успешно удалена");
            message.success(messageText);
          })
          .catch(() => {
            const deletedItemName = type === "department"
              ? "подразделение"
              : "должность";
            message.error(t(`Не удалось удалить ${deletedItemName}`));
          });
      },
    });
  };

  return (
    <>
      {modalHolder}
      <Button
        type="text"
        size="small"
        icon={
          <DeleteOutlined
            style={{
              color: iconColor,
            }}
          />
        }
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
        key={"delete"}
      />
    </>
  );
};

export default OrgStructureDeleteButton;
export type { OrgStructureDeleteButtonProps };
