import React, { useEffect } from "react";
import useSWR from "swr";
import axios from "@/axios";
import FeaturesContext from "@/entities/features/lib/context.tsx";
import { FeaturesData } from "@/entities/features/lib/model.ts";
import { useTranslation } from "react-i18next";

type Props = {
  children: React.ReactNode;
};

const FeaturesContextProvider: React.FC<Props> = ({ children }) => {
  const { data, ...request } = useSWR<FeaturesData, any, string>(
    "/api/features",
    async (url) => {
      return axios.get(url).then((res) => res.data);
    },
    { refreshInterval: 60 * 1000 },
  );

  const { i18n } = useTranslation();

  useEffect(() => {
    if (request.isLoading || !data) return;

    i18n.changeLanguage(!data.delovoy_lang ? "ru" : "delovoy");
  }, [request.isLoading, data]);

  if (request.error) throw request.error;

  const value: FeaturesData = {
    ...data!,
    isLoading: request.isLoading,
  };

  return (
    <FeaturesContext.Provider value={value}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesContextProvider;
