import React from "react";
import { ButtonProps } from "antd/es/button";
import { PoolParticipant } from "@/models";
import { Button, Modal } from "antd";
import axios from "@/axios";

type PoolParticipantRemoveButtonProps = ButtonProps & {
  poolParticipantId?: PoolParticipant["id"];
  poolParticipantsIds?: PoolParticipant["id"][];
  afterRemove?: () => void;
};

const PoolParticipantRemoveButton: React.FC<
  PoolParticipantRemoveButtonProps
> = ({
  poolParticipantId,
  poolParticipantsIds = [],
  afterRemove,
  ...props
}) => {
  const [modal, contextHolder] = Modal.useModal();

  if (poolParticipantId && !poolParticipantsIds.includes(poolParticipantId)) {
    poolParticipantsIds.push(poolParticipantId!);
  }

  const poolsWord = poolParticipantsIds.length > 1 ? "участников" : "участника";

  const onClick = () => {
    modal.confirm({
      title: `Вы уверены, что хотите удалить ${poolsWord} потока?`,
      okText: "Удалить",
      okType: "danger",
      cancelText: "Отмена",
      onOk: () =>
        axios
          .delete(`/api/pool-participants/batch`, {
            data: { resources: poolParticipantsIds },
          })
          .then(() => {
            afterRemove?.();
          }),
    });
  };

  return (
    <>
      <Button
        disabled={poolParticipantsIds.length === 0}
        danger
        type="primary"
        onClick={onClick}
        {...props}
      >
        Удалить
      </Button>
      {contextHolder}
    </>
  );
};

export default PoolParticipantRemoveButton;
export type { PoolParticipantRemoveButtonProps };
