export function getAllLocalStorageItems(): {key: string, value: string}[] {
  let items: {key: string, value: string}[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    if (key !== null) {
      let value = localStorage.getItem(key);
      if (value !== null) {
        items.push({key, value});
      }
    }
  }
  return items;
}
