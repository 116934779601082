import React from "react";
import {
  ProFormRadioGroupProps,
  ProFormSelect,
} from "@ant-design/pro-components";

const IndicatorIntervalSelect: React.FC<{
  name: ProFormRadioGroupProps["name"];
}> = ({ name }) => {
  return (
    <ProFormSelect
      name={name}
      label={"Интервал"}
      options={[
        { label: "1 д.", value: 1 },
        { label: "7 д.", value: 7 },
        { label: "30 д.", value: 30 },
        { label: "90 д.", value: 90 },
      ]}
      formItemProps={{ style: { marginBottom: 0 } }}
      allowClear={false}
    />
  );
};

export default IndicatorIntervalSelect;
