import React from "react";

const themeModes = ["light", "dark", "auto"] as const;

type ThemeValue = (typeof themeModes)[number];

const ThemeContext = React.createContext<{
  value: Exclude<ThemeValue, "auto">;
  mode: ThemeValue;
  set: (theme: ThemeValue) => void;
}>(undefined as any);

export default ThemeContext;
export type { ThemeValue };
