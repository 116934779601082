import React, { useEffect } from "react";
import useSWR from "swr";
import axios from "@/axios";
import ModeContext, { ModeContextValue } from "@/entities/mode/lib/context";
import { Mode } from "@/entities/mode/lib/type";
import ErrorPage from "@/shared/pages/error.tsx";
import * as Sentry from "@sentry/react";

type Props = {
  children: React.ReactNode;
};

const ModeContextProvider: React.FC<Props> = ({ children }) => {
  const { data, error, isLoading } = useSWR(
    "/api/.well-known/mode",
    (url) => axios.get<{ value: Mode }>(url).then((res) => res.data.value),
    { revalidateOnFocus: false, revalidateOnReconnect: false },
  );

  useEffect(() => {
    Sentry.setTag("mode", data);
  }, [data]);

  if (error) return <ErrorPage errorMessage={error.message} />;

  const value: ModeContextValue = {
    value: data!,
    isLoading,
  };

  return <ModeContext.Provider value={value}>{children}</ModeContext.Provider>;
};

export default ModeContextProvider;
