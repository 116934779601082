import React, { useState } from "react";
import { Button, Modal } from "antd";
import { ProFormCheckbox, ProFormItem } from "@ant-design/pro-components";
import { indicators } from "@/pages/space/manage/dashboards/indicators.tsx";
import { useTranslation } from "react-i18next";

const IndicatorsSelectModal: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Выбрать показатели
      </Button>
      <Modal
        title={"Показатели"}
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {indicators.map((indicator) => (
          <ProFormCheckbox
            key={indicator.name}
            name={["settings", indicator.name, "is_visible"]}
            formItemProps={{
              style: { marginBottom: 0 },
            }}
          >
            {t(indicator.title)}
          </ProFormCheckbox>
        ))}
      </Modal>
      {indicators.map((indicator) => (
        <ProFormItem
          hidden
          key={indicator.name}
          name={["settings", indicator.name, "is_visible"]}
        />
      ))}
    </>
  );
};

export default IndicatorsSelectModal;
