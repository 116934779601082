import React from "react";
import { Button, Flex, message, theme } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Department, Position, TreeNode } from "@/models";
import { ProForm, ProFormProps, ProFormText } from "@ant-design/pro-components";
import axios from "@/axios";
import styled from "styled-components";
import { deepmerge } from "deepmerge-ts";
import { useTranslation } from "react-i18next";
import OrgStructureIcon from "@/entities/org-structure/ui/icon";

type OrgStructureEditFormProps = ProFormProps & {
  type: "department" | "position";
  rest:
    | {
        type: "update";
        recordKey: TreeNode["id"];
        value: TreeNode["is_an"]["name"];
        onAfterUpdate?: () => void;
      }
    | {
        type: "create";
        onAfterCreate?: () => void;
        parentTreeNodeId?: TreeNode["parent_tree_node_id"];
        onCancel?: () => void;
      };
};

const FormStyledWrapper = styled.div`
  .ant-form-item {
    margin-block: 4px;
  }
`;

const OrgStructureEditForm: React.FC<OrgStructureEditFormProps> = ({
  type,
  rest,
  ...props
}) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [form] = ProForm.useForm();
  const [loading, setLoading] = React.useState(false);

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      initialValues: {
        name: rest.value,
      },
    });
    defaultProps.onFinish = async (values) => {
      setLoading(true);

      const url = `/api/${type}s/${rest.recordKey}`;

      return await axios
        .put(url, {
          name: values.name,
        })
        .then(() => {
          const messageText =
            type === "department"
              ? t("Подразделение успешно обновлено")
              : t("Должность успешно обновлена");
          message.success(messageText);
          rest.onAfterUpdate?.();
        })
        .catch((err) => {
          const entityName =
            type === "department" ? "подразделение" : "должность";
          message.error(
            err.response.data.message ?? t(`Не удалось обновить ${entityName}`),
          );
        })
        .finally(() => setLoading(false));
    };
  }

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps.onFinish = async (values) => {
      setLoading(true);

      const url = `/api/${type}s`;

      return await axios
        .post(url, {
          ...values,
          tree_node: { parent_tree_node_id: rest.parentTreeNodeId },
        })
        .then(() => {
          const messageText =
            type === "department"
              ? t("Подразделение успешно cоздано")
              : t("Должность успешно создана");
          message.success(messageText);

          rest.onAfterCreate?.();
        })
        .catch((err) => {
          const entityName =
            type === "department" ? "подразделение" : "должность";
          message.error(
            err.response?.data?.message ??
              t(`Не удалось создать ${entityName}`),
          );
        })
        .finally(() => setLoading(false));
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <FormStyledWrapper>
      <ProForm {...props} style={{ width: "100%" }} grid disabled={loading}>
        <Flex
          gap={token.paddingXXS}
          style={{ width: "100%" }}
          justify="space-between"
        >
          <ProFormText
            name={"name"}
            rules={[{ required: true }]}
            colProps={{ span: 24 }}
            fieldProps={{
              prefix: (
                <OrgStructureIcon
                  style={{ marginRight: token.marginXXS }}
                  type={type}
                />
              ),
              autoFocus: true,
              style: { borderColor: token.colorPrimaryHover },
              allowClear: false,
              suffix: (
                <Flex gap={token.paddingXXS}>
                  <Button
                    size="small"
                    icon={<CheckOutlined />}
                    type="text"
                    loading={loading}
                    onClick={() => form.submit()}
                  />
                  <Button
                    size="small"
                    icon={<CloseOutlined />}
                    type="text"
                    onClick={() =>
                      rest.type === "update"
                        ? rest.onAfterUpdate?.()
                        : rest.onCancel?.()
                    }
                  />
                </Flex>
              ),
            }}
          />
        </Flex>
      </ProForm>
    </FormStyledWrapper>
  );
};

export default OrgStructureEditForm;
