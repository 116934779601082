import { ProSchema } from "@ant-design/pro-utils/es/typing";

const typeEnumValue: ProSchema["valueEnum"] = {
  other: {
    text: "Другое",
  },
  "1c:hrm": {
    text: "1С:ЗУП",
  },
};

export default typeEnumValue;
