import React from "react";
import { Button, Result } from "antd";
import AuthButton from "@/shared/components/auth-button.tsx";

const AccessDeniedResult: React.FC = () => {
  return (
    <Result
      status="403"
      title="403 Нет доступа"
      subTitle="Извините, у вас нет доступа к данной компании, пожалуйста, обратитесь к администратору компании."
      extra={[
        <Button
          key="reload"
          onClick={() => window.location.reload()}
          type={"primary"}
        >
          Обновить страницу
        </Button>,
        <AuthButton type={"default"} />,
      ]}
    />
  );
};

export default AccessDeniedResult;
