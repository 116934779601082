import {DIRECTORY_SEPARATOR} from "@/shared/auto-router/lib/constants.ts";

/**
 * Is tree of directories and files
 */
export interface BaseFileSystemItem {
  name: string;
  path: string;
}

export interface FileSystemItemFile extends BaseFileSystemItem {
  type: "file";
  baseName: string;
}

export interface FileSystemItemDirectory extends BaseFileSystemItem {
  type: "directory";
  children: FileSystemItem[];
}

export type FileSystemItem = FileSystemItemFile | FileSystemItemDirectory;

export type FileSystemItems = FileSystemItem[];

// /**
//  * Insert file into file system
//  *
//  * If file is in directory then
//  * find or create directory and insert file into it
//  */
// const insertIntoFileSystem = (
//   path: string,
//   fileSystem: FileSystem,
//   parentPath: string = "/",
// ): FileSystem => {
//   const [currentName, ...restNames] = path
//     .replace(/^\//, "")
//     .split(DIRECTORY_SEPARATOR);
//
//   if (currentName !== undefined) {
//     const currentIsFile =
//       restNames.length === 0 && currentName.endsWith(".tsx");
//
//     parentPath = parentPath.replace(/\/$/, "");
//
//     if (currentIsFile) {
//       const fileSystemItem: FileSystemItem = {
//         name: currentName,
//         path: [parentPath, currentName].join(DIRECTORY_SEPARATOR),
//         type: "file",
//         baseName: currentName.replace(/\.tsx$/, ""),
//       };
//       fileSystem.push(fileSystemItem);
//     } else {
//       let currentDirectory = fileSystem.find(
//         (fileSystemItem) => fileSystemItem.name === currentName,
//       );
//
//       if (currentDirectory === undefined) {
//         currentDirectory = {
//           name: currentName,
//           path: [parentPath, currentName].join(DIRECTORY_SEPARATOR),
//           type: "directory",
//           children: [],
//         };
//         fileSystem.push(currentDirectory);
//       }
//
//       if (currentDirectory.type !== "directory") {
//         throw new Error(`currentDirectory.type !== "directory"`);
//       }
//
//       currentDirectory.children = insertIntoFileSystem(
//         restNames.join(DIRECTORY_SEPARATOR),
//         currentDirectory.children,
//         currentDirectory.path,
//       );
//     }
//   }
//
//   return fileSystem;
// };
//
// const changeDirFileSystem = (
//   fileSystem: FileSystem,
//   path: string,
// ): FileSystem => {
//   const [currentName, ...restNames] = path
//     .replace(/^\//, "")
//     .replace(/\/$/, "")
//     .split(DIRECTORY_SEPARATOR);
//
//   if (currentName === undefined) {
//     throw new Error(`currentName === undefined`);
//   }
//
//   const currentDirectory = fileSystem.find(
//     (fileSystemItem) => fileSystemItem.name === currentName,
//   );
//
//   if (currentDirectory === undefined || currentDirectory.type !== "directory") {
//     throw new Error(`No such directory`);
//   }
//
//   if (restNames.length === 0) {
//     return currentDirectory.children;
//   } else {
//     return changeDirFileSystem(
//       currentDirectory.children,
//       restNames.join(DIRECTORY_SEPARATOR),
//     );
//   }
// };

class FileSystem {
  private _items: FileSystemItems = [];

  public get items(): FileSystemItems {
    return this._items;
  }

  public set items(value: FileSystemItems) {
    this._items = value;
  }

  public setItems(value: FileSystemItems) {
    this._items = value;

    return this;
  }

  private _insert(path: string, parentPath: string) {
    const [currentName, ...restNames] = path
      .replace(/^\//, "")
      .split(DIRECTORY_SEPARATOR);

    if (currentName !== undefined) {
      const currentIsFile =
        restNames.length === 0 && currentName.endsWith(".tsx");

      parentPath = parentPath.replace(/\/$/, "");

      if (currentIsFile) {
        const fileSystemItem: FileSystemItem = {
          name: currentName,
          path: [parentPath, currentName].join(DIRECTORY_SEPARATOR),
          type: "file",
          baseName: currentName.replace(/\.tsx$/, ""),
        };
        this._items.push(fileSystemItem);
      } else {
        let currentDirectory = this._items.find(
          (fileSystemItem) => fileSystemItem.name === currentName,
        );

        if (currentDirectory === undefined) {
          currentDirectory = {
            name: currentName,
            path: [parentPath, currentName].join(DIRECTORY_SEPARATOR),
            type: "directory",
            children: [],
          };
          this._items.push(currentDirectory);
        }

        if (currentDirectory.type !== "directory") {
          throw new Error(`currentDirectory.type !== "directory"`);
        }

        currentDirectory.children = new FileSystem()
          .setItems(currentDirectory.children)
          ._insert(
            restNames.join(DIRECTORY_SEPARATOR),
            currentDirectory.path,
          ).items;
      }
    }

    return this;
  }

  public insert(path: string) {
    return this._insert(path, "/");
  }

  public changeDir(path: string) {
    const [currentName, ...restNames] = path
      .replace(/^\//, "")
      .replace(/\/$/, "")
      .split(DIRECTORY_SEPARATOR);

    if (currentName === undefined) {
      throw new Error(`currentName === undefined`);
    }

    const currentDirectory = this.items.find(
      (fileSystemItem) => fileSystemItem.name === currentName,
    );

    if (
      currentDirectory === undefined ||
      currentDirectory.type !== "directory"
    ) {
      throw new Error(`No such directory`);
    }

    this.items = currentDirectory.children;

    if (restNames.length !== 0) {
      this.changeDir(restNames.join(DIRECTORY_SEPARATOR));
    }

    return this;
  }
}

export default FileSystem;
