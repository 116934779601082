import React from "react";
import { Space } from "@/entities/space/lib/model";

type SpaceContextValue = Space & {
  refresh: () => void | Promise<void>;
  isLoading: boolean;
};

const SpaceContext = React.createContext<SpaceContextValue | undefined>(
  undefined,
);

export default SpaceContext;
export type { SpaceContextValue };
