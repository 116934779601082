import React from "react";
import { Client } from "@/entities/client/lib/type.ts";

type ClientContextValue = Client;

const ClientContext = React.createContext<ClientContextValue | undefined>(
  undefined,
);

export default ClientContext;
export type { ClientContextValue };
