import React from "react";
import { Modal, ModalProps } from "antd";
import FilePreview, { FilePreviewProps } from "@/entities/file/ui/preview";

type Props = Omit<ModalProps, "content" | "children"> & {
  previewProps: FilePreviewProps;
};

const FilePreviewModal: React.FC<Props> = ({ previewProps, ...modalProps }) => {
  return (
    <Modal destroyOnClose footer={null} title={"Просмотр"} {...modalProps}>
      <FilePreview {...previewProps} />
    </Modal>
  );
};

export default FilePreviewModal;
