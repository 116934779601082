import React from "react";
import { Button, message, Modal } from "antd";
import {
  ModalForm,
  ModalFormProps,
  ProForm,
  ProFormSelect,
} from "@ant-design/pro-components";
import { AxiosRequestConfig } from "axios";
import axios from "@/axios";
import {
  ProSchema,
  ProSchemaValueEnumObj,
} from "@ant-design/pro-utils/es/typing";
import statusValueEnum from "@/entities/space/lib/status-value-enum";
import { RestOnAfterBatchUpdate } from "@/shared/rest/lib/types";
import { OrionRestBatchUpdateResponse } from "@/shared/types/orion-rest";
import { Space } from "@/entities/space/lib/model";

const updateStatusValueEnum: ProSchema<Space>["valueEnum"] = () => {
  return Object.fromEntries(
    Object.entries(statusValueEnum as any).filter(
      ([key]) =>
        (key.startsWith("to_") && key !== "to_create") || key === "active",
    ),
  ) as ProSchemaValueEnumObj;
};

type BatchUpdateProps = {
  rest: {
    recordKeys: Space["id"][];
    onAfterBatchUpdate: RestOnAfterBatchUpdate<Space>;
  };
  trigger?: JSX.Element;
};

const BatchUpdate: React.FC<BatchUpdateProps> = ({
  rest: { recordKeys, onAfterBatchUpdate },
  trigger,
}) => {
  const [form] = ProForm.useForm<Partial<Space>>();

  const [modal, modalHolder] = Modal.useModal();

  const disabled = recordKeys.length === 0;

  const onFinish: ModalFormProps<Partial<Space>>["onFinish"] = async (
    values,
  ) => {
    return modal.confirm({
      title: "Вы уверены, что сделать?",
      onOk: async () => {
        if (disabled) throw new Error("Disabled");

        const axiosConfig: AxiosRequestConfig = {
          method: "PATCH",
          url: "/api/spaces/batch",
          data: { resources: {} },
        };

        recordKeys.forEach((id) => {
          axiosConfig.data.resources[id] = values;
        });

        return axios<OrionRestBatchUpdateResponse<Space>>(axiosConfig)
          .then(async (res) => {
            await onAfterBatchUpdate(res.data.data);
            return true;
          })
          .catch((err) => {
            console.error(err);
            message.error(
              err.response.data.message ?? "Ошибка при обновлении пространства",
            );
            return false;
          });
      },
    });
  };

  trigger = trigger ? (
    React.cloneElement(trigger, { disabled })
  ) : (
    <Button type={"primary"} disabled={disabled}>
      Редактировать
    </Button>
  );

  return (
    <>
      {modalHolder}
      <ModalForm<Partial<Space>>
        form={form}
        trigger={trigger}
        modalProps={{
          title: "Множественное редактирование",
          destroyOnClose: true,
        }}
        onFinish={onFinish}
      >
        <ProFormSelect<Space>
          name={"status"}
          label={"Статус"}
          valueEnum={updateStatusValueEnum as any}
        />
      </ModalForm>
    </>
  );
};

export default BatchUpdate;
export type { BatchUpdateProps };
