import React from "react";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Modal, ModalProps, Space, Typography, message } from "antd";
import axios from "@/axios";
import { Secrets } from "./secrets-descriptions";
import useSecretsModal from "../hooks/use-secrets-modal";

type ResetSecretsButtonProps = ModalProps & {
  recordKey?: number;
};

const ResetSecretsButton: React.FC<ResetSecretsButtonProps> = ({
  recordKey,
}) => {
  const [modal, modalHolder] = Modal.useModal();
  const { showSecretsModal, modalHolder: secretsModalHolder } =
    useSecretsModal();

  const onResetPassword = async () => {
    const resetDataResponse = await axios
      .patch<Secrets>(`/api/service-accounts/${recordKey}/reset-secrets`)
      .then(({ data }) => data)
      .catch((err) => {
        message.error(err.response.data.message ?? "Ошибка восстановления");
      });

    if (resetDataResponse) {
      await showSecretsModal(resetDataResponse, recordKey);
    } else {
      message.error("Ошибка восстановления");
    }
  };

  const restoreServiceAccountModal = () => {
    modal.confirm({
      title: `Восстановить данные сервисного аккаунта`,
      okText: "Восстановить",
      width: "40% ",
      icon: null,
      content: (
        <Space direction="vertical" size={0}>
          <Typography.Text type="secondary">
            Для выбранного аккаунта будет сгенерирован новый пароль для доступа
            к интеграции.
          </Typography.Text>
          <Typography.Text type="secondary">
            Для корректного проведения последующих интеграций произведите
            настройку подключения аккаунта в вашем сервисе.
          </Typography.Text>
        </Space>
      ),
      onOk: async () => {
        return onResetPassword();
      },
    });
  };

  return (
    <>
      {modalHolder}
      <React.Fragment key={"secrets"}>{secretsModalHolder}</React.Fragment>
      <Button
        type={"primary"}
        onClick={() => restoreServiceAccountModal()}
        disabled={recordKey ? false : true}
      >
        Восстановить данные для интеграции
      </Button>
    </>
  );
};

export default ResetSecretsButton;
export type { ResetSecretsButtonProps };
