import React, { useState } from "react";
import { Lesson, Section } from "@/models";
import {
  ProForm,
  ProFormDigit,
  ProFormProps,
  ProFormRadio,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { Button, Flex, Modal, Space, Typography, message } from "antd";
import { ButtonProps } from "antd/es/button";
import axios from "@/axios";
import {
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import { UpdateRestProps } from "@/shared/rest/lib/types";
import { ProFormStyled } from "@/entities/automation/ui/pro-form-styled";
import useMe from "@/entities/me/lib/use";

type SectionFormProps = ProFormProps<Section> & {
  rest: UpdateRestProps<Section>;
  onDelete?: () => void;
};

const SectionForm: React.FC<SectionFormProps> = ({
  rest,
  onDelete: onDeleteProp,
  ...props
}) => {
  const [form] = ProForm.useForm<Section>();
  const [deleteForm] = ProForm.useForm<Section>();
  const [modal, modalHolder] = Modal.useModal();
  const member = useMe();

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    disabled: !member.permissions.includes("course:update"),
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Section>>(`/api/sections/${rest.recordKey}`)
        .then((res) => res.data.data);
    };
    defaultProps.onFinish = async (values) => {
      setIsSubmitButtonLoading(true);
      return axios
        .put<OrionRestUpdateResponse<Section>>(
          `/api/sections/${rest.recordKey}`,
          values,
        )
        .then((res) => {
          message.success("Раздел успешно обновлен");
          rest.onAfterUpdate?.(res.data.data);
          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении раздела: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        })
        .finally(() => setIsSubmitButtonLoading(false));
    };
  }

  props = deepmerge(defaultProps, props, overrideProps);

  const onDelete: ButtonProps["onClick"] = () => {
    deleteForm.setFieldValue("on_deleted_action", "delete_children_materials");
    modal.warning({
      title: "Удаление раздела",
      content: (
        <Space direction="vertical">
          <Typography.Text type="secondary">
            Выберите что сделать с материалами раздела:
          </Typography.Text>
          <ProFormStyled form={deleteForm} submitter={false}>
            <ProFormRadio.Group
              name="on_deleted_action"
              options={[
                {
                  label: (
                    <Space direction="vertical" size={0}>
                      <Typography.Text>
                        Удалить вложенные материалы вместе с разделом
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        Вложенные в раздел материалы будут удалены из курса
                        вместе с разделом
                      </Typography.Text>
                    </Space>
                  ),
                  value: "delete_children_materials",
                },
                {
                  label: (
                    <Space direction="vertical" size={0}>
                      <Typography.Text>Удалить только раздел</Typography.Text>
                      <Typography.Text type="secondary">
                        Вложенные в раздел материалы останутся в курсе
                      </Typography.Text>
                    </Space>
                  ),
                  value: "save_children_materials",
                },
              ]}
            />
          </ProFormStyled>
        </Space>
      ),
      icon: null,
      width: "50%",
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: async () => {
        await axios.put(`/api/sections/${props.id}`, {
          on_deleted_action: deleteForm.getFieldValue("on_deleted_action"),
        });
        await axios.delete(`/api/sections/${props.id}`);
        await onDeleteProp?.();
        message.success("Раздел успешно удалён");
      },
    });
  };

  return (
    <>
      {modalHolder}
      <ProForm<Section>
        form={form}
        submitter={{
          render: false,
        }}
        {...props}
      >
        <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
          {member.permissions.includes("course:update") && (
            <>
              <Button danger type={"primary"} onClick={onDelete}>
                Удалить
              </Button>

              <Button
                type={"primary"}
                loading={isSubmitButtonLoading}
                onClick={form.submit}
              >
                Сохранить
              </Button>
            </>
          )}
        </Flex>
        <ProFormDigit name="course_id" label="ID курса" hidden />
        <ProFormText
          name="name"
          label="Название"
          rules={[{ required: true, max: 255 }]}
        />
        <ProFormTextArea name="description" label="Описание" />
      </ProForm>
    </>
  );
};

export default SectionForm;
export type { SectionFormProps };
