import React from "react";
import LicenseForm, {
  LicenseUpdateProps,
} from "@/entities/license/ui/form.tsx";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Flex, Modal } from "antd";
import { deepmerge } from "deepmerge-ts";

type LicenseModalFormProps = LicenseUpdateProps & {
  trigger?: JSX.Element;
};

const LicenseModalForm: React.FC<LicenseModalFormProps> = ({
  rest,
  trigger,
  ...formProps
}) => {
  const [open, setOpen] = React.useState(false);

  const triggerText = rest.type === "create" ? "Создать" : "Редактировать";
  if (!trigger) {
    trigger = <Button type={"primary"}>{triggerText}</Button>;
  }
  let disabled = rest.type === "update" && rest.recordKey === undefined;
  if (rest.type === "update" && typeof rest.recordKey === "object") {
    disabled = rest.recordKey.length !== 1;
  }

  const title =
    rest.type === "create" ? "Создание лицензии" : "Редактирование лицензии";

  if (rest.type === "update") {
    const restOnAfterUpdate = rest.onAfterUpdate;
    rest.onAfterUpdate = async (values) => {
      setOpen(false);
      await restOnAfterUpdate?.(values);
    };
  }

  if (rest.type === "create") {
    const restOnAfterCreate = rest.onAfterCreate;
    rest.onAfterCreate = async (values) => {
      setOpen(false);
      await restOnAfterCreate?.(values);
    };
  }

  const defaultFormProps: typeof formProps = {
    submitter: {
      render: (_, dom) => {
        return <Flex justify={"end"}>{dom}</Flex>;
      },
      resetButtonProps: false,
    },
  };

  formProps = deepmerge(defaultFormProps, formProps);

  return (
    <>
      {React.cloneElement(trigger, {
        disabled,
        onClick: () => setOpen(true),
      })}
      <Modal
        width={800}
        open={open}
        title={title}
        destroyOnClose
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <LicenseForm {...formProps} rest={rest} />
      </Modal>
    </>
  );
};

export default LicenseModalForm;
export type { LicenseModalFormProps };
