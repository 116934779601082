import React, { useEffect } from "react";
import Spin from "@/shared/components/spin";
import { useNavigate, useSearchParams } from "react-router-dom";

const Page: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    navigate(`/education?${searchParams.toString()}`);
  }, []);

  return <Spin />;
};

export default Page;
