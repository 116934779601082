import { TabsProps } from "antd";
import useQueryState from "@/shared/hooks/use-query-state";

type Tab = string | undefined;

const useQueryTabs = (
  defaultTab: Tab,
  tabsItems: Required<TabsProps>["items"],
  paramName: string = "tab",
) => {
  return useQueryState<Tab>(paramName, {
    defaultValue: defaultTab,
    valueType: "string",
    onValidateValue: (value) => {
      return tabsItems.some((item) => item.key === value);
    },
  });
};

export default useQueryTabs;
