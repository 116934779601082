import React from "react";
import {
  ProDescriptions,
  ProDescriptionsProps,
} from "@ant-design/pro-components";
import { Typography, theme } from "antd";
import { ExamResultQuestionsItem } from "@/entities/exam/lib/model";
import deepmergeProDescriptionsProps from "@/shared/ant-design-pro-components/descriptions/lib/deepmerge-props";

type ExamsDescriptionsProps = ProDescriptionsProps<
  ExamResultQuestionsItem,
  any
>;

const ExamReplyQuestionsDescriptions: React.FC<ExamsDescriptionsProps> = ({
  ...props
}) => {
  const { token } = theme.useToken();

  const defaultProps: Partial<typeof props> = {
    columns: [
      {
        dataIndex: "text",
        render: (_, { text }) => <Typography.Text>{text}</Typography.Text>,
      },
      {
        title: <Typography.Text strong>Балл</Typography.Text>,
        dataIndex: "points",
      },
      {
        title: <Typography.Text strong>Ваш ответ</Typography.Text>,
        dataIndex: "answers.*.text.join()",
        render: (_, { answers }) => {
          return answers
            .filter((answer) => answer.is_replied)
            .map((answer) => {
              return answer.value;
            })
            .join(", ");
        },
      },
      {
        dataIndex: "is_correct",
        title: <Typography.Text strong>Результат</Typography.Text>,
        valueEnum: {
          true: {
            text: "Верно",
            color: token.colorSuccess,
          },
          false: {
            text: "Неверно",
            color: token.colorError,
          },
        },
      },
    ],
  };

  props = deepmergeProDescriptionsProps(defaultProps, props);

  return <ProDescriptions {...props} />;
};

export default ExamReplyQuestionsDescriptions;
