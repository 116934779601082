import React from "react";
import { Button, Result } from "antd";

type ErrorPageProps = {
  errorMessage?: string;
};

const Error: React.FC<ErrorPageProps> = ({ errorMessage }) => {
  return (
    <Result
      style={{ width: "100%", height: "100%" }}
      status="error"
      title={"Что-то пошло не так"}
      subTitle={errorMessage ?? "Неизвестная ошибка"}
      extra={[
        <Button
          key="reload"
          onClick={() => window.location.reload()} // TODO: How remove using window.location
          type={"primary"}
        >
          Обновить страницу
        </Button>,
        <Button key="home" onClick={() => (window.location.href = "/")}>
          Вернуться на главную
        </Button>,
      ]}
    />
  );
};

export default Error;
