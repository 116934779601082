import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import axios from "@/axios";
import { Spin } from "antd";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { Course } from "@/models";

const BreadcrumbItem: React.FC = () => {
  const { course_id } = useParams();

  const {
    data: course,
    error,
    isLoading,
  } = useSWR(`/api/courses/${course_id!}`, async (url) => {
    return axios
      .get<OrionRestShowResponse<Course>>(url)
      .then((res) => res.data.data);
  });

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!course) throw new Error("Course not found");

  return <>{course.name}</>;
};

export default BreadcrumbItem;
