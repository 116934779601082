import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Flex, Tabs as AntTabs, TabsProps, theme } from "antd";
import CourseEmbedsTreeEditor from "@/pages/space/manage/courses/[course_id]/embeds-tree-editor";
import PoolTable from "@/entities/pool/ui/table";
import useQueryTabs from "@/shared/hooks/use-query-tab";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import PoolTemplateTable from "@/entities/pool-template/ui/table";
import useMe from "@/entities/me/lib/use";
import styled from "styled-components";

const Tabs = styled(AntTabs)`
  .ant-tabs-content {
    height: 100%;
  }
`;

const Page: React.FC = () => {
  const { token } = theme.useToken();
  const { course_id } = useParams();
  const navigate = useNavigate();
  const member = useMe();

  const [poolTemplateHasColumns, setPoolTemplateHasColumns] =
    useState<boolean>(true);

  const tabs: TabsProps["items"] = [
    {
      key: "embeds",
      label: "Содержание",
      style: {
        height: "100%",
      },
      children: <CourseEmbedsTreeEditor courseId={Number(course_id!)} />,
    },
    ...(member.permissions.includes("pool:view")
      ? [
          {
            key: "pools",
            label: "Потоки",
            style: {
              height: "100%",
            },
            children: (
              <PoolTable
                courseId={Number(course_id)}
                hasQueryParams
                options={{
                  setting: {
                    draggable: false,
                    showListItemOption: false,
                  },
                  reload: false,
                  density: false,
                }}
                columnsState={{
                  persistenceKey: "pool-table",
                }}
                columns={[
                  {
                    dataIndex: "name",
                    render: (dom, { course_id, id: pool_id }) => {
                      if (
                        member.permissions.includes("pool_participant:view")
                      ) {
                        return (
                          <Link
                            to={`/manage/courses/${course_id}/pools/${pool_id}`}
                          >
                            {dom}
                          </Link>
                        );
                      }
                      return dom;
                    },
                    hideInSetting: true,
                    disable: true,
                  },
                  { dataIndex: "key" },
                  { dataIndex: "status" },
                  { dataIndex: "participants_count" },
                  { dataIndex: "starts_at" },
                  { dataIndex: "ends_at" },
                  { dataIndex: "created_at" },
                ]}
                rest={{
                  onAfterCreate: (pool) => {
                    navigate(
                      `/manage/courses/${pool.course_id}/pools/${pool.id}?pool_participant_create=true`,
                    );
                  },
                }}
              />
            ),
          },
        ]
      : []),
    ...(member.permissions.includes("pool:view")
      ? [
          {
            key: "pool-template",
            label: "Шаблоны потоков",
            style: {
              height: "100%",
            },
            children: (
              <PoolTemplateTable
                hasQueryParams={{
                  paramName: "pool_template_query",
                }}
                options={{
                  setting: {
                    draggable: false,
                    showListItemOption: false,
                  },
                  reload: false,
                  density: false,
                }}
                rowSelection={poolTemplateHasColumns ? {} : false}
                columnsState={{
                  onChange: (columnState) =>
                    setPoolTemplateHasColumns(
                      Object.values(columnState).some(({ show }) => show),
                    ),
                  persistenceKey: "pool-template-table",
                }}
                courseId={Number(course_id)}
                columns={[{ dataIndex: "name" }, { dataIndex: "created_at" }]}
              />
            ),
          },
        ]
      : []),
  ];

  const [activeTab, setActiveTab] = useQueryTabs(
    member.permissions.includes("pool:view") ? "pools" : "embeds",
    tabs,
  );

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Flex style={{ width: "100%", height: "calc(100% - 30px)" }}>
        <Tabs
          activeKey={activeTab}
          items={tabs}
          style={{ height: "100%", width: "100%" }}
          onChange={setActiveTab}
          tabBarStyle={{ backgroundColor: token.colorBgBase }}
        />
      </Flex>
    </Flex>
  );
};

export default Page;
