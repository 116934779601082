import { Scorm } from "@/models.ts";

const statusValueEnum: Record<Scorm["status"], string> = {
  pending: "Ожидает пакета",
  to_deploy: "Ожидает публикации",
  deploying: "Публикуется",
  active: "Активен",
  unsupported: "Неподдерживаемый пакет",
};

export default statusValueEnum;
