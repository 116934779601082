import React, { useState } from "react";
import { Dashboard, PoolParticipant } from "@/models.ts";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { Link } from "react-router-dom";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import { Card, Col } from "antd";
import { ProForm } from "@ant-design/pro-components";
import { IndicatorContentMetadata } from "@/pages/space/manage/dashboards/indicator-content-metadata.ts";
import useMe from "@/entities/me/lib/use";

type Record = PoolParticipant;

export const metadata: IndicatorContentMetadata = {
  name: "overdue_education",
  title: "Просроченные обучения сотрудников",
  position: 8,
};

const IndicatorContentOverdueEducation: React.FC = () => {
  const [error, setError] = useState<Error | null>(null);
  const form = ProForm.useFormInstance<Dashboard>();
  const is_visible = ProForm.useWatch(
    ["settings", metadata.name, "is_visible"],
    form,
  );
  const member = useMe();

  const request: ProTableProps<Record, any>["request"] = async () => {
    const now = new Date().toISOString();
    return axios
      .post<OrionRestIndexResponse<Record>>(
        "/api/pool-participants/search",
        {
          sort: [{ field: "ends_at", direction: "asc" }],
          filters: [
            { field: "pool.status", operator: "!=", value: "completed" },
            { field: "status", operator: "!=", value: "completed" },
            { field: "ends_at", operator: "<", value: now },
          ],
          includes: [{ relation: "member" }, { relation: "pool" }],
        },
        { params: { limit: 10 } },
      )
      .then((res) => {
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  if (error) throw error;

  return (
    <>
      {is_visible && (
        <Col span={24}>
          <Card title={metadata.title} bodyStyle={{ padding: 8 }}>
            <ProTable<Record>
              rowKey={"id"}
              search={false}
              toolBarRender={false}
              request={request}
              pagination={false}
              style={{ width: "100%" }}
              size={"small"}
              columns={[
                {
                  title: "ФИО",
                  dataIndex: ["member", "full_name"],
                  render: (text, record) => {
                    if (member.permissions.includes("member:view")) {
                      return (
                        <Link to={`/manage/members/${record.member!.id}`}>
                          {text}
                        </Link>
                      );
                    }
                    return text;
                  },
                },
                {
                  title: "Поток",
                  dataIndex: ["pool", "name"],
                  ellipsis: true,
                  renderText: (text, { pool }) => (
                    <Link
                      to={`/manage/courses/${pool!.course_id}/pools/${
                        pool!.id
                      }`}
                    >
                      {text}
                    </Link>
                  ),
                },
                {
                  title: "Планируемая дата завершения",
                  dataIndex: "ends_at",
                  valueType: "dateTime",
                  fieldProps: { format: dateSTime },
                },
              ]}
            />
          </Card>
        </Col>
      )}
    </>
  );
};

export default IndicatorContentOverdueEducation;
