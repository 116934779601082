import React from "react";
import { useAuth } from "react-oidc-context";
import Spin from "@/shared/components/spin";
import NotFoundPage from "@/shared/pages/not-found";
import LoginRequiredPage from "@/shared/pages/login-required";

interface Props {
  children: React.ReactNode;
  type: "confident" | "loaded" | "required";
  accessed?: boolean;
}

const WithAuth: React.FC<Props> = ({ children, type, accessed = true }) => {
  const auth = useAuth();

  if (type === "loaded" && (auth.isLoading || !accessed)) return <Spin />;
  if (type === "confident" && (!auth.isAuthenticated || !accessed))
    return <NotFoundPage />;
  if (type === "required" && (!auth.isAuthenticated || !accessed))
    return <LoginRequiredPage />;

  return <>{children}</>;
};

export default WithAuth;
