import { ParamsType } from "@ant-design/pro-provider";
import { deepmerge } from "deepmerge-ts";
import arraysEqual from "@/shared/helpers/arrays-equal";
import { ProTableProps } from "@/shared/ant-design-pro-components/table/ui";

const deepmergeProTableProps = <
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = "text",
>(
  defaultProps: ProTableProps<DataType, Params, ValueType> = {},
  props: ProTableProps<DataType, Params, ValueType> = {},
  overrideProps: ProTableProps<DataType, Params, ValueType> = {},
): ProTableProps<DataType, Params, ValueType> => {
  let columnsProps = props.columns || [];
  const defaultColumnsProps = defaultProps.columns || [];
  columnsProps = columnsProps.map((column, index) => {
    const defaultColumn =
      defaultColumnsProps.find((defaultColumn) => {
        return (
          (typeof defaultColumn.dataIndex === "object" &&
            typeof column.dataIndex === "object" &&
            arraysEqual(defaultColumn.dataIndex, column.dataIndex)) ||
          defaultColumn.dataIndex === column.dataIndex
        );
      }) ?? {};

    return {
      ...deepmerge(defaultColumn, column),
      dataIndex: column.dataIndex,
    } as any;
  });
  props = deepmerge(defaultProps, props);
  props.columns = columnsProps;

  return { ...props, ...overrideProps };
};

export default deepmergeProTableProps;
