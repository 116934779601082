import React from "react";
import { Image, Typography } from "antd";
import { File as FileModel } from "@/models";
import PreviewPdf from "@/entities/file/ui/preview-pdf";
import PreviewVideo from "@/entities/file/ui/preview-video";

type FilePreviewProps = {
  file: Pick<FileModel, "type"> & {
    url: string;
  };
};

const FilePreview: React.FC<FilePreviewProps> = ({ file: { url, type } }) => {
  switch (type.split("/")[1]) {
    case "pdf":
      return <PreviewPdf src={url} />;
  }

  switch (type.split("/")[0]) {
    case "image":
      return (
        <Image
          src={url}
          preview={false}
          style={{
            width: "100%",
          }}
        />
      );
    case "video":
      return <PreviewVideo src={url} />;
    default:
      return (
        <Typography.Text>
          Невозможно отобразить данный тип файла
        </Typography.Text>
      );
  }
};

export default FilePreview;
export type { FilePreviewProps };
