import React from "react";
import { BroadcastMessages } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { RestCallbacks } from "@/shared/rest/lib/types";
import BroadcastMessagesModalForm from "@/entities/broadcast-messages/ui/modal-form.tsx";
import { dateSTime, toISOString } from "@/shared/dayjs/lib/formats.tsx";
import valueEnum from "@/entities/broadcast-messages/lib/type-value-enum.tsx";
import {
  CheckCircleOutlined,
  EditOutlined,
  FlagOutlined,
} from "@ant-design/icons";
import { Space, Tooltip, Typography } from "antd";
import BroadcastMessagesDeleteTableRowAction from "@/entities/broadcast-messages/ui/delete-table-row-actions.tsx";
import dayjs from "dayjs";
import { now } from "lodash";

type Data = BroadcastMessages;

type Params = Partial<BroadcastMessages>;

type TableProps = ProTableProps<Data, Params>;

type Props = TableProps & {
  rest?: RestCallbacks<BroadcastMessages>;
};

const BroadcastMessageTable: React.FC<Props> = ({ rest, ...props }) => {
  const request: TableProps["request"] = async (params, sort, filter) => {
    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/broadcast-messages/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    const { data } =
      await axios.request<OrionRestIndexResponse<BroadcastMessages>>(
        actionConfig,
      );
    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const toolBarRender: TableProps["toolBarRender"] = (action) => {
    if (!action) throw new Error("action is undefined");

    return [
      <BroadcastMessagesModalForm
        key={"create"}
        rest={{
          type: "create",
          onAfterCreate: () => {
            action.reload();
          },
        }}
      />,
    ];
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    options: {
      density: true,
      fullScreen: true,
      reload: true,
      setting: true,
    },
    pagination: { showSizeChanger: true },
    request,
    toolBarRender,
    search: false,
    columns: [
      {
        title: "ID",
        dataIndex: "id",
        sorter: true,
        copyable: true,
        width: 100,
      },
      {
        title: "Тип",
        dataIndex: "type",
        sorter: true,
        filters: true,
        valueEnum: valueEnum,
        width: 200,
      },
      {
        title: "Текст",
        dataIndex: "text",
        sorter: true,
        filters: true,
        filterMode: "search",
      },
      {
        title: "Дата начала",
        dataIndex: "starts_at",
        sorter: true,
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        width: "200px",
      },
      {
        title: "Дата окончания",
        dataIndex: "ends_at",
        sorter: true,
        valueType: "dateTime",
        width: "200px",
        fieldProps: { format: dateSTime },
      },
      {
        title: "Действия",
        dataIndex: "actions",
        align: "center",
        render: (_, record, __, action) => (
          <Space>
            <BroadcastMessagesModalForm
              key={`update-${record.id}`}
              trigger={
                <Tooltip title={"Редактировать"} mouseEnterDelay={1}>
                  <Typography.Link>
                    <EditOutlined />
                  </Typography.Link>
                </Tooltip>
              }
              rest={{
                type: "update",
                recordKey: record.id,
                onAfterUpdate: () => {
                  action?.reload();
                },
              }}
            />
            {dayjs(record.starts_at).isAfter(now()) && (
              <div key={`start-${record.id}`}>
                <Tooltip title={"Начать рассылку"} mouseEnterDelay={1}>
                  <Typography.Link
                    onClick={() => {
                      axios
                        .put(`/api/broadcast-messages/${record.id}`, {
                          ...record,
                          starts_at: toISOString(dayjs()),
                        })
                        .then(() => {
                          action?.reload();
                        });
                    }}
                  >
                    <CheckCircleOutlined />
                  </Typography.Link>
                </Tooltip>
              </div>
            )}
            {!dayjs(record.starts_at).isAfter(now()) &&
              (!record.ends_at || dayjs(record.ends_at).isAfter(now())) && (
                <div key={`end-${record.id}`}>
                  <Tooltip title={"Завершить рассылку"} mouseEnterDelay={1}>
                    <Typography.Link
                      onClick={() => {
                        axios
                          .put(`/api/broadcast-messages/${record.id}`, {
                            ...record,
                            ends_at: toISOString(dayjs()),
                          })
                          .then(() => {
                            action?.reload();
                          });
                      }}
                    >
                      <FlagOutlined />
                    </Typography.Link>
                  </Tooltip>
                </div>
              )}
            <BroadcastMessagesDeleteTableRowAction
              key={`delete-${record.id}`}
              recordKey={record.id}
              onAfterRemove={() => {
                action?.reload();
              }}
            />
          </Space>
        ),
        width: 100,
      },
    ],
  };

  return (
    <ProTable<Data, Params>
      {...deepmergeProTableProps(defaultProps as any, props as any)}
    />
  );
};
export default BroadcastMessageTable;
