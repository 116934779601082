import React from "react";
import useSWR from "swr";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { BroadcastMessages } from "@/models";
import { AxiosRequestConfig } from "axios";
import { Alert, Flex } from "antd";

const LayoutBroadcastMessage: React.FC = () => {
  const { data, error } = useSWR("/api/broadcast-messages/search", (url) => {
    const now = new Date().toISOString();

    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url,
      data: {
        filters: [
          {
            type: "and",
            field: "type",
            operator: "=",
            value: "banner",
          },
          {
            type: "and",
            nested: [
              {
                type: "or",
                field: "ends_at",
                operator: ">=",
                value: now,
              },
              {
                type: "or",
                field: "ends_at",
                operator: "=",
                value: null,
              },
            ],
          },
          {
            type: "and",
            nested: [
              {
                type: "or",
                field: "starts_at",
                operator: "<=",
                value: now,
              },
              {
                type: "or",
                field: "starts_at",
                operator: "=",
                value: null,
              },
            ],
          },
        ],
      },
    };

    return axios
      .request<OrionRestIndexResponse<BroadcastMessages>>(actionConfig)
      .then((res) => res.data.data);
  });

  if (error) throw error;

  return (
    <Flex vertical>
      {data?.map((broadcastMessage) => (
        <Alert
          key={broadcastMessage.id}
          message={broadcastMessage.text}
          banner
        />
      ))}
    </Flex>
  );
};

export default LayoutBroadcastMessage;
