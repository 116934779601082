import React from "react";
import { ProForm, ProFormProps } from "@ant-design/pro-components";
import FormQuestionsMenu from "@/entities/test/ui/form-questions-menu";
import axios from "@/axios";
import { Test } from "@/models";
import FormFields from "@/entities/test/ui/form-fields";
import { AxiosRequestConfig } from "axios";
import { deepmerge } from "deepmerge-ts";
import { message } from "antd";
import {
  OrionRestCreateResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import Question from "@/entities/test/lib/question";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useMe from "@/entities/me/lib/use";

type TestFormQuestionRecord = Question & {
  is_deleted?: boolean;
};

// TODO: Why "exams"?
type TestFormRecord = Omit<
  Test,
  "questions" | "exams" | "test_participants"
> & {
  questions: TestFormQuestionRecord[];
};

type TestFormProps = Partial<ProFormProps<TestFormRecord>> &
  (
    | {
        type: "create";
        // create
        afterCreate?: (values: TestFormRecord) => unknown | Promise<unknown>;
        // update
        testId?: undefined;
        onAfterUpdate?: undefined;
        onAfterDelete?: undefined;
      }
    | {
        type: "update";
        // create
        afterCreate?: undefined;
        // update
        testId: Test["id"];
        onAfterUpdate?: (values: TestFormRecord) => unknown | Promise<unknown>;
        onAfterDelete?: () => unknown | Promise<unknown>;
      }
  );

const TestForm: React.FC<TestFormProps> = ({
  type,
  testId,
  form: _form,
  afterCreate,
  onAfterUpdate,
  onAfterDelete,
  ...props
}) => {
  const [form] = ProForm.useForm<TestFormRecord>(_form);
  const member = useMe();

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] =
    React.useState(false);

  const toggleIsSubmitButtonLoading = () =>
    setIsSubmitButtonLoading((prev) => !prev);

  const defaultProps: Partial<ProFormProps<TestFormRecord>> = {
    initialValues: {
      type: "intermediate",
      show_results_format: "hidden",
      is_shuffles_questions: false,
      is_questions_database: false,
      is_limits_testing_time: false,
      is_limits_testing_attempts: false,
      questions: [],
      success_criteria: 100,
    },
    submitter: {
      searchConfig: {
        resetText: "Сбросить",
        submitText: type === "create" ? "Создать тест" : "Обновить тест",
      },
      resetButtonProps: {
        style: { display: "none" },
      },
    },
  };

  if (type === "update") {
    defaultProps.request = () => {
      return axios
        .get(`/api/tests/${testId}`)
        .then((response) => response.data.data);
    };

    defaultProps.submitter = false;
  }

  defaultProps.onFinish = async (value) => {
    value = form.getFieldsValue(true);

    if (!value.questions) value.questions = [];

    value.questions = value.questions.filter((question) => {
      return !question.is_deleted;
    });

    const config: AxiosRequestConfig = {};
    switch (type) {
      case "create":
        config.method = "POST";
        config.url = "/api/tests";
        break;
      case "update":
        config.method = "PUT";
        config.url = `/api/tests/${testId}`;
        break;
      default:
        throw new Error(`Unsupported ${type} type`);
    }

    config.data = value;

    return axios
      .request<
        | OrionRestCreateResponse<TestFormRecord>
        | OrionRestUpdateResponse<TestFormRecord>
      >(config)
      .then(async (response) => {
        switch (type) {
          case "create":
            message.success("Тест успешно создан");
            await afterCreate?.(response.data.data);
            break;
          case "update":
            message.success("Тест успешно обновлен");
            await onAfterUpdate?.(response.data.data);
            break;
          default:
            throw new Error(`Unsupported ${type} type`);
        }

        form.setFieldsValue(response.data.data);
        return true;
      })
      .catch((reason) => {
        message.error(reason.response.data.message ?? "Что-то пошло не так...");

        if (reason.response.status === 422) {
          setValidationErrorsToFormFields(form, reason.response.data.errors);
        } else {
          console.error(reason);
        }

        return false;
      })
      .finally(() => {
        setIsSubmitButtonLoading(false);
      });
  };

  const overrideProps: Partial<ProFormProps<TestFormRecord>> = {
    form,
  };

  props = deepmerge(defaultProps, props, overrideProps);

  const onDelete = async () => {
    if (type !== "update") {
      throw new Error(`Delete is not supported for ${type}`);
    }

    return axios
      .delete(`/api/tests/${form.getFieldValue("id")!}`)
      .then(() => {
        message.success("Тест удален");
        onAfterDelete?.();
      })
      .catch((reason) => {
        message.error(reason.response.data.message ?? "Что-то пошло не так");
      });
  };

  return (
    <ProForm<TestFormRecord>
      {...props}
      style={{ height: "100%" }}
      disabled={!member.permissions.includes("course:update")}
    >
      <FormQuestionsMenu
        form={form}
        isSubmitButtonLoading={isSubmitButtonLoading}
        toggleIsSubmitButtonLoading={toggleIsSubmitButtonLoading}
      >
        <FormFields
          form={form}
          type={type}
          onDelete={onDelete}
          isSubmitButtonLoading={isSubmitButtonLoading}
          toggleIsSubmitButtonLoading={toggleIsSubmitButtonLoading}
        />
      </FormQuestionsMenu>
    </ProForm>
  );
};

export default TestForm;
export type { TestFormProps, TestFormRecord, TestFormQuestionRecord };
