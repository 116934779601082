import { PoolParticipant } from "@/models";
import React from "react";

type ContextPoolParticipantValue = PoolParticipant;

const ContextPoolParticipant = React.createContext<
  ContextPoolParticipantValue | undefined
>(undefined);

export const usePoolParticipant = () =>
  React.useContext(ContextPoolParticipant) as ContextPoolParticipantValue;

export default ContextPoolParticipant;
export type { ContextPoolParticipantValue };
