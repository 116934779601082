import { Tooltip } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const ArchivedCourseIcon: React.FC = () => {
  return (
    <Tooltip title="Архивный">
      <InboxOutlined />
    </Tooltip>
  );
};
export default ArchivedCourseIcon;
