import React from "react";
import MyEducationList from "@/widgets/my-education/ui/list";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";

const Page: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <MyEducationList
          onItem={(record) => ({
            onClick: () => navigate(`/education/${record.id}`),
          })}
        />
      </div>
    </Flex>
  );
};

export default Page;
