import React from "react";
import { Result } from "antd";

const AccessDeniedResult: React.FC = () => {
  return (
    <Result
      status="403"
      title="403 Пространство не активно"
      subTitle="Извините, данное пространство не активно. Если вы считаете, что это ошибка, обратитесь в службу поддержки."
    />
  );
};

export default AccessDeniedResult;
