import React from "react";

export type HeaderContextType = {
  hidden: boolean;
  setHidden: (value: boolean) => void;
};

const HeaderContext = React.createContext<HeaderContextType | undefined>(
  undefined,
);

export default HeaderContext;
