import React from "react";
import PoolUpdateButton, {
  PoolUpdateButtonProps,
} from "@/entities/pool/ui/button-update";
import { message } from "antd";

type PoolStartButtonProps = Omit<PoolUpdateButtonProps, "newValue">;

const PoolStartButton: React.FC<PoolStartButtonProps> = ({
  afterUpdate,
  ...props
}) => {
  return (
    <PoolUpdateButton
      newValue={{ status: "started" }}
      children={"Запустить"}
      afterUpdate={() => {
        message.success("Обучение запущено");
        afterUpdate?.();
      }}
      {...props}
    />
  );
};

export default PoolStartButton;
export type { PoolStartButtonProps };
