import React from "react";

type AutoRouterContext = {
  basePath: string;
};

const autoRouterContext = React.createContext<AutoRouterContext>(undefined!);

export const useAutoRouter = () => React.useContext(autoRouterContext);
export const AutoRouterContextProvider = autoRouterContext.Provider;
