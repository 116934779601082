import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import axios from "@/axios";
import { Spin } from "antd";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { Group } from "@/models";

const BreadcrumbItem: React.FC = () => {
  const { group_id } = useParams();

  const {
    data: group,
    error,
    isLoading,
  } = useSWR(`/api/groups/${group_id!}`, async (url) => {
    return axios
      .get<OrionRestShowResponse<Group>>(url)
      .then((res) => res.data.data);
  });

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!group) throw new Error("Group not found");

  return <>{group.name}</>;
};

export default BreadcrumbItem;
