import React from "react";
import { Button, message } from "antd";
import {
  ModalForm,
  ModalFormProps,
  ProFormText,
} from "@ant-design/pro-components";
import axios from "@/axios";
import { License } from "@/models";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";

type ImportLicenseFormProps = {
  onAfterImport?: () => void;
  trigger?: JSX.Element;
};

const ImportLicenseForm: React.FC<ImportLicenseFormProps> = ({
  trigger,
  onAfterImport,
}) => {
  const onFinish: ModalFormProps["onFinish"] = async (values) => {
    return axios
      .post<OrionRestShowResponse<License>>(`/api/licenses/import`, values)
      .then(() => {
        message.success("Лицензия успешно импортирована");
        onAfterImport?.();
        return true;
      })
      .catch((err) => {
        const messageText = err.response.data.message ?? err.message;
        message.error(`Ошибка при импортировании лицензии: ${messageText}`);
        return false;
      });
  };

  return (
    <ModalForm
      title="Импорт лицензии"
      submitter={{
        searchConfig: {
          submitText: "Импортировать",
        },
        resetButtonProps: false,
      }}
      modalProps={{
        destroyOnClose: true,
      }}
      trigger={trigger ?? <Button type={"primary"}>Импорт</Button>}
      onFinish={onFinish}
    >
      <ProFormText
        rules={[{ required: true }]}
        name="key"
        label="Ключ лицензии"
      />
    </ModalForm>
  );
};

export default ImportLicenseForm;
