import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import axios from "@/axios";
import { Spin } from "antd";
import { Test } from "@/models";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";

const BreadcrumbItem: React.FC = () => {
  const { test_id } = useParams();
  const { data, error, isLoading } = useSWR(
    `/api/tests/${test_id!}`,
    async (url) => {
      return axios
        .get<OrionRestShowResponse<Test>>(url)
        .then((res) => res.data.data);
    },
  );

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!data) throw new Error("No data");

  return <>{data.name}</>;
};

export default BreadcrumbItem;
