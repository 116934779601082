import React from "react";
import {
  ProForm,
  ProFormCheckbox,
  ProFormGroup,
  ProFormProps,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
  ProFormTextArea,
  ProFormTimePicker,
} from "@ant-design/pro-components";
import { Space } from "@/models";
import {
  Flex,
  message,
  Space as AntdSpace,
  Modal,
  Typography,
  Statistic,
  Row,
  Col,
} from "antd";
import axios from "@/axios";
import { OrionRestUpdateResponse } from "@/shared/types/orion-rest";
import styled from "styled-components";
import { authenticationTypeEnum } from "@/entities/space/lib/model";
import useSpace from "@/entities/space/lib/use";
import PageContainer from "@/shared/ant-design/page-container/ui";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useMe from "@/entities/me/lib/use";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import useMode from "@/entities/mode/lib/use";
import ProFormPhone from "@/shared/ant-design-pro-components/form-phone/ui/component";
import dayjs from "dayjs";

const FormWrapper = styled.div`
  .space-form .ant-space {
    width: 100%;
  }
  .space-form {
    display: flex;
    flex-direction: column-reverse;
  }
  height: 100%;
  overflow: hidden auto;

  .space-form .ant-space .ant-space-item {
    width: 100%;
    height: 100%;
  }
`;

const Page: React.FC = () => {
  const space = useSpace();
  const member = useMe();
  const mode = useMode();
  const [modal, modalHolder] = Modal.useModal();
  const [form] = ProForm.useForm<Space>();
  const isSupportChatAutoCloseable = ProForm.useWatch(
    "is_support_chat_auto_closeable",
    form,
  );

  const request: ProFormProps<Space>["request"] = async () => {
    const space_id = await axios
      .get("/api/.well-known/space")
      .then((res) => res.data.id);

    return axios
      .get(`/api/spaces/${space_id}`)
      .then((res) => res.data.data)
      .catch((reason) => {
        message.error(reason.response.data.message ?? reason.message);
        throw reason;
      });
  };

  const onSave: ProFormProps<Space>["onFinish"] = async (values) => {
    const touchedValues = form.getFieldsValue(
      true,
      (meta) => meta.touched,
    ) as Space;

    if (touchedValues.slug) {
      const isConfirmed = await modal.confirm({
        title: "Изменение адреса пространства",
        content: (
          <AntdSpace direction={"vertical"}>
            <Typography.Text>
              Вы изменяете адрес пространства, по старому адресу пространство
              будет не доступно.
            </Typography.Text>
            <Typography.Text type={"danger"}>
              Вы уверены, что хотите изменить адрес пространства?
            </Typography.Text>
          </AntdSpace>
        ),
        okText: "Да, я уверен",
        okButtonProps: { danger: true },
        cancelText: "Нет",
      });

      if (!isConfirmed) return;
    }
    const updatedValues = await axios
      .put<OrionRestUpdateResponse<Space>>(
        `/api/spaces/${form.getFieldsValue(true).id}`,
        values,
      )
      .then((res) => res.data.data)
      .catch((reason) => {
        message.error(reason.response.data.message ?? reason.message);
        if (reason.response.status === 422) {
          setValidationErrorsToFormFields(form, reason.response.data.errors);
        } else {
          console.error(reason);
        }
        throw reason;
      });

    message.success("Изменения успешно сохранены");

    if (touchedValues.slug) {
      const onOk = () => {
        window.location.replace(updatedValues.url!);
      };

      modal.confirm({
        title: "Перенаправление на новый адрес пространства",
        content: (
          <>
            <Typography.Text>
              Вы изменили адрес пространства, поэтому необходимо перейти на
              него.
            </Typography.Text>
            <Statistic.Countdown
              value={Date.now() + 10 * 1000}
              onFinish={onOk}
            />
            <Typography.Text type={"secondary"}>
              Если этого не произошло в течение 10 секунд, нажмите на кнопку
              "Перейти"
            </Typography.Text>
          </>
        ),
        onOk,
        keyboard: false,
        okText: "Перейти",
        cancelButtonProps: { style: { display: "none" } },
      });
    } else {
      space.refresh();
    }

    // TODO: Fix this "any" type
    form.setFieldsValue(updatedValues as any);
  };

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      {modalHolder}
      <PageContainer style={{ height: "calc(100% - 30px)" }}>
        <FormWrapper>
          <ProForm<Space>
            className={"space-form"}
            submitter={{
              resetButtonProps: false,
              searchConfig: { submitText: "Сохранить" },
              render: (_, dom) => (
                <Flex style={{ width: "100%" }} justify="end" gap={16}>
                  {dom}
                </Flex>
              ),
            }}
            form={form}
            disabled={!member.permissions.includes("space:update")}
            onFinish={onSave}
            request={request}
          >
            <ProFormGroup title={"Настройки пространства"}>
              <ProFormText
                name={"slug"}
                label={"Адрес пространства"}
                rules={[
                  { required: true },
                  { min: 3, max: 255 },
                  {
                    pattern: /^[a-z0-9-]*$/i,
                    message:
                      "Поддерживаются только латинские буквы в нижнем регистре, цифры и тире",
                  },
                ]}
                fieldProps={{
                  suffix: `.${window.location.host
                    .split(".")
                    .slice(1)
                    .join(".")}`,
                }}
                tooltip={
                  "Адрес пространства - это доменное имя, по которому вы сможете войти в пространство"
                }
                hidden={mode.value === "self-hosted"}
                width={"xl"}
              />
              <ProFormSelect
                name={"authentication_types"}
                label={"Тип аутентификации"}
                valueEnum={authenticationTypeEnum}
                mode={"multiple"}
                rules={[{ required: true, type: "array", min: 1 }]}
                width={"xl"}
              />
              <ProFormSwitch
                name={"is_allow_employee_edit_self_member"}
                label={"Пользователи могут редактировать свои данные"}
              />
              <Row gutter={16}>
                <Col span={12}>
                  <ProFormGroup
                    tooltip={
                      "Ниже введённые контактные данные будут отображаться всем пользователям вашего пространства в шапке и на экране входа"
                    }
                    title={"Контакты поддержки"}
                  >
                    <ProFormPhone name="support_phone_number" label="Телефон" />
                    <ProFormText
                      name={"support_email"}
                      label={"Почта"}
                      rules={[{ max: 255 }, { type: "email" }]}
                    />
                  </ProFormGroup>
                </Col>
                <Col span={12}>
                  <ProFormGroup title={"Настройки чата поддержки"}>
                    <ProFormTextArea
                      label={"Приветственное сообщение"}
                      name={"support_chat_hello_message"}
                    />
                  </ProFormGroup>
                  <ProFormCheckbox name={"is_support_chat_auto_closeable"}>
                    Автоматические закрытие чатов
                  </ProFormCheckbox>
                  <ProFormTimePicker
                    label={"Время автоматического закрытия чата"}
                    name={"support_chat_auto_close_delay"}
                    fieldProps={{ showNow: false, needConfirm: false }}
                    hidden={!isSupportChatAutoCloseable}
                    rules={[{ required: isSupportChatAutoCloseable }]}
                    allowClear={false}
                    convertValue={(value: number) => {
                      if (!value) return dayjs().startOf("day");

                      return dayjs()
                        .startOf("day")
                        .add(value, "second")
                        .format("HH:mm:ss");
                    }}
                    normalize={(value) => {
                      if (value)
                        return value.$H * 3600 + value.$m * 60 + value.$s;
                    }}
                  />
                </Col>
              </Row>
            </ProFormGroup>
          </ProForm>
        </FormWrapper>
      </PageContainer>
    </Flex>
  );
};

export default Page;
