import { AxiosRequestConfig } from "axios";

const axiosConfigAdapter = (
  params: any = {},
  sort: any = {},
  filter: any = {},
): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {
    data: {
      filters: [],
      sort: [],
      includes: [],
      aggregates: [],
      scopes: [],
    },
    params: {},
  };

  if (params.current) {
    config.params.page = params.current;
  }
  if (params.pageSize) {
    config.params.limit = params.pageSize;
  }

  if (sort !== null && sort !== undefined) {
    Object.entries(sort).forEach(([key, value]) => {
      // replace "," separator with "." to match orion's format
      key = key.replace(/,/g, ".");
      config.data.sort.push({
        field: key,
        direction: value === "ascend" ? "asc" : "desc",
      });
    });
  }

  if (config.data.sort.length === 0) {
    config.data.sort.push({ field: "id", direction: "desc" });
  }

  const flattenObject = (obj: any, parentKey?: string) => {
    let result: any = {};

    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const _key = parentKey ? parentKey + "." + key : key;
      if (typeof value === "object" && !Array.isArray(value)) {
        result = { ...result, ...flattenObject(value, _key) };
      } else {
        result[_key] = value;
      }
    });

    return result;
  };

  const filterForEacher = ([key, value]: [string, any]) => {
    key = key.replace(/,/g, ".");

    if (value === null || value === undefined) {
      return;
    } else if (key === "range") {
      const rangeFilterAdded = (value: (string | number)[], key: string) => {
        if (value[0] !== null && value[0] !== undefined) {
          config.data.filters.push({
            field: key,
            operator: ">=",
            value: value[0],
          });
        }
        if (value[1] !== null && value[1] !== undefined) {
          config.data.filters.push({
            field: key,
            operator: "<=",
            value: value[1],
          });
        }
      };
      Object.entries(value).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          rangeFilterAdded(value, key);
        } else if (typeof value === "object") {
          const flattenedValue = flattenObject(value, key);
          Object.entries(flattenedValue).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              rangeFilterAdded(value, key);
            }
          });
        }
      });
    } else if (typeof value === "string") {
      config.data.filters.push({
        field: key,
        operator: "ilike",
        value: `%${value}%`,
      });
    } else if (typeof value === "number") {
      config.data.filters.push({
        field: key,
        operator: "=",
        value: value,
      });
    } else if (Array.isArray(value)) {
      config.data.filters.push({
        type: "and",
        nested: value.map((valueItem) => {
          return { type: "or", field: key, operator: "=", value: valueItem };
        }),
      });
    } else if (typeof value === "object") {
      const flattenedValue = flattenObject(value, key);
      Object.entries(flattenedValue).forEach(filterForEacher);
    } else {
      throw new Error("Unsupported filter value type");
    }
  };

  Object.entries(filter).forEach(filterForEacher);

  return config;
};

export default axiosConfigAdapter;
