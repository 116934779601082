import React from "react";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { message, Modal, Space, Typography } from "antd";
import { Automation } from "@/models";
import { ProForm, ProFormRadio } from "@ant-design/pro-components";
import { ProFormStyled } from "@/entities/automation/ui/pro-form-styled";

type AutomationDeleteButtonProps = {
  recordKeys: number[];
  onAfterDelete?: () => void;
};

const AutomationDeleteButton: React.FC<AutomationDeleteButtonProps> = ({
  recordKeys,
  onAfterDelete,
}) => {
  const [modal, modalHolder] = Modal.useModal();
  const [form] = ProForm.useForm();

  const onDelete = async () => {
    modal.confirm({
      title: "Удалить условие автоназначения",
      content: (
        <Space direction="vertical">
          <Typography.Text type="secondary">
            Выбранная запись/записи будет удалена из системы
          </Typography.Text>
          <ProFormStyled form={form} submitter={false}>
            <ProFormRadio.Group
              name="on_deleted_action"
              initialValue={"save_existing_education"}
              options={[
                {
                  label: (
                    <Space direction="vertical">
                      <Typography.Text>
                        Удалить условие, но оставить уже назначенное обучение
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        Удалить условие, и дать возможность закончить уже
                        назначенное обучение для участников потоков обучения с
                        текущим статусами "Не начат" и "В процессе"
                      </Typography.Text>
                    </Space>
                  ),
                  value: "save_existing_education",
                },
                {
                  label: (
                    <Space direction="vertical">
                      <Typography.Text>
                        Удалить условие и незавершенные обучения
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        Удалить условие и завершить обучения для участников
                        потоков обучения со статусами "Не начат" и "В процессе"
                      </Typography.Text>
                    </Space>
                  ),
                  value: "delete_unfinished_education",
                },
              ]}
            />
          </ProFormStyled>
        </Space>
      ),
      icon: null,
      width: "60%",
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      cancelText: "Отменить",
      closable: true,
      onOk: async () => {
        const resources = recordKeys.reduce(
          (acc: { [key: string]: Partial<Automation> }, id) => {
            acc[id] = {
              on_deleted_action: form.getFieldValue("on_deleted_action"),
            };
            return acc;
          },
          {},
        );
        await axios
          .request({
            method: "PATCH",
            url: `/api/automations/batch`,
            data: {
              resources,
            },
          })
          .catch((err) => {
            message.error(
              err.response.data.message ??
                "Не удалось удалить запись/записи условия автоназначения",
            );
          });
        return await axios
          .request({
            method: "DELETE",
            url: `/api/automations/batch`,
            data: {
              resources: recordKeys,
            },
          })
          .then(() => {
            onAfterDelete?.();
            message.success("Запись/записи условия автоназначения удалены");
          })
          .catch(() => {
            message.error(
              "Не удалось удалить запись/записи условия автоназначения",
            );
          });
      },
    });
  };

  const disabled = recordKeys.length === 0;

  return (
    <>
      {modalHolder}
      <Button type={"primary"} danger onClick={onDelete} disabled={disabled}>
        Удалить
      </Button>
    </>
  );
};

export default AutomationDeleteButton;
export type { AutomationDeleteButtonProps };
