import axios from "@/axios";
import { Modal, message } from "antd";
import { Space } from "@/entities/space/lib/model";
import SecretsDescriptions, {
  ServiceAccountInfo,
  Secrets,
} from "@/entities/service-account/ui/secrets-descriptions";

const useSecretsModal = () => {
  const [modal, modalHolder] = Modal.useModal();

  const showSecretsModal = async (secretsData: Secrets, recordKey?: number) => {
    try {
      const { url } = await axios
        .get<Required<Space>>(`/api/.well-known/space`)
        .then((res) => res.data);

      const serviceAccountSecrets: ServiceAccountInfo = {
        ...secretsData,
        url,
      };

      if (recordKey) {
        const { type } = await axios
          .get(`/api/service-accounts/${recordKey}`)
          .then((res) => res.data.data);
        serviceAccountSecrets.type = type;
      }

      modal.info({
        title: `Информация о сервисном аккаунте`,
        okText: "Закрыть",
        width: "60%",
        icon: null,
        content: <SecretsDescriptions dataSource={serviceAccountSecrets} />,
      });
    } catch {
      message.error(
        "Произошла ошибка при получении информации о сервисном аккаунте",
      );
    }
  };

  return { showSecretsModal, modalHolder };
};

export default useSecretsModal;
