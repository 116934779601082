import React, { useEffect } from "react";
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  Player,
  ReplayControl,
  TimeDivider,
  VolumeMenuButton,
} from "video-react";
import "video-react/dist/video-react.css";
import { Grid } from "antd";
import * as Sentry from "@sentry/react";

type PreviewVideoProps = {
  src: string;
};
const { useBreakpoint } = Grid;

const PreviewVideo: React.FC<PreviewVideoProps> = ({ src }) => {
  const screens = useBreakpoint();

  useEffect(() => {
    document.querySelector("video")?.addEventListener("error", (event) => {
      Sentry.captureException(new Error("Caught video player error event"), {
        extra: { event, src },
      });
    });
  }, []);

  return (
    <Player src={src}>
      <BigPlayButton position={"center"} />
      <ControlBar>
        <CurrentTimeDisplay order={4.1} />
        {!screens.xs && <ReplayControl seconds={10} />}
        {!screens.xs && <ForwardControl seconds={10} />}
        <TimeDivider order={4.2} />
        <VolumeMenuButton vertical />
      </ControlBar>
    </Player>
  );
};

export default PreviewVideo;
export type { PreviewVideoProps };
