import React from "react";
import {
  ProDescriptions,
  ProDescriptionsProps,
} from "@ant-design/pro-components";
import { Section as BaseSection, TreeNode } from "@/models";
import { Space, Spin, Typography } from "antd";
import useSWR from "swr";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import axios from "@/axios";

type Section = BaseSection & {
  tree_node: TreeNode;
};

type SectionDescriptionsProps = ProDescriptionsProps<Section>;

const SectionDescriptions: React.FC<SectionDescriptionsProps> = ({
  dataSource,
}) => {
  const { data, isLoading, error } = useSWR(
    dataSource ? `/api/sections/${dataSource.id}` : null,
    (url) =>
      axios
        .get<OrionRestShowResponse<Section>>(url, {
          params: {
            includes: [
              { relation: "tree_node" },
              { relation: "tree_node.tree" },
              { relation: "tree_node.tree.is_an" },
            ],
          },
        })
        .then((res) => res.data.data.tree_node.tree),
  );

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!data) throw new Error("No data");

  return (
    <ProDescriptions<Section> dataSource={dataSource}>
      <ProDescriptions.Item
        dataIndex={"name"}
        span={24}
        render={(content) => (
          <Typography.Title level={5} style={{ margin: 0 }}>
            {content}
          </Typography.Title>
        )}
      />
      <ProDescriptions.Item
        dataIndex={"description"}
        span={24}
        render={(content) => (
          <Space direction="vertical">
            <Typography.Text>{content}</Typography.Text>
            <Typography.Text>
              В данном разделе вы изучите следующие уроки:
            </Typography.Text>
            <ul style={{ margin: 0 }}>
              {data?.map((child) => (
                <li key={child.id}>{child.is_an?.name}</li>
              ))}
            </ul>
          </Space>
        )}
      />
    </ProDescriptions>
  );
};
export default SectionDescriptions;
export type { SectionDescriptionsProps };
