import React from "react";
import { Button, Flex, message, Tag, Typography } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import ProTable from "@/shared/ant-design-pro-components/table/ui";
import { Role } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { Link, useNavigate } from "react-router-dom";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import useMe from "@/entities/me/lib/use";
import RolesDeleteButton from "@/entities/roles/delete-button";
import { invertColor } from "@/shared/helpers/invert-hex-color";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { ColumnType } from "antd/lib/table";

const Page: React.FC = () => {
  const navigate = useNavigate();
  const member = useMe();
  const { ref, parentHeight } = useParentHeight();
  const [total, setTotal] = React.useState<number>();
  const [someSelected, setSomeSelected] = React.useState<boolean>();
  const [actionColumnWidth, setActionColumnWidth] =
    React.useState<ColumnType<Role>["width"]>(150);
  const maxRolesCount = 10;

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div ref={ref} style={{ width: "100%", height: "calc(100% - 30px" }}>
        <ProTable<Role, Partial<Role>>
          hasQueryParams
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          columnsState={{
            persistenceKey: "roles-table",
            onChange: (map) => {
              if (!map.name.show && !map.description.show) {
                setActionColumnWidth(undefined);
              } else {
                setActionColumnWidth(150);
              }
            },
          }}
          rowKey={"id"}
          search={false}
          style={{
            width: "calc(100vw - 200px - 32px)",
            maxWidth: "calc(1920px - 200px - 32px)",
          }}
          scroll={{ y: parentHeight - (someSelected ? 185 : 125) }}
          pagination={false}
          toolBarRender={(action, { selectedRowKeys, selectedRows }) => {
            if (!selectedRowKeys)
              throw new Error("selectedRowKeys is undefined");
            if (!selectedRows) throw new Error("selectedRows is undefined");
            if (!action) throw new Error("action is undefined");

            const selectedKey =
              selectedRowKeys.length === 1
                ? Number(selectedRowKeys?.[0])
                : undefined;

            return [
              member.permissions.includes("role:delete") && (
                <RolesDeleteButton
                  recordKey={selectedKey}
                  onBeforeDelete={() => {
                    const HasGuardedRole = selectedRows.find(
                      (role) => role.is_guarded,
                    );
                    if (HasGuardedRole) {
                      message.warning("Нельзя удалить системную роль");
                      action.clearSelected?.();
                      return true;
                    }
                    return false;
                  }}
                  onAfterDelete={() => {
                    action.reload();
                    action.clearSelected?.();
                  }}
                />
              ),
              member.permissions.includes("role:update") && (
                <Button
                  key={"update"}
                  type="primary"
                  onClick={() => {
                    const role = selectedRows.find(
                      (role) => role.id === selectedKey,
                    );
                    if (role?.is_guarded) {
                      message.warning("Нельзя редактировать системную роль");
                      action.clearSelected?.();
                    } else {
                      navigate(`/manage/roles/${selectedKey}`);
                    }
                  }}
                  disabled={!selectedKey}
                >
                  Редактировать
                </Button>
              ),
              member.permissions.includes("role:create") && (
                <Button
                  type="primary"
                  key="create"
                  onClick={() => {
                    if (total && total >= maxRolesCount) {
                      return message.warning(
                        `Нельзя создавать больше ${maxRolesCount} ролей`,
                      );
                    }
                    navigate("/manage/roles/create");
                  }}
                >
                  Создать
                </Button>
              ),
            ];
          }}
          request={async (_, sort, filter) => {
            const config = {
              method: "POST",
              url: "/api/roles/search",
              ...axiosConfigAdapter({ limit: 10 }, sort, filter),
            };

            return axios
              .request<OrionRestIndexResponse<Role>>(config)
              .then((response) => {
                setTotal(response.data.meta.total);
                return {
                  data: response.data.data,
                  success: true,
                  total: response.data.meta.total,
                };
              });
          }}
          columns={[
            {
              title: "Название",
              dataIndex: "name",
              render: (_, { color, name }) => (
                <Tag color={color} bordered>
                  <Typography.Text style={{ color: invertColor(color) }}>
                    {name}
                  </Typography.Text>
                </Tag>
              ),
            },
            {
              title: "Описание",
              dataIndex: "description",
            },
            {
              title: "Действия",
              width: actionColumnWidth,
              hideInSetting: true,
              disable: true,
              hideInTable: !member.permissions.includes("member:view"),
              render: (_, { id }) => (
                <Link
                  to={{
                    pathname: "/manage/members",
                    search: `?query={"filter"%3A{"role_id"%3A[${id}]}%2C"sort"%3A{"created_at"%3A"descend"}}`,
                  }}
                >
                  Пользователи
                </Link>
              ),
            },
          ]}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSomeSelected(selectedRowKeys.length > 0);
            },
          }}
        />
      </div>
    </Flex>
  );
};

export default Page;
