import React, { useEffect } from "react";
import ruRU from "antd/lib/locale/ru_RU";
import { ConfigProvider, theme, ThemeConfig } from "antd";
import { deepmerge } from "deepmerge-ts";
import useTheme from "@/shared/theme/lib/use.tsx";

type AntdConfigProviderProps = {
  children: React.ReactNode;
};

const AntdConfigProvider: React.FC<AntdConfigProviderProps> = ({
  children,
}) => {
  const { value: themeContext } = useTheme();
  const { token } = theme.useToken();

  const whiteColor = "#ffffff";
  const black8Color = "#161c23";
  const black9Color = "#10151a";
  const black10Color = "#0a0e13";
  const blackColor = black10Color;
  // const blackColor = "#14181c";
  const blue1Color = "#f3f7ff";
  const blue5Color = "#75b2ff";
  const blue10Color = "#1d72c0";
  const blueColor = blue10Color;
  const yellowColor = "#ffcb38";

  const lightThemeProp: ThemeConfig = {
    token: {
      colorBgBase: whiteColor,
      colorBgLayout: blue1Color,
      colorBgContainer: whiteColor,
    },
    components: {
      Layout: {
        // bodyBg: lightBlueColor,
        headerBg: whiteColor,
      },
    },
  };

  const darkThemeProp: ThemeConfig = {
    token: {
      colorInfo: blue5Color,
      colorLink: blue5Color,
      colorBgBase: blackColor,
      colorBgLayout: blackColor,
      colorBgContainer: black8Color,
    },
    components: {
      Layout: {
        bodyBg: black10Color,
        headerBg: black8Color,
      },
      Menu: {
        colorPrimary: whiteColor,
        controlItemBgActive: blueColor,
      },
    },
  };

  let themeProp: ThemeConfig = {
    algorithm:
      themeContext === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
    token: {
      blue: blueColor,
      colorPrimary: blueColor,
      colorInfo: blueColor,
      colorLink: blueColor,
      colorWarning: yellowColor,
    },
    components: {
      Tabs: {
        horizontalItemPadding: `12px ${token.paddingMD}px`,
      },
    },
  };

  switch (themeContext) {
    case "light":
      themeProp = deepmerge(themeProp, lightThemeProp);
      break;
    case "dark":
      themeProp = deepmerge(themeProp, darkThemeProp);
      break;
  }

  useEffect(() => {
    // @ts-ignore
    document.body.style.backgroundColor = themeProp.token.colorBgLayout;
  }, [themeContext]);

  return (
    <ConfigProvider locale={ruRU} theme={themeProp}>
      {children}
    </ConfigProvider>
  );
};

export default AntdConfigProvider;
export type { AntdConfigProviderProps };
