import React, { useEffect, useState } from "react";
import { Exam, Test } from "@/models";
import { ActionType } from "@ant-design/pro-components";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { dateSTime, toTime } from "@/shared/dayjs/lib/formats";
import examStatusValueEnum from "../lib/exam-status-value-enum";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import axios from "@/axios";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";

type ExamsTableProps = ProTableProps<Exam, any> & {
  poolParticipantId?: string;
  testId?: Test["id"];
  examId?: Exam["id"];
};

const ExamTable: React.FC<ExamsTableProps> = ({
  poolParticipantId,
  testId,
  examId,
  ...props
}) => {
  const actionRef = React.useRef<ActionType>(null);
  const [checkingStatus, setCheckingStatus] = useState(false);

  useEffect(() => {
    let interval: undefined | NodeJS.Timeout;
    if (actionRef.current && checkingStatus) {
      interval = setInterval(() => {
        actionRef.current?.reload();
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [checkingStatus]);

  useEffect(() => {
    if (actionRef.current && examId) {
      actionRef.current?.reload();
    }
  }, [examId]);

  const defaultProps: Partial<typeof props> = {
    rowKey: "id",
    pagination: {
      pageSize: 5,
    },
    actionRef,
    search: false,
    manualRequest: false,
    request: async (params, sort, filter) => {
      const config = {
        method: "POST",
        url: "/api/exams/search",
        ...axiosConfigAdapter(params, sort, filter),
      };

      if (poolParticipantId && testId) {
        config.data.filters.push(
          { field: "pool_participant_id", value: poolParticipantId },
          { field: "test_participant.test_id", value: testId },
        );
      }

      if (examId) {
        config.data.filters.push({ field: "id", value: examId });
      }

      config.data.sort.push({
        field: "created_at",
        order: "desc",
      });

      const data = await axios
        .request<OrionRestIndexResponse<Exam>>(config)
        .then((res) => res.data);

      const lastExams = data.data[0];

      setCheckingStatus(
        lastExams.status === "to_check" || lastExams.status === "checking",
      );

      return {
        data: data.data,
        success: true,
        total: data.meta.total,
      };
    },
    columns: [
      {
        title: "№ попытки",
        dataIndex: "attempt_number",
      },
      {
        title: "Время",
        dataIndex: "spent_time",
        render: (_, { spent_time }) => {
          return spent_time ? toTime(spent_time) : "-";
        },
      },
      { title: "Балл", dataIndex: ["result", "current_score"] },
      {
        title: "Дата выполнения",
        dataIndex: "ended_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
      },
      {
        title: "Результат",
        dataIndex: "status",
        valueType: "select",
        valueEnum: examStatusValueEnum,
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps, props);

  return <ProTable {...props} />;
};

export default ExamTable;
export type { ExamsTableProps };
