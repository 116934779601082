import React from "react";
import ProFormInputMask, {
  ProFormInputMaskProps,
} from "@/shared/ant-design-pro-components/form-input-mask/ui/component.tsx";

type ProFormSnilsProps = Partial<ProFormInputMaskProps>;

const ProFormSnils: React.FC<ProFormSnilsProps> = (_props) => {
  const defaultProps: ProFormInputMaskProps = {
    mask: "000-000-000 00",
    placeholder: "Введите значение",
  };

  const props = { ...defaultProps, ..._props };

  return <ProFormInputMask {...props} />;
};

export default ProFormSnils;
export type { ProFormSnilsProps };
