import React from "react";
import { Flex, Space, theme } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import { useNavigate, useParams } from "react-router-dom";
import RolesForm from "@/entities/roles/form";

const Page: React.FC = () => {
  const { roles_id } = useParams();
  const { token } = theme.useToken();
  const navigate = useNavigate();

  return (
    <Flex vertical style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Space
        direction={"vertical"}
        style={{
          height: "100%",
          width: "calc(100vw - 200px - 32px)",
          maxWidth: "calc(1920px - 200px - 32px)",
          backgroundColor: token.colorBgContainer,
          padding: token.paddingMD,
          overflow: "auto",
        }}
      >
        <RolesForm
          rest={{
            type: "update",
            recordKey: Number(roles_id),
            onAfterCancel: () => navigate("/manage/roles"),
            onAfterDelete: () => navigate("/manage/roles"),
          }}
        />
      </Space>
    </Flex>
  );
};

export default Page;
