import React from "react";
import "dayjs/locale/ru";
import Router from "@/router.tsx";
import AntdConfigProvider from "@/antd-config-provider.tsx";
import ThemeContextProvider from "@/shared/theme/lib/context-provider.tsx";
import ClientContextProvider from "@/entities/client/lib/context-provider.tsx";

const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <AntdConfigProvider>
        <ClientContextProvider>
          <Router />
        </ClientContextProvider>
      </AntdConfigProvider>
    </ThemeContextProvider>
  );
};

export default App;
