import { Import } from "@/entities/import/lib/model";
import { Alert, Flex, Space, theme, Typography } from "antd";
import React from "react";

type ImportResultProps = {
  result: Import["result"];
};

const ButtonPoolResult: React.FC<ImportResultProps> = ({ result }) => {
  const { token } = theme.useToken();

  if (!result) result = [];
  if (!Array.isArray(result)) result = [result];

  return (
    <Flex
      vertical
      gap={token.size}
      style={{ paddingBlock: token.padding, width: "100%" }}
    >
      {(result ?? []).map((result) => {
        switch (result.result) {
          case "not_found":
            return (
              <Alert
                key={result.snils}
                showIcon
                message={`Для ${result.snils} не добавлен номер в реестре`}
                type="error"
                description={
                  <Space direction={"vertical"}>
                    {result.errors?.map((error, index) => (
                      <Typography.Text key={index}>{error}</Typography.Text>
                    ))}
                  </Space>
                }
              />
            );
          case "appended":
            return (
              <Alert
                key={result.snils}
                showIcon
                message={`Для ${result.snils} добавлен номер в реестре`}
                type="success"
              />
            );

          case "changed":
            return (
              <Alert
                key={result.snils}
                showIcon
                message={`Для ${result.snils} изменен номер в реестре`}
                type="info"
              />
            );
        }
      })}
    </Flex>
  );
};

export default ButtonPoolResult;
