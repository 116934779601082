import React from "react";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import MemberSelect from "@/entities/member/ui/select";
import PoolSelect from "@/entities/pool/ui/select";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import DateTimeRangePickerWithTimeControls from "@/shared/ant-design-pro-components/date-time-range-picker-with-time-controls/ui/date-time-range-picker-with-time-controls";

const EducationEndingTomorrowMembersFormFields: React.FC = ({}) => {
  return (
    <>
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_id"}
      />
      <PoolSelect
        mode="multiple"
        label="Поток"
        name={"pool_id"}
        colProps={{ span: 8 }}
      />
      <DateTimeRangePickerWithTimeControls
        label="Планируемая дата завершения"
        name={["range", "ends_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default EducationEndingTomorrowMembersFormFields;
