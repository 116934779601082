import React from "react";
import { Button, Result, theme } from "antd";
import AuthButton from "@/shared/components/auth-button";
import { useNavigate } from "react-router-dom";

const LoginRequiredPage: React.FC = () => {
  const navigate = useNavigate();
  const { token } = theme.useToken();

  return (
    <Result
      title="Требуется авторизация"
      extra={[
        <AuthButton key={"logout"} />,
        <Button key="home" onClick={() => navigate("/")}>
          Домой
        </Button>,
      ]}
      style={{ paddingBottom: token.padding }}
    />
  );
};

export default LoginRequiredPage;
