import axios from "@/axios";
import useSWR from "swr";
import { Area } from "@ant-design/charts";
import IndicatorIntervalSelect from "@/pages/space/manage/dashboards/indicator-interval-select.tsx";
import { ProForm, ProFormSelect } from "@ant-design/pro-components";
import { Dashboard } from "@/models.ts";
import { Card, Col, Space, Spin, theme } from "antd";
import { IndicatorContentMetadata } from "@/pages/space/manage/dashboards/indicator-content-metadata.ts";

export const metadata: IndicatorContentMetadata = {
  name: "employee_attendance",
  title: "Посещаемость сотрудников",
  position: 2,
};

const IndicatorContentEmployeeAttendance: React.FC = () => {
  const form = ProForm.useFormInstance<Dashboard>();
  const { token } = theme.useToken();
  const is_visible = ProForm.useWatch(
    ["settings", metadata.name, "is_visible"],
    form,
  );
  const interval = ProForm.useWatch(
    ["settings", metadata.name, "interval"],
    form,
  );
  const group_by = ProForm.useWatch(
    ["settings", metadata.name, "group_by"],
    form,
  );

  const { data, isLoading, error } = useSWR(
    interval && group_by
      ? [`/api/dashboards/statistics/traffic-members`, interval, group_by]
      : null,
    async ([url, interval, group_by]) => {
      return axios
        .get(url, { params: { interval, group_by } })
        .then((res) => res.data.data);
    },
  );

  if (error) throw error;

  return (
    <>
      {is_visible && (
        <Col span={24}>
          <Card
            title={metadata.title}
            extra={
              <Space>
                <IndicatorIntervalSelect
                  name={["settings", metadata.name, "interval"]}
                />
                <ProFormSelect
                  name={["settings", metadata.name, "group_by"]}
                  label="Группировка"
                  options={[
                    { label: "По часам", value: "hour" },
                    { label: "По дням", value: "day" },
                  ]}
                  formItemProps={{ style: { marginBottom: 0 } }}
                  allowClear={false}
                />
              </Space>
            }
          >
            <Spin spinning={isLoading}>
              <Area
                data={data ?? []}
                xField={"point"}
                yField={"count"}
                containerStyle={{
                  maxWidth: "calc(100vw - 200px - 32px - 48px - 16px)",
                }}
                axis={{
                  x: { labelFill: token.colorText },
                  y: { labelFill: token.colorText },
                }}
              />
            </Spin>
          </Card>
        </Col>
      )}
    </>
  );
};

export default IndicatorContentEmployeeAttendance;
