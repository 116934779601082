import React from "react";
import { Flex, Spin, theme } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import useSWR from "swr";
import { useNavigate, useParams } from "react-router-dom";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { SupportChat as SupportChatModel } from "@/models";
import SupportChat from "@/entities/support/ui/chat";

const Page: React.FC = () => {
  const { support_chat_iid } = useParams();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const {
    data: supportChat,
    error,
    isLoading,
  } = useSWR(["/api/support-chats/search", support_chat_iid], async ([url]) => {
    return axios
      .post<OrionRestIndexResponse<SupportChatModel>>(url, {
        filters: [
          {
            field: "iid",
            operator: "=",
            value: support_chat_iid,
          },
        ],
      })
      .then((res) => res.data.data[0]);
  });

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!supportChat) throw new Error("Support chat not found");

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Flex
        style={{
          height: "calc(100% - 30px)",
          width: "calc(100vw - 232px)",
          backgroundColor: token.colorBgContainer,
          borderRadius: token.borderRadius,
          boxShadow: token.boxShadow,
        }}
      >
        <SupportChat
          onAfterClose={() => {
            navigate("/manage/support/chats");
          }}
          navigateToUser={(memberId) => {
            navigate(`/manage/members/${memberId}`);
          }}
          supportChatId={supportChat.id}
          supportChatOwnerId={supportChat.owner_id}
          status={supportChat.status}
        />
      </Flex>
    </Flex>
  );
};

export default Page;
