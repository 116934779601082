import React from "react";
import { useRouteError } from "react-router-dom";
import ErrorPage from "./error";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

const ErrorRouter: React.FC = () => {
  const error = useRouteError();

  if (error instanceof AxiosError) {
    const errorMessage = error.response?.data.message || "";
    error.name += ` (${errorMessage})`;
  }
  
  Sentry.captureException(error);

  return (
    <ErrorPage
      errorMessage={error instanceof Error ? error.message : undefined}
    />
  );
};

export default ErrorRouter;
