import React from "react";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Alert, Modal, ModalProps, Space, Typography, message } from "antd";
import axios from "@/axios";
import { License } from "@/models";

type ExportLicenseButtonProps = ModalProps & {
  recordKey?: License["id"];
};

const ExportLicenseButton: React.FC<ExportLicenseButtonProps> = ({
  recordKey,
}) => {
  const [modal, modalHolder] = Modal.useModal();

  const licenseExport = async () => {
    await axios
      .post(`/api/licenses/${recordKey}/export`)
      .then(({ data }) => {
        modal.info({
          okText: "Закрыть",
          width: "75%",
          icon: null,
          content: (
            <Space direction="vertical">
              <Typography.Text strong>Ключ лицензии:</Typography.Text>
              <Typography.Text type="secondary" copyable>
                {data.data}
              </Typography.Text>
            </Space>
          ),
        });
      })
      .catch((err) => {
        message.error(err.response.data.message ?? "Ошибка экспорта");
      });
  };

  return (
    <>
      {modalHolder}
      <Button
        type={"primary"}
        onClick={() => licenseExport()}
        disabled={!recordKey}
      >
        Экспорт
      </Button>
    </>
  );
};

export default ExportLicenseButton;
export type { ExportLicenseButtonProps };
