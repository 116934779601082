import React from "react";
import useSWR from "swr";
import axios from "@/axios";
import VersionContext, {
  VersionContextValue,
} from "@/entities/version/lib/context";
import { Version } from "@/entities/version/lib/type";
import ErrorPage from "@/shared/pages/error.tsx";

type Props = {
  children: React.ReactNode;
};

const VersionContextProvider: React.FC<Props> = ({ children }) => {
  const { data, isLoading, error } = useSWR(
    "/api/.well-known/version",
    (url) => axios.get<{ value: Version }>(url).then((res) => res.data.value),
    {
      refreshInterval: 60 * 1000,
      revalidateOnFocus: false,
    },
  );

  if (error) return <ErrorPage errorMessage={error.message} />;

  const value: VersionContextValue = {
    value: data!,
    isLoading,
  };

  return (
    <VersionContext.Provider value={value}>{children}</VersionContext.Provider>
  );
};

export default VersionContextProvider;
