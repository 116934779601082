import {
  ProForm,
  ProFormCheckbox,
  ProFormCheckboxGroupProps,
} from "@ant-design/pro-components";
import { Checkbox, message, Space, Typography } from "antd";
import { useEffect, useMemo } from "react";
import type { CheckboxProps } from "antd";
import { CheckboxOptionType } from "antd/lib";

type CheckboxFormFieldPermissionProps = {
  name: string;
  label: string;
  options: ProFormCheckboxGroupProps["options"];
  onChange?: () => void;
};

const CheckboxFormFieldPermission: React.FC<
  CheckboxFormFieldPermissionProps
> = ({ name, label, options: _options, onChange: _onChange }) => {
  const form = ProForm.useFormInstance();
  const names = ProForm.useWatch(name, form) as string[];

  const indeterminate =
    names && names.length > 0 && names!.length < _options!.length;
  const checkAll = _options!.length === names?.length;

  const onChange = () => {
    _onChange?.();
  };

  useEffect(() => {
    let newCheckedList = names;

    if (
      names?.length &&
      !names.includes(`${name}:view`) &&
      options?.some(
        (item) => (item as CheckboxOptionType).value === `${name}:view`,
      )
    ) {
      newCheckedList.push(`${name}:view`);
    }

    if (name === "course" && names) {
      if (names.includes("course:create") && !names.includes("course:update")) {
        newCheckedList.push("course:update");
      }
      if (names.includes("import:questions")) {
        if (!names.includes("course:update")) {
          newCheckedList = newCheckedList.filter(
            (item) => item !== "import:questions",
          );
          message.warning(
            "Для импорта вопросов необходимо Редактирование курсов",
          );
        }
      }
    }

    if (name === "member" && names) {
      if (names.includes("member:manage_accesses")) {
        if (!names.includes("member:create")) {
          newCheckedList.push("member:create");
        }
        if (!names.includes("member:update")) {
          newCheckedList.push("member:update");
        }
      } else if (
        names.includes("member:create") &&
        !names.includes("member:update")
      ) {
        newCheckedList.push("member:update");
      }
    }

    if (name === "org_structure" && names) {
      if (
        names.includes("org_structure:create") &&
        !names.includes("org_structure:update")
      ) {
        newCheckedList.push("org_structure:update");
      }
    }

    form!.setFieldsValue({
      [name]: newCheckedList?.length ? newCheckedList : undefined,
    });
  }, [names]);

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    const list: CheckboxProps["value"][] =
      _options?.map((item) => {
        if (typeof item === "object") {
          return item.value;
        }
      }) ?? [];
    form?.setFieldValue(name, e.target.checked ? list : []);
    _onChange?.();
  };

  const options = useMemo(
    () =>
      _options?.map((item) => {
        if (typeof item === "object" && names?.length) {
          if (
            item.value === `${name}:view` &&
            names?.some((item: string) => item !== `${name}:view`)
          ) {
            return {
              ...item,
              disabled: true,
            };
          } else if (
            name === "course" &&
            item.value === `course:update` &&
            names?.some((item: string) => item === `course:create`)
          ) {
            return {
              ...item,
              disabled: true,
            };
          } else if (name === "member") {
            if (
              item.value === `member:update` &&
              names?.some((item: string) => item === `member:create`)
            ) {
              return {
                ...item,
                disabled: true,
              };
            }
            if (
              item.value === `member:create` &&
              names?.some((item: string) => item === `member:manage_accesses`)
            ) {
              return {
                ...item,
                disabled: true,
              };
            }
          } else if (
            name === "org_structure" &&
            item.value === `org_structure:update` &&
            names?.some((item: string) => item === `org_structure:create`)
          ) {
            return {
              ...item,
              disabled: true,
            };
          }
        }
        return item;
      }),
    [names],
  );
  return (
    <Space direction={"vertical"}>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        <Typography.Text strong>{label}</Typography.Text>
      </Checkbox>
      <div style={{ paddingLeft: 20 }}>
        <ProFormCheckbox.Group
          name={name}
          layout="vertical"
          fieldProps={{ onChange }}
          options={options}
        />
      </div>
    </Space>
  );
};

export default CheckboxFormFieldPermission;
