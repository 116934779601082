import React, { ReactElement } from "react";
import { Viewer, ViewMode } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import {
  defaultLayoutPlugin,
  ToolbarProps,
  ToolbarSlot,
} from "@react-pdf-viewer/default-layout";
import { Flex, Space, Grid, Alert } from "antd";
import * as pdfJS from "pdfjs-dist";
import pdfJSWorkerURL from "pdfjs-dist/build/pdf.worker?url";
import useTheme from "@/shared/theme/lib/use";
import * as Sentry from "@sentry/react";

type PreviewPdfProps = {
  src: string;
};

pdfJS.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL;

const { useBreakpoint } = Grid;

const PreviewPdf: React.FC<PreviewPdfProps> = ({ src }) => {
  const screens = useBreakpoint();
  const theme = useTheme();

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <Flex
            style={{
              width: "100%",
            }}
            justify="space-between"
          >
            <Space>
              <ShowSearchPopover />
              <Flex gap={2} align="center">
                <CurrentPageInput />
                /
                <NumberOfPages />
              </Flex>
            </Space>
            <Space size={0} direction="horizontal">
              <ZoomOut />

              {screens.sm && <Zoom />}

              <ZoomIn />
            </Space>

            <Flex align="center">
              {screens.sm && (
                <>
                  <GoToPreviousPage />

                  <GoToNextPage />
                </>
              )}

              <div style={{ padding: "0 2px" }}>
                <EnterFullScreen />
              </div>
            </Flex>
          </Flex>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  return (
    <Viewer
      theme={theme.value}
      viewMode={ViewMode.SinglePage}
      fileUrl={src}
      plugins={[defaultLayoutPluginInstance]}
      renderError={(error) => {
        Sentry.captureException(error);
        return (
          <Space
            style={{ width: "100%", height: "100%", justifyContent: "center" }}
            align="center"
          >
            <Alert message={error.message} type="error" />
          </Space>
        );
      }}
    />
  );
};

export default PreviewPdf;
export type { PreviewPdfProps };
