import React, { ReactNode } from "react";
import { Course } from "@/models.ts";
import { Tag } from "antd";

export const statusValueEnum: Record<Course["status"], ReactNode> = {
  active: <Tag color="success">Активный</Tag>,
  archived: <Tag color="magenta">Архивный</Tag>,
  to_archive: <Tag color="magenta">Архивировать</Tag>,
  archiving: <Tag color="magenta">Архивируется</Tag>,
  to_delete: <Tag color="error">Ожидает удаления</Tag>,
  deleting: <Tag color="error">Удаляется</Tag>,
  deleted: <Tag color="error">Удален</Tag>,
};
