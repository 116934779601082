import React from "react";
import useClient from "@/entities/client/lib/use.tsx";
import AutoRouter from "@/shared/auto-router/lib/component.tsx";

const Router: React.FC = () => {
  const client = useClient();

  return <AutoRouter basePath={client.type} />;
};

export default Router;
