import React from "react";
import PoolParticipantForm, {
  PoolParticipantFormProps,
} from "@/entities/pool-participant/ui/form";
import { deepmerge } from "deepmerge-ts";
import { PoolParticipant } from "@/models";
import axios from "@/axios";
import { message } from "antd";

type FormProps = PoolParticipantFormProps;

type PoolParticipantCreateFormProps = FormProps & {
  afterCreate?: (pool: PoolParticipant) => void;
};

const PoolParticipantCreateForm: React.FC<PoolParticipantCreateFormProps> = ({
  afterCreate,
  ...props
}) => {
  const defaultProps: FormProps = {
    fieldsProps: {
      id: { hidden: true },
      membersIds: {
        rules: [
          { required: true, message: "Выберите хотя бы одного участника" },
        ],
      },
    },
    onFinish: (values) => {
      // @ts-ignore
      const membersIds = values.members_ids;
      // @ts-ignore
      delete values.members_ids;
      const resources: PoolParticipant[] = membersIds.map(
        (member_id: string): PoolParticipant => ({
          ...values,
          member_id: Number(member_id),
        }),
      );

      return axios
        .post<{ data: PoolParticipant }>("/api/pool-participants/batch", {
          resources,
        })
        .then((res) => {
          message.success("Участник потока успешно создан");
          afterCreate?.(res.data.data);
          return Promise.resolve(true);
        })
        .catch((error) => {
          message.error(error.response.data.message);
        });
    },
  };

  return <PoolParticipantForm {...deepmerge(defaultProps, props)} />;
};

export default PoolParticipantCreateForm;
export type { PoolParticipantCreateFormProps };
