import React from "react";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { message, Modal } from "antd";
import { PoolParticipant } from "@/models";

type GroupParticipantDeleteButtonProps = {
  recordKeys: PoolParticipant["id"][];
  onAfterDelete?: () => void;
};

const GroupParticipantDeleteButton: React.FC<
  GroupParticipantDeleteButtonProps
> = ({ recordKeys, onAfterDelete }) => {
  const [modal, modalHolder] = Modal.useModal();

  const onDelete = () => {
    modal.warning({
      title: "Удаление участника из группы",
      content:
        "Выбранные участники группы будут удалены из группы, но останутся в системе.",
      icon: null,
      width: "40%",
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: () =>
        axios
          .request({
            method: "DELETE",
            url: `/api/group-participants/batch`,
            data: { resources: recordKeys },
          })
          .then(() => {
            onAfterDelete?.();
            message.success("Участники удалены из группы");
          })
          .catch(() => {
            message.error("Не удалось удалить участников из группы");
          }),
    });
  };

  const disabled = recordKeys.length === 0;

  return (
    <>
      {modalHolder}
      <Button type={"primary"} danger onClick={onDelete} disabled={disabled}>
        Удалить из группы
      </Button>
    </>
  );
};

export default GroupParticipantDeleteButton;
export type { GroupParticipantDeleteButtonProps };
