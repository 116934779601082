import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "@/app";
import "@/main.css";
import "@/sentry";
import "@/i18n";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
