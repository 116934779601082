import React, { useCallback } from "react";
import { message, Spin } from "antd";
import useSWR from "swr";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { TreeNode } from "@/models";
import { ProFormTreeSelect } from "@ant-design/pro-components";
import OrgStructureIcon from "@/entities/org-structure/ui/icon";
import useMe from "@/entities/me/lib/use";
import useFeatures from "@/entities/features/lib/use";

type OrgStructureTreeSelectProps = React.ComponentProps<
  typeof ProFormTreeSelect
> & {
  withType?: boolean;
  labelWithPath?: boolean;
  searchUser?: boolean;
  departmentOnly?: boolean;
  disabledDepartments?: boolean;
};

const OrgStructureTreeSelect: React.FC<OrgStructureTreeSelectProps> = ({
  withType,
  fieldProps,
  labelWithPath,
  searchUser,
  departmentOnly,
  disabledDepartments,
  ...props
}) => {
  const me = useMe();
  const features = useFeatures();

  const { data, isLoading, error } = useSWR(
    ["/api/tree-nodes/search", departmentOnly],
    async ([url]) => {
      let page = 1;
      let lastPage = Number.MAX_SAFE_INTEGER;

      const departments = [] as any[];

      while (page <= lastPage) {
        await axios
          .post<OrionRestIndexResponse<TreeNode>>(
            url,
            {
              filters: [
                { field: "parent_tree_node_id", operator: "=", value: null },
                {
                  field: "is_an_type",
                  operator: "in",
                  value: departmentOnly
                    ? ["department"]
                    : ["department", "position"],
                },
              ],
              includes: [
                { relation: "tree" },
                { relation: "is_an" },
                { relation: "tree.is_an" },
              ],
            },
            {
              params: {
                page,
                limit: 500,
              },
            },
          )
          .then((res) => {
            page = res.data.meta.current_page + 1;
            lastPage = res.data.meta.last_page;
            departments.push(...res.data.data);
          })
          .catch((error) => {
            message.error(
              error?.response?.data.message ?? "Что-то пошло не так",
            );
            throw error;
          });
      }

      return departments;
    },
  );

  const getPositionIds = (tree: TreeNode[]) => {
    let positionIds: number[] = [];

    tree.forEach((node) => {
      if (node.is_an_type === "position") {
        positionIds.push(node.is_an_id);
      }

      if (node.tree && node.tree.length > 0) {
        positionIds = positionIds.concat(getPositionIds(node.tree));
      }
    });

    return positionIds;
  };

  const treeNodeToSelectOption = useCallback(
    (
      treeNode: TreeNode,
      parentPath = "",
      disabledParent: boolean | undefined = undefined,
    ): any => {
      let disabledDepartment: boolean | undefined = disabledParent;
      if (
        features.isEnabled("restrict_accesses") &&
        me.is_restricted_access &&
        treeNode.is_an_type === "department" &&
        (disabledParent === undefined || disabledParent)
      ) {
        const hasAccess = me.accessed_departments.includes(treeNode.is_an_id);

        disabledDepartment = !hasAccess;
      }

      let value: string | number;

      if (departmentOnly) {
        value = treeNode.is_an_id;
      } else if (withType) {
        value = `${treeNode.is_an_type}#${treeNode.is_an_id}`;
      } else if (treeNode.is_an_type === "department") {
        const positionIds = getPositionIds(treeNode.tree!).join(",");
        value = `${positionIds},${treeNode.is_an_type}#${treeNode.is_an_id}`;
      } else {
        value = treeNode.is_an_id;
      }

      const path = parentPath
        ? `${parentPath}, ${treeNode.is_an.name}`
        : treeNode.is_an.name;

      const children = departmentOnly
        ? treeNode.tree?.filter(
            (treeNode) => treeNode.is_an_type === "department",
          )
        : treeNode.tree;

      const disabledPosition =
        me.is_restricted_access &&
        !me.accessed_positions?.includes(treeNode.is_an_id);

      return {
        title: treeNode.is_an.name,
        value: value,
        icon: <OrgStructureIcon type={treeNode.is_an_type as any} />,
        path,
        children: children!.map((treeNode) =>
          treeNodeToSelectOption(treeNode, path, disabledDepartment),
        ),
        disabled:
          treeNode.is_an_type === "department"
            ? disabledDepartments || disabledDepartment
            : disabledPosition,
      };
    },
    [],
  );

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!data) throw new Error("No data");

  const treeSelectData =
    data.map((treeNode) => treeNodeToSelectOption(treeNode)) ?? [];

  return (
    <ProFormTreeSelect
      allowClear
      fieldProps={{
        treeData: treeSelectData,
        filterTreeNode: true,
        treeIcon: true,
        showSearch: true,
        autoClearSearchValue: true,
        treeNodeFilterProp: "title",
        treeNodeLabelProp: labelWithPath ? "path" : "title",
        fieldNames: {
          label: "title",
        },
        ...fieldProps,
      }}
      {...props}
    />
  );
};

export default OrgStructureTreeSelect;
