import { Alert, Typography } from "antd";
import { AlertProps } from "antd/lib";
import React from "react";

const OneCHRMIntegrationAlert: React.FC<AlertProps> = ({ ...props }) => {
  return (
    <Alert
      message={
        <Typography.Text>
          Для создания интеграции с 1С:ЗУП скачайте <a>расширение</a> для 1С,
          установите расширение, следуя <a>инструкции</a>
        </Typography.Text>
      }
      type="info"
      {...props}
    />
  );
};

export default OneCHRMIntegrationAlert;
