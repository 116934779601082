import axios from "@/axios";
import { Member, SupportChat, SupportChatMessage } from "@/models";
import AvatarWithLoader from "@/shared/ant-design/avatar/ui/avatar-with-loader";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { ChatItem, ChatItemProps } from "@ant-design/pro-chat";
import { Avatar, Flex, theme, Typography } from "antd";
import useSWR from "swr";
import dayjs from "dayjs";
import { UserOutlined } from "@ant-design/icons";

type SupportChatItemProps = ChatItemProps & {
  senderId: SupportChatMessage["sender_id"];
  senderType: SupportChatMessage["sender_type"];
  supportChatOwnerId?: SupportChat["owner_id"];
  navigateToUser?: (memberId: Member["id"]) => void;
  role: string;
};

const SupportChatItem: React.FC<SupportChatItemProps> = ({
  senderId,
  senderType,
  supportChatOwnerId,
  role,
  placement,
  navigateToUser,
  message,
  originData,
}) => {
  const { token } = theme.useToken();
  const {
    data: member,
    error,
    isLoading,
  } = useSWR(
    senderType === "member" && supportChatOwnerId && role !== "user"
      ? `/api/members/${senderId}`
      : null,
    async (url) => {
      return await axios
        .get<OrionRestShowResponse<Member>>(url, {
          params: { includes: [{ relation: "avatar_image_file" }] },
        })
        .then((res) => res.data.data);
    },
  );

  if (error) throw error;
  if (isLoading) return <ChatItem placement={placement} avatar={{}} loading />;

  return (
    <ChatItem
      placement={placement}
      chatItemRenderConfig={{
        avatarRender: supportChatOwnerId
          ? () => {
              if (role === "user") {
                return <></>;
              }
              if (member?.avatar_image_file) {
                return (
                  <AvatarWithLoader src={member?.avatar_image_file?.url} />
                );
              }
              return <Avatar size={"large"} icon={<UserOutlined />} />;
            }
          : false,
        titleRender: () => {
          const createdAt = originData?.createAt;
          const isMemberAndNotUser = member && role !== "user";

          return (
            <Flex vertical>
              <Typography.Text
                type="secondary"
                style={{ fontSize: token.fontSizeSM }}
              >
                {dayjs(createdAt).format(dateSTime)}
              </Typography.Text>
              {senderType === "member" && isMemberAndNotUser && (
                <Typography.Link
                  strong
                  style={{
                    fontSize: token.fontSizeSM,
                    textAlign: placement === "left" ? "left" : "right",
                  }}
                  onClick={() => navigateToUser?.(member.id)}
                >
                  {`${member.first_name} ${member.last_name[0]}.`}
                </Typography.Link>
              )}
              {senderType === "anonymous" && role !== "user" && (
                <Typography.Text style={{ fontSize: token.fontSizeSM }}>
                  Анонимный пользователь
                </Typography.Text>
              )}
            </Flex>
          );
        },
      }}
      message={message}
      avatar={{}}
    />
  );
};

export default SupportChatItem;
