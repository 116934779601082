import React from "react";
import PoolTemplateForm, {
  PoolTemplateFormProps,
} from "@/entities/pool-template/ui/form.tsx";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Flex, Modal } from "antd";
import { deepmerge } from "deepmerge-ts";

type PoolTemplateModalFormProps = PoolTemplateFormProps & {
  //
};

const PoolTemplateModalForm: React.FC<PoolTemplateModalFormProps> = ({
  rest,
  ...formProps
}) => {
  const [open, setOpen] = React.useState(false);

  const triggerText = rest.type === "create" ? "Создать" : "Редактировать";
  let disabled = rest.type === "update" && rest.recordKey === undefined;
  if (rest.type === "update" && typeof rest.recordKey === "object") {
    disabled = rest.recordKey.length !== 1;
  }

  const title =
    rest.type === "create"
      ? "Создание шаблона потоков"
      : "Редактирование шаблона потоков";

  if (rest.type === "update") {
    const restOnAfterUpdate = rest.onAfterUpdate;
    rest.onAfterUpdate = async (values) => {
      setOpen(false);
      await restOnAfterUpdate?.(values);
    };
  }

  if (rest.type === "create") {
    const restOnAfterCreate = rest.onAfterCreate;
    rest.onAfterCreate = async (values) => {
      setOpen(false);
      await restOnAfterCreate?.(values);
    };
  }

  const defaultFormProps: Omit<typeof formProps, "courseId"> = {
    submitter: {
      render: (_, dom) => {
        return <Flex justify={"end"}>{dom}</Flex>;
      },
    },
  };

  formProps = deepmerge(defaultFormProps, formProps);

  return (
    <>
      <Button
        type={"primary"}
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        {triggerText}
      </Button>
      <Modal
        width={800}
        open={open}
        title={title}
        destroyOnClose
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <PoolTemplateForm {...formProps} rest={rest} />
      </Modal>
    </>
  );
};

export default PoolTemplateModalForm;
export type { PoolTemplateModalFormProps };
