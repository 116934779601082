import React from "react";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { message, Modal, Space, Typography } from "antd";
import { Automation } from "@/models";
import { ProForm, ProFormRadio } from "@ant-design/pro-components";
import { ProFormStyled } from "@/entities/automation/ui/pro-form-styled";

type AutomationStatusToogleButtonProps = {
  status: Automation["status"];
  recordKeys: number[];
  onAfterFinish?: () => void;
};

const AutomationStatusToogleButton: React.FC<
  AutomationStatusToogleButtonProps
> = ({ recordKeys, onAfterFinish, status }) => {
  const [modal, modalHolder] = Modal.useModal();
  const [form] = ProForm.useForm();

  const onToogleStatus = async () => {
    modal.confirm({
      title: `${
        status === "enabled" ? "Запустить" : "Остановить"
      } условие/условия автоназначения`,
      content:
        status === "enabled" ? (
          <Space direction="vertical">
            <Typography.Text type="secondary">
              Как только условие будет запущено, система автоматически начнет
              отслеживать изменения данных сотрудников.
            </Typography.Text>
            <ProFormStyled form={form} submitter={false}>
              <ProFormRadio.Group
                name="on_enabled_action"
                initialValue={"new_members"}
                options={[
                  {
                    label: (
                      <Space direction="vertical">
                        <Typography.Text>
                          Запуск только для новых сотрудников
                        </Typography.Text>
                        <Typography.Text type="secondary">
                          Условие не будут применено для существующих в системе
                          сотрудников
                        </Typography.Text>
                      </Space>
                    ),
                    value: "new_members",
                  },
                  {
                    label: (
                      <Space direction="vertical">
                        <Typography.Text>
                          Запуск для старых и новых сотрудников
                        </Typography.Text>
                        <Typography.Text type="secondary">
                          Внесенные изменения будут применены для существующих и
                          новых сотрудников
                        </Typography.Text>
                      </Space>
                    ),
                    value: "all_members",
                  },
                ]}
              />
            </ProFormStyled>
          </Space>
        ) : (
          "Выбранная запись/записи будет остановлена"
        ),
      icon: null,
      width: "60%",
      okText: status === "enabled" ? "Запустить" : "Остановить",
      okButtonProps: { danger: status === "disabled", type: "primary" },
      cancelText: "Отменить",
      closable: true,
      onOk: async () => {
        const resources = recordKeys.reduce(
          (acc: { [key: string]: Partial<Automation> }, id) => {
            acc[id] = { status };
            return acc;
          },
          {},
        );

        if (status === "enabled") {
          for (const key in resources) {
            resources[key].on_enabled_action =
              form.getFieldValue("on_enabled_action");
          }
        }

        return await axios
          .request({
            method: "PATCH",
            url: `/api/automations/batch`,
            data: { resources },
          })
          .then(() => {
            onAfterFinish?.();
            message.success(
              `Запись/записи условия автоназначения ${
                status === "enabled" ? "запущены" : "остановлены"
              }`,
            );
          })
          .catch(() => {
            message.error(
              `Не удалось ${
                status === "enabled" ? "запустить" : "остановить"
              } запись/записи условия автоназначения`,
            );
          });
      },
    });
  };

  const disabled = recordKeys.length === 0;

  return (
    <>
      {modalHolder}
      <Button
        type={"primary"}
        danger={status === "disabled"}
        onClick={onToogleStatus}
        disabled={disabled}
      >
        {status === "enabled" ? "Запустить" : "Остановить"}
      </Button>
    </>
  );
};

export default AutomationStatusToogleButton;
export type { AutomationStatusToogleButtonProps };
