import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

type Cast<Value> = {
  get: (value: string) => Value | null;
  set: (value: Value) => string;
};

function useQueryState<Value = string>(
  paramName: string = "tab",
  options?: {
    defaultValue?: Value;
    valueType?: "string" | "number" | "boolean" | "array" | "object";
    onValidateValue?: (value: Value) => boolean;
    cast?: Cast<Value>;
  },
) {
  const defaultOptions: typeof options = {
    valueType: "string",
  };

  options = { ...defaultOptions, ...options };

  let { defaultValue, valueType, onValidateValue, cast } = options;

  if (!cast) {
    switch (valueType) {
      case "string":
        cast = {
          get: (value) => value as Value,
          set: (value) => String(value),
        };
        break;
      case "number":
        cast = {
          get: (value) => Number(value) as Value,
          set: (value) => String(value),
        };
        break;
      case "boolean":
        cast = {
          get: (value) => (value.toLowerCase() === "true") as Value,
          set: (value) => (value ? "true" : "false"),
        };
        break;
      case "array":
      case "object":
        cast = {
          get: (value) => {
            try {
              return JSON.parse(value) as Value;
            } catch (error) {
              return null;
            }
          },
          set: (value) => JSON.stringify(value),
        };
        break;
      default:
        throw new Error("Unsupported value type");
    }
  }

  const [query, setQuery] = useSearchParams();

  const setActiveTab = (key: Value) => {
    setQuery((prev) => {
      prev.set(paramName, cast!.set(key));
      return prev;
    });
  };

  const currentValue = (() => {
    if (query.has(paramName)) {
      let rawValue = query.get(paramName)!;
      try {
        return rawValue ? cast!.get(rawValue) : null;
      } catch (error) {
        console.error(error);
        return null;
      }
    } else {
      return null;
    }
  })();

  useEffect(() => {
    if (defaultValue) {
      if (!currentValue) {
        setActiveTab(defaultValue);
      } else if (onValidateValue && !onValidateValue(currentValue)) {
        setActiveTab(defaultValue);
      }
    }
  }, [query]);

  return [currentValue!, setActiveTab] as const;
}

export default useQueryState;
