import React from "react";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Flex, Modal } from "antd";
import { deepmerge } from "deepmerge-ts";
import LearningMatrixEntryFormBatch, {
  LearningMatrixEntryFormBatchProps,
} from "@/entities/learning-matrix-entry/ui/form-batch.tsx";

type LearningMatrixEntryModalFormBatchProps = LearningMatrixEntryFormBatchProps;

const LearningMatrixEntryModalFormBatch: React.FC<
  LearningMatrixEntryModalFormBatchProps
> = ({ rest, ...formProps }) => {
  const [open, setOpen] = React.useState(false);

  const triggerText = rest.type === "create" ? "Создать" : "Редактировать";
  let disabled = rest.type === "update" && rest.recordKeys === undefined;

  const title =
    rest.type === "create"
      ? "Добавить запись матрицы"
      : "Редактировать запись матрицы";

  if (rest.type === "update") {
    const restOnAfterUpdate = rest.onAfterFinish;
    rest.onAfterFinish = async () => {
      setOpen(false);
      await restOnAfterUpdate?.();
    };
  }

  if (rest.type === "create") {
    const restOnAfterCreate = rest.onAfterFinish;
    rest.onAfterFinish = async () => {
      setOpen(false);
      await restOnAfterCreate?.();
    };
  }

  const defaultFormProps: typeof formProps = {
    submitter: {
      render: (_, dom) => {
        return (
          <Flex gap={16} justify={"end"}>
            {dom}
          </Flex>
        );
      },
    },
  };

  formProps = deepmerge(defaultFormProps, formProps);

  return (
    <>
      <Button
        type={"primary"}
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        {triggerText}
      </Button>
      <Modal
        width={800}
        open={open}
        title={title}
        destroyOnClose
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <LearningMatrixEntryFormBatch {...formProps} rest={rest} />
      </Modal>
    </>
  );
};

export default LearningMatrixEntryModalFormBatch;
export type { LearningMatrixEntryModalFormBatchProps };
