import axios from "@/axios";
import useSWR from "swr";
import { Area } from "@ant-design/charts";
import { ProForm, ProFormSelect } from "@ant-design/pro-components";
import { Button, Card, Space, Spin, theme } from "antd";
import React from "react";
import { ReloadOutlined } from "@ant-design/icons";

type FormData = {
  interval: number;
  group_by: "hour" | "day";
  stack_by: "none" | "space";
};

type Date = { point: string; count: number }[];

const IndicatorContentOnlineHistory: React.FC = () => {
  const [form] = ProForm.useForm<FormData>();
  const interval = ProForm.useWatch(["interval"], { form });
  const group_by = ProForm.useWatch(["group_by"], { form });
  const stack_by = ProForm.useWatch(["stack_by"], { form });

  const { token } = theme.useToken();

  const { data, isLoading, error, mutate } = useSWR(
    interval && group_by
      ? [
          `/api/dashboards/statistics/traffic-members`,
          interval,
          group_by,
          stack_by,
        ]
      : null,
    async ([url, interval, group_by, stack_by]) => {
      return axios
        .get<{ data: Date }>(url, {
          params: {
            interval,
            group_by,
            stack_by,
          },
        })
        .then((res) => res.data.data);
    },
  );

  // if (error) throw error;

  return (
    <Card
      title={
        <Space>
          <>Посещаемость платформы</>
          <Button
            type={"text"}
            icon={<ReloadOutlined />}
            onClick={() => {
              mutate();
            }}
          />
        </Space>
      }
      extra={
        <ProForm<FormData>
          form={form}
          initialValues={{
            interval: 7,
            group_by: "hour",
            stack_by: "none",
          }}
          submitter={false}
          layout={"horizontal"}
        >
          <Space>
            <ProFormSelect
              name={"interval"}
              label={"Интервал"}
              options={[
                { label: "За день", value: 1 },
                { label: "За неделю", value: 7 },
                { label: "За месяц", value: 30 },
                { label: "За квартал", value: 90 },
                { label: "За год", value: 365 },
              ]}
              style={{ marginBottom: 0 }}
              formItemProps={{ style: { marginBottom: 0 } }}
              allowClear={false}
            />
            <ProFormSelect
              name={"group_by"}
              label={"Группировка"}
              options={[
                { label: "По часам", value: "hour" },
                { label: "По дням", value: "day" },
                { label: "По месяцам", value: "month" },
                { label: "По годам", value: "year" },
              ]}
              formItemProps={{ style: { marginBottom: 0 } }}
              allowClear={false}
              onChange={(value) => {
                if (value === "hour") {
                  form.setFieldValue("stack_by", "none");
                }
              }}
            />
            <ProFormSelect
              name={"stack_by"}
              label={"Стак"}
              options={[
                { label: "Нет", value: "none" },
                { label: "По пространствам", value: "space" },
              ]}
              formItemProps={{ style: { marginBottom: 0 } }}
              allowClear={false}
              onChange={(value) => {
                if (value === "space") {
                  form.setFieldValue("group_by", "day");
                }
              }}
            />
          </Space>
        </ProForm>
      }
    >
      <Spin spinning={isLoading}>
        {stack_by === "none" && (
          <Area
            data={data ?? []}
            xField={"point"}
            yField={"count"}
            axis={{
              x: { labelFill: token.colorText },
              y: { labelFill: token.colorText },
            }}
            legend={{
              color: {
                itemLabelFill: token.colorText,
              },
            }}
          />
        )}
        {stack_by === "space" && (
          <Area
            data={data ?? []}
            xField={"point"}
            yField={"count"}
            colorField={"space_slug"}
            stack={true}
            axis={{
              x: { labelFill: token.colorText },
              y: { labelFill: token.colorText },
            }}
            legend={{
              color: {
                itemLabelFill: token.colorText,
              },
            }}
          />
        )}
      </Spin>
    </Card>
  );
};

export default IndicatorContentOnlineHistory;
