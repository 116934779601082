import React, { useState } from "react";
import { Layout as AntLayout, theme, Dropdown, Space } from "antd";
import Menu from "./menu";
import {
  LogoutOutlined,
  MoonOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import type { GlobalToken } from "antd/es/theme/interface";
import { Outlet } from "react-router-dom";
import AuthButton from "@/shared/components/auth-button.tsx";
import useTheme from "@/shared/theme/lib/use.tsx";

const { Header, Content } = AntLayout;

const HeaderStyled = styled(Header)<{ token: GlobalToken }>`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 ${({ token }) => token.sizeMD}px;
`;

const ContentStyled = styled(Content)<{ token: GlobalToken }>`
  margin: ${({ token }) => token.sizeMD}px;
  height: calc(100% - 64px - ${({ token }) => token.sizeMD * 2}px);
  display: flex;
  align-items: stretch;
`;

const Layout: React.FC = () => {
  const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);

  /** Styling */

  const { token } = theme.useToken();
  const themeAlgorithm = useTheme();

  /** Render */

  const LogoutButton = () => (
    <AuthButton type={"text"} icon={<LogoutOutlined />}>
      Выйти
    </AuthButton>
  );

  const ThemeSwitch = () => (
    <Dropdown
      menu={{
        selectable: true,
        defaultSelectedKeys: [themeAlgorithm.mode],
        items: [
          {
            key: "light",
            label: "Светлая",
            onClick: () => themeAlgorithm.set("light"),
          },
          {
            key: "dark",
            label: "Темная",
            onClick: () => themeAlgorithm.set("dark"),
          },
          {
            key: "auto",
            label: "Авто",
            onClick: () => themeAlgorithm.set("auto"),
          },
        ],
      }}
      open={isThemeMenuOpen}
      onOpenChange={(open) => {
        setIsThemeMenuOpen(open);
      }}
    >
      <Space>
        <MoonOutlined /> Тема
        {isThemeMenuOpen ? <UpOutlined /> : <DownOutlined />}
      </Space>
    </Dropdown>
  );

  return (
    <AntLayout style={{ height: "100%" }}>
      <HeaderStyled
        token={token}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 99,
          width: "100%",
        }}
      >
        <Menu />
        <div>
          <ThemeSwitch />
          <LogoutButton />
        </div>
      </HeaderStyled>
      <ContentStyled token={token}>
        <Outlet />
      </ContentStyled>
    </AntLayout>
  );
};

export default Layout;
