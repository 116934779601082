import React from "react";
import { theme } from "antd";

type PageContainerProps = {
  style?: React.CSSProperties;
  children: React.ReactNode;
};

const PageContainer: React.FC<PageContainerProps> = ({ style, children }) => {
  const { token } = theme.useToken();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: token.sizeMD,
        backgroundColor: token.colorBgContainer,
        borderRadius: token.borderRadius,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default PageContainer;
export type { PageContainerProps };
