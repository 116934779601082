import React from "react";
import { useAuth } from "react-oidc-context";
import { deepmerge } from "deepmerge-ts";
import Button, { ButtonProps } from "@/shared/ant-design/button/ui/button.tsx";

interface AuthButtonProps extends ButtonProps {}

const AuthButton: React.FC<AuthButtonProps> = ({ ...overrideProps }) => {
  const auth = useAuth();
  let props: ButtonProps = {};

  if (auth.isAuthenticated) {
    props.children = "Выйти";
    props.onClick = () => {
      return auth.signoutRedirect({
        post_logout_redirect_uri: window.location.origin,
      });
    };

    props.type = "text";
  } else {
    props.children = "Войти";
    props.onClick = () => {
      return auth.signinRedirect({ redirect_uri: window.location.href });
    };

    props.type = "primary";
  }

  props = deepmerge(props, overrideProps);

  return <Button {...props} />;
};

export default AuthButton;
