import React, { useState } from "react";
import { Button as AntdButton, ButtonProps as AntdButtonProps } from "antd";
import { deepmerge } from "deepmerge-ts";

type ButtonProps = AntdButtonProps;

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  const [loading, setLoading] = useState(false);

  const defaultProps: AntdButtonProps = {
    loading,
  };

  const overrideProps: AntdButtonProps = {
    onClick: async (event) => {
      await setLoading(true);
      await props.onClick?.(event);
      await setLoading(false);
    },
  };

  props = deepmerge(defaultProps, props);

  return (
    <AntdButton {...props} {...overrideProps}>
      {children}
    </AntdButton>
  );
};

export default Button;
export type { ButtonProps };
