import { TreeNode } from "@/models";

const getTreeNodeNames = (treeNode?: TreeNode): string => {
  if (!treeNode) {
    return "";
  }

  const name = treeNode.is_an?.name || "";

  if (treeNode.reverse_tree) {
    return `${getTreeNodeNames(treeNode.reverse_tree)}, ${name}`;
  }

  return name.trim();
};

export default getTreeNodeNames;
