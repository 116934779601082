import React from "react";
import axios from "@/axios";
import { Exam, Test } from "@/models";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useSWR from "swr";
import { Spin, Typography } from "antd";

type ExamTriesCounterProps = {
  poolParticipantId?: string;
  testId: Test["id"];
};

const ExamTriesCounter: React.FC<ExamTriesCounterProps> = ({
  poolParticipantId,
  testId,
}) => {
  const { data: exams, ...examsRequest } = useSWR(
    [`/api/exams/search`, poolParticipantId, testId, "counter"],
    async ([url, pool_participant_id, test_id]) => {
      return axios
        .post<OrionRestIndexResponse<Exam>>(url, {
          filters: [
            { field: "pool_participant_id", value: pool_participant_id },
            { field: "test_participant.test_id", value: test_id },
            {
              field: "status",
              operator: "!=",
              value: "not_started",
            },
            {
              field: "status",
              operator: "!=",
              value: "launched",
            },
          ],
          includes: [{ relation: "test_participant" }],
        })
        .then((response) => response.data.data);
    },
  );
  if (examsRequest.error) throw examsRequest.error;
  if (examsRequest.isLoading) return <Spin />;
  if (!exams) throw new Error("Exams not found");

  const lastExam = exams[exams.length - 1];

  const totalExamsCount = exams.length;
  let attemptsText = `${totalExamsCount}`;

  if (
    lastExam &&
    lastExam.test_participant!.test_snapshot.is_limits_testing_attempts
  ) {
    attemptsText += `/${
      lastExam.test_participant!.test_snapshot.testing_attempts_limit ?? ""
    }`;
  }

  return (
    <Typography.Title level={5} style={{ margin: 0 }}>
      Попытки: {attemptsText}
    </Typography.Title>
  );
};

export default ExamTriesCounter;
