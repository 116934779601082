import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import { statusValueEnum } from "@/entities/course/lib/status-value-enum";

type CourseSelectActiveArchivedStatusProps = ProFormSelectProps;

const CourseSelectActiveArchivedStatus: React.FC<
  CourseSelectActiveArchivedStatusProps
> = ({ ...props }) => {
  return <ProFormSelect valueEnum={statusValueEnum} {...props} />;
};

export default CourseSelectActiveArchivedStatus;
