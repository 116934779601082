import React from "react";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { message, Modal } from "antd";
import { PoolTemplate } from "@/models";

type PoolTemplateDeleteButtonProps = {
  recordKeys: PoolTemplate["id"][];
  onAfterDelete?: () => void;
};

const PoolTemplateDeleteButton: React.FC<PoolTemplateDeleteButtonProps> = ({
  recordKeys,
  onAfterDelete,
}) => {
  const [modal, modalHolder] = Modal.useModal();

  const onDelete = async () => {
    modal.warning({
      title: "Удалить шаблон потока",
      content: "Выбранная запись/записи будет удалена из системы",
      icon: null,
      width: "40%",
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: () =>
        axios
          .request({
            method: "DELETE",
            url: `/api/pool-templates/batch`,
            data: { resources: recordKeys },
          })
          .then(() => {
            onAfterDelete?.();
            message.success("Шаблоны потока удалены");
          })
          .catch((err) => {
            message.error(
              err.response.data.message ?? "Не удалось удалить шаблоны потока",
            );
          }),
    });
  };

  const disabled = recordKeys.length === 0;

  return (
    <>
      {modalHolder}
      <Button type={"primary"} danger onClick={onDelete} disabled={disabled}>
        Удалить
      </Button>
    </>
  );
};

export default PoolTemplateDeleteButton;
export type { PoolTemplateDeleteButtonProps };
