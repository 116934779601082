import { Modal, Space as AntSpace, Typography } from "antd";
import { Automation } from "@/models";
import { Link } from "react-router-dom";

const useDeletingErrorsModal = () => {
  const [modal, modalHolder] = Modal.useModal();

  const showDeletingErrorsModal = (title: string, data: Automation[]) => {
    modal.info({
      title,
      okText: "Закрыть",
      okButtonProps: {
        danger: true,
      },
      width: "60%",
      icon: null,
      content: (
        <AntSpace direction="vertical">
          <Typography.Text>Он используется в условиях:</Typography.Text>
          {data.map((record) => (
            <Link key={record.id} to={`/manage/automations/${record.id}`}>
              {record.name}
            </Link>
          ))}
        </AntSpace>
      ),
    });
  };

  return [showDeletingErrorsModal, modalHolder] as const;
};

export default useDeletingErrorsModal;
