import { Avatar, Skeleton } from "antd";
import { AvatarProps } from "antd/lib";
import React, { useEffect, useState } from "react";

type AvatarWithLoaderProps = AvatarProps;

const AvatarWithLoader: React.FC<AvatarWithLoaderProps> = ({
  src,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (typeof src !== "string") return;
    const img = new Image();
    img.src = src;
    img.onload = () => setLoading(false);
    img.onerror = function () {
      setLoading(false);
      setError(true);
    };
  }, [src]);

  if (loading) {
    return (
      <Skeleton.Avatar
        style={{ display: "flex", alignItems: "center" }}
        active
        size={"large"}
      />
    );
  }
  if (error) {
    return <Avatar size={"large"}>error</Avatar>;
  }

  return <Avatar size={"large"} src={src} {...props} />;
};

export default AvatarWithLoader;
