import React from "react";
import {
  Member as BaseMember,
  MemberAccessDepartment,
  MemberPosition,
  Permission,
} from "@/models";

export type MemberContextValue = Omit<BaseMember, "accessed_departments"> & {
  refresh: () => void;
  permissions: Permission["key"][];
  accessed_departments: Array<MemberAccessDepartment["id"]>;
  accessed_positions: Array<MemberPosition["id"]>;
  isLoading: boolean;
};

const MeContext = React.createContext<MemberContextValue | undefined>(
  undefined,
);

export default MeContext;
