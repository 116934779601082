import React from "react";
import ProFormInputMask, {
  ProFormInputMaskProps,
} from "@/shared/ant-design-pro-components/form-input-mask/ui/component.tsx";

type ProFormPhoneProps = Partial<ProFormInputMaskProps>;

const ProFormPhone: React.FC<ProFormPhoneProps> = (_props) => {
  const defaultProps: ProFormInputMaskProps = {
    mask: "+7 (000) 000-00-00",
    placeholder: "Введите значение",
  };

  const props = { ...defaultProps, ..._props };

  return <ProFormInputMask {...props} />;
};

export default ProFormPhone;
export type { ProFormPhoneProps };
