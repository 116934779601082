import React from "react";
import { message, Modal } from "antd";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest.tsx";
import { Export } from "@/entities/export/lib/model.ts";
import fileDownload from "js-file-download";
import Button from "@/shared/ant-design/button/ui/button";

type ExportButtonProps = {
  metaFields?: Export["meta_fields"];
  type: Export["type"];
} & {
  trigger?: JSX.Element;
};

const ExportButton: React.FC<ExportButtonProps> = ({
  metaFields,
  type,
  trigger = <Button type={"primary"}>Экспорт</Button>,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [error, setError] = React.useState<Error | null>(null);
  const [messageApi, messageContextHolder] = message.useMessage();

  const entityExport = async () => {
    let attempts = 120;

    let exportRecord = await axios
      .post<OrionRestCreateResponse<Export>>("/api/exports", {
        meta_fields: metaFields,
        status: "to_process",
        type,
      })
      .then((res) => res.data.data)
      .catch((error) => {
        message.error("Произошла ошибка при создании экспорта");
        setError(error);
        throw error;
      });

    if (exportRecord) {
      messageApi.open({
        type: "loading",
        content: `Идет экспорт №${exportRecord.id} ...`,
        duration: 0,
      });
      while (
        exportRecord.status !== "processed" &&
        exportRecord.status !== "failed" &&
        attempts--
      ) {
        exportRecord = await axios
          .get<OrionRestShowResponse<Export>>(
            `/api/exports/${exportRecord.id}?include=content`,
          )
          .then((res) => res.data.data)
          .catch((error) => {
            setError(error);
            throw error;
          });
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      messageApi.destroy();
    }

    if (exportRecord.status === "failed") {
      if (Array.isArray(exportRecord.result)) {
        exportRecord.result?.forEach((result) => {
          message.error(result.message);
        });
      } else {
        modal.error({
          title: `Экспорт №${exportRecord.id} завершился с ошибкой`,
          width: "60%",
          content: exportRecord.result?.message,
          closable: true,
          footer: null,
          maskClosable: true,
        });
      }

      return Promise.resolve(false);
    }

    if (exportRecord.status === "processed") {
      let url = exportRecord.content?.[0].url!;
      message.success(`Экспорт №${exportRecord.id} завершился`);

      axios
        .get(url, { responseType: "blob" })
        .then((res) => {
          fileDownload(res.data, exportRecord.content![0].name);
        })
        .catch((error) => {
          setError(error);
          throw error;
        });

      return Promise.resolve(false);
    } else {
      message.error(`Ошибка при экпортировании №${exportRecord.id}`);
      return Promise.resolve(false);
    }
  };

  if (error) throw error;

  return (
    <>
      {contextHolder}
      {messageContextHolder}
      {React.cloneElement(trigger, { onClick: entityExport })}
    </>
  );
};

export default ExportButton;
