import React from "react";
import PoolUpdateButton, {
  PoolUpdateButtonProps,
} from "@/entities/pool/ui/button-update";
import { message } from "antd";

type PoolCompleteButtonProps = Omit<PoolUpdateButtonProps, "newValue">;

const PoolCompleteButton: React.FC<PoolCompleteButtonProps> = ({
  afterUpdate,
  ...props
}) => {
  return (
    <PoolUpdateButton
      newValue={{ status: "completed" }}
      children={"Завершить"}
      danger
      afterUpdate={() => {
        message.success("Обучение завершено");
        afterUpdate?.();
      }}
      {...props}
    />
  );
};

export default PoolCompleteButton;
export type { PoolCompleteButtonProps };
