import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import Item, { ItemProps } from "@/entities/org-structure/ui/item";

type SortableItemProps = ItemProps;

const SortableItem: React.FC<SortableItemProps> = ({ id, item, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({ id: id!, data: item });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  if (id === "empty")
    return <div ref={setNodeRef} {...listeners} {...attributes}></div>;

  return (
    <Item
      id={id}
      item={item}
      type={item!.is_an_type as "department" | "position"}
      listeners={listeners}
      attributes={attributes}
      ref={setNodeRef}
      style={style}
      {...props}
    />
  );
};

export default SortableItem;
