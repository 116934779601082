import React, { useEffect } from "react";
import {
  ModalForm,
  ModalFormProps,
  ProForm,
  ProFormFieldSetProps,
  ProFormItem,
  ProFormProps,
  ProFormText,
  ProList,
  ProListProps,
} from "@ant-design/pro-components";
import { Group, Member } from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
} from "@/shared/types/orion-rest";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  GlobalToken,
  Row,
  Spin,
  Typography,
  message,
  theme,
} from "antd";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useSWRInfinite from "swr/infinite";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { AxiosRequestConfig } from "axios";
import { debounce } from "lodash";
import styled from "styled-components";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const StyledProList = styled(ProList)<
  ProListProps<Member> & { token: GlobalToken }
>`
  .ant-list-item {
    padding: ${({ token }) => token.paddingSM}px;
  }
  .ant-pro-card-body {
    padding-inline: 0 !important;
  }
`;

type Record = Group & {
  members_ids: Member["id"][];
};

type GroupParticipantFormBatchRecord = {
  groupId: number;
  memberIds: number[];
};

type GroupFormProps = ProFormProps<Record> & {
  modal?: boolean;
  groupId?: Group["id"][];
  onAfterAdd?: () => void;
};

type MemberListProps = ProListProps<Member> & {
  onChange?: ProFormFieldSetProps["onChange"];
};

const GroupParticipantForm: React.FC<GroupFormProps> = ({
  modal = false,
  groupId,
  onAfterAdd,
  ...props
}) => {
  const [form] = ProForm.useForm<Record>(props.form);

  const MemberList: React.FC<MemberListProps> = ({ ...props }) => {
    const [form] = ProForm.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
      [],
    );
    const { token } = theme.useToken();
    const pageSize = 20;

    const { data, mutate, size, setSize, isLoading, error } = useSWRInfinite(
      (pageIndex: number, previousPageData: Member[]) => {
        if (previousPageData && !previousPageData.length) return null;
        return [`/api/members/search`, pageIndex + 1, groupId];
      },
      async ([url, current, groupId]) => {
        const config: AxiosRequestConfig = {
          method: "POST",
          url,
          ...axiosConfigAdapter({ current, pageSize }),
        };

        const searchValues = form.getFieldsValue();

        if (searchValues["full_name"]) {
          config.data.filters.push({
            field: "full_name",
            operator: "ilike",
            value: `%${searchValues["full_name"]}%`,
          });
        }

        if (groupId && groupId.length === 1) {
          config.data.scopes.push({
            name: "whereDoesntHaveInGroup",
            parameters: groupId,
          });
        }

        return axios
          .request<OrionRestIndexResponse<Member>>(config)
          .then((res) => {
            return res.data.data.map((member) => ({
              ...member,
              meta: res.data.meta,
            }));
          });
      },
      {
        revalidateFirstPage: false,
      },
    );
    const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
      const errorScrollHeight = 10;
      const { scrollHeight, clientHeight, scrollTop } = event.currentTarget;
      const isEndScroll =
        scrollHeight - (clientHeight + scrollTop) <= errorScrollHeight;

      if (isEndScroll) {
        setSize(size + 1);
      }
    };

    useEffect(() => {
      mutate();
    }, []);

    const debouncedMutate = debounce(mutate, 200);

    if (isLoading) return <Spin />;
    if (error) throw error;
    if (!data) throw new Error("No data");

    const dataSource = data.flat();

    const onSelectAllChange = (event: CheckboxChangeEvent) => {
      if (event.target.checked) {
        const allMembersIds = dataSource.map((el) => el.id);
        setSelectedRowKeys(allMembersIds);
        props.onChange?.(allMembersIds);
      } else {
        setSelectedRowKeys([]);
        props.onChange?.([]);
      }
    };

    return (
      <>
        <ProForm
          form={form}
          onValuesChange={() => debouncedMutate()}
          submitter={false}
        >
          <Row gutter={12}>
            <Col span={24}>
              <ProFormText name={"full_name"} placeholder="Поиск по ФИО" />
            </Col>
          </Row>
          <Checkbox
            style={{
              width: "100%",
              backgroundColor: token.colorBgContainerDisabled,
              padding: token.paddingSM,
            }}
            checked={selectedRowKeys.length === dataSource.length}
            indeterminate={
              selectedRowKeys &&
              selectedRowKeys?.length > 0 &&
              selectedRowKeys?.length < dataSource.length
            }
            onChange={onSelectAllChange}
          >
            <Typography.Text strong>ФИО</Typography.Text>
          </Checkbox>
          <Divider style={{ margin: "0" }} />
        </ProForm>
        <div
          onScroll={onScroll}
          style={{ maxHeight: "50vh", overflow: "auto" }}
        >
          <StyledProList
            ErrorBoundary={false}
            token={token}
            rowKey="id"
            dataSource={dataSource}
            tableAlertRender={false}
            metas={{
              title: {
                dataIndex: "full_name",
                render: (dom) => <Typography.Text>{dom}</Typography.Text>,
              },
            }}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedRowKeys,
              onChange: (_selectedRowKeys) => {
                const previouslySelectedKeys = selectedRowKeys.filter((id) => {
                  return !dataSource.map((el) => el.id).includes(Number(id));
                });
                const selectedKeys = [
                  ...previouslySelectedKeys,
                  ..._selectedRowKeys,
                ];
                props.onChange?.(selectedKeys);
                setSelectedRowKeys(selectedKeys);
              },
            }}
          />
        </div>
      </>
    );
  };

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: { style: { display: "none" } },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  defaultProps = deepmerge(defaultProps, {
    submitter: { searchConfig: { submitText: "Добавить" } },
  });
  defaultProps.onFinish = async (values) => {
    const filters = {
      id: groupId ?? null,
    };
    const existingGroupsParticipants = await axios
      .post<OrionRestIndexResponse<Group>>("/api/groups/search", {
        includes: [{ relation: "participants" }],
        ...axiosConfigAdapter({}, {}, filters),
      })
      .then((res) =>
        res.data.data.map((group) => ({
          groupId: group.id,
          memberIds: group.participants.map(
            (participant) => participant.member_id,
          ),
        })),
      )
      .catch((err) => {
        message.error(
          err.response.data.message ?? "Ошибка при добавлении участников",
        );
      });

    const selectedGroupsParticipants = groupId?.map((groupId) => ({
      groupId,
      memberIds: values.members_ids,
    }));

    const filterUniqueMembers = (
      existing: GroupParticipantFormBatchRecord[],
      selected: GroupParticipantFormBatchRecord[],
    ) => {
      return selected.map((selectedGroup) => {
        const existingGroup = existing.find(
          (group) => group.groupId === selectedGroup.groupId,
        );
        if (!existingGroup) {
          return selectedGroup;
        }

        const uniqueMembers = selectedGroup.memberIds.filter((memberId) => {
          return !existingGroup.memberIds.includes(memberId);
        });

        return {
          groupId: selectedGroup.groupId,
          memberIds: uniqueMembers,
        };
      });
    };

    if (!existingGroupsParticipants || !selectedGroupsParticipants)
      return false;

    const filteredGroupsParticipants = filterUniqueMembers(
      existingGroupsParticipants,
      selectedGroupsParticipants,
    );

    const resources = filteredGroupsParticipants.flatMap(
      ({ groupId, memberIds }) => {
        return memberIds?.map((memberId) => ({
          member_id: memberId,
          group_id: groupId,
        }));
      },
    );

    if (resources.length === 0) {
      message.error("Пользователи уже есть в указанных группе/группах");
      return false;
    }

    return await axios
      .post<OrionRestCreateResponse<Group>>("/api/group-participants/batch", {
        resources,
      })
      .then(() => {
        message.success("Участники добавлены в указанные группу/группы");
        onAfterAdd?.();
        return true;
      })
      .catch((err) => {
        message.error(
          err.response.data.message ?? "Ошибка при добавлении участников",
        );

        if (err.response.status === 422) {
          setValidationErrorsToFormFields(form, err.response.data.errors);
        } else {
          console.error(err);
        }

        return false;
      });
  };

  /** Pre Render */

  const FormComponent = modal ? ModalForm<Record> : ProForm<Record>;
  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Type Modal */

  if (modal) {
    const disabled = groupId === undefined || groupId?.length === 0;
    const modalFormProps: Partial<ModalFormProps<Group>> = {
      width: "60%",
      title: "Добавление участника в группу",
      trigger: (
        <Button type={"primary"} disabled={disabled}>
          Добавить в группу
        </Button>
      ),
      modalProps: {
        destroyOnClose: true,
      },
    };

    props = deepmerge(modalFormProps, props);
  }

  /** Render */

  return (
    <FormComponent {...props}>
      <ProFormItem
        name={"members_ids"}
        label="Пользователи"
        rules={[
          { required: true, message: "Выберите хотя бы одного участника" },
        ]}
      >
        <MemberList />
      </ProFormItem>
    </FormComponent>
  );
};
export default GroupParticipantForm;
export type { GroupFormProps };
