import { useState } from "react";
import ImportButton, { ImportButtonProps } from "@/entities/import/ui/button";

const useImportModal = (props: ImportButtonProps) => {
  const [open, setOpen] = useState(false);

  const modalHolder = (
    <ImportButton
      open={open}
      onOpenChange={(value) => setOpen(value)}
      {...props}
    />
  );

  return [
    {
      open: () => {
        setOpen(true);
      },
    },
    modalHolder,
  ] as const;
};

export default useImportModal;
