import React from "react";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { theme } from "antd";
import { ExamResultQuestionsItem } from "@/entities/exam/lib/model";

type ExamsTableProps = ProTableProps<ExamResultQuestionsItem, any>;

const ExamReplyQuestionsTable: React.FC<ExamsTableProps> = ({ ...props }) => {
  const { token } = theme.useToken();

  const defaultProps: Partial<typeof props> = {
    // rowKey: "id", // TODO: What to use as rowKey?
    columns: [
      // Вопрос
      {
        title: "Вопрос",
        dataIndex: "text",
      },
      // Балл
      {
        title: "Балл",
        dataIndex: "points",
      },
      // Ваш ответ
      {
        title: "Ответ",
        dataIndex: "answers.*.text.join()",
        render: (_, { answers }) => {
          return answers
            .filter((answer) => answer.is_replied)
            .map((answer) => {
              return answer.value;
            })
            .join(", ");
        },
      },
      // Верно/Неверно
      {
        title: "Верно/неверно",
        dataIndex: "is_correct",
        // valueType: "checkbox",
        valueEnum: {
          true: { text: "Верно", color: token.colorSuccess },
          false: { text: "Неверно", color: token.colorError },
        },
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return <ProTable {...props} />;
};

export default ExamReplyQuestionsTable;
export type { ExamsTableProps };
