import React from "react";
import { usePoolParticipant } from "@/pages/space/education/[pool_participant_id]/context-pool-participant";

const BreadcrumbItem: React.FC = () => {
  const poolParticipant = usePoolParticipant();

  return <>{poolParticipant.pool!.course!.name}</>;
};

export default BreadcrumbItem;
