import React from "react";
import { ProFormDateTimePicker } from "@ant-design/pro-components";
import dayjs from "dayjs";

type Normalize = React.ComponentProps<
  typeof ProFormDateTimePicker
>["normalize"];

/**
 * Date
 */
export const date = "DD.MM.YYYY";

export const dateNormalize: Normalize = (value: any) => {
  if (!value) return value;

  return dayjs(value, date).format("YYYY-MM-DD");
};

/**
 * Time
 */
export const time = "HH:mm:ss";

/**
 * Short time
 */
export const sTime = "HH:mm";

/**
 * Date and time
 */
export const dateTime = `${date} ${time}`;

/**
 * Date and short time
 */
export const dateSTime = `${date} ${sTime}`;

export const toISOString = (dayjs: dayjs.Dayjs) => {
  return (
    dayjs
      .locale("UTC")
      .toISOString()
      /** Приводим JS ISO к формату, который принимает Backend */
      .replace(/Z$/, "000Z")
  );
};

export const toTime = (seconds: number) => {
  return dayjs().startOf("day").add(seconds, "second").format(time);
};

export const dateSTimeNormalize: Normalize = (value) => {
  if (!value) return value;

  return toISOString(dayjs(value, dateSTime));
};
