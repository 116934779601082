import React, { useEffect } from "react";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import { Result, theme, Tooltip, Typography } from "antd";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { ExternalCourse, LearningMatrixEntry } from "@/models.ts";
import { Course } from "@/entities/course/lib/modal.ts";
import { AxiosRequestConfig } from "axios";
import { ProColumns } from "@ant-design/pro-components";
import styled from "styled-components";
import { GlobalToken } from "antd/es/theme/interface";
import LearningMatrixEntryDeleteButton from "@/entities/learning-matrix-entry/ui/delete-button";
import ExportButton from "@/entities/export/ui/button";
import ImportButton from "@/entities/import/ui/button";
import LearningMatrixEntryModalFormBatch from "@/entities/learning-matrix-entry/ui/modal-form.tsx";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { useTranslation } from "react-i18next";
import getTreeNodeNames from "@/entities/member/lib/get-tree-node-names";
import CourseSelectWithExternal from "@/entities/course/ui/select-with-external";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import OrgStructureIcon from "@/entities/org-structure/ui/icon";

type Record = LearningMatrixEntry & {
  [key: string]: any;
};
type Params = Partial<LearningMatrixEntry>;

const ProTableStyled = styled(ProTable<Record, Params>)<
  ProTableProps<Record, Params> & {
    token: GlobalToken;
  }
>`
  .ant-table-header .course-title {
    rotate: -180deg;
    writing-mode: vertical-rl;
    height: 200px;
  }

  .ant-table-header .external_course {
    background: ${({ token }) => token["gold-1"]};
  }

  .ant-table-header .fixed-scroll {
    position: sticky !important;
    z-index: 1;
    right: 0;
  }

  .ant-table-header .courses-group-column {
    display: block;
    padding-right: 24px;
    width: 100%;
    text-align: end;
  }
  .ant-table-body {
    overflow: auto !important;
  }
`;

type CourseWithType = (Course | ExternalCourse) & {
  course_type: "course" | "external_course";
};

type LearningMatrixProps = {};

const LearningMatrix: React.FC<LearningMatrixProps> = ({}) => {
  const { t } = useTranslation();
  const [someSelected, setSomeSelected] = React.useState(false);
  const member = useMe();

  const { token } = theme.useToken();
  const [courses, setCourses] = React.useState<CourseWithType[]>([]);
  const [total, setTotal] = React.useState<number>();
  const { parentHeight, ref } = useParentHeight("table");

  const [error, setError] = React.useState<Error | null>(null);
  const [coursesColumns, setCoursesColumns] = React.useState<
    ProColumns<Record>[]
  >([]);

  useEffect(() => {
    setCoursesColumns(
      courses.map<ProColumns<Record>>((course) => ({
        width: 75,
        renderText: (_, record) => {
          const cell = record[`${course.course_type}#${course.id}`];

          if (!cell) return " ";

          let res = "+";
          if (cell.retry_month_count) res += ` (${cell.retry_month_count})`;
          return res;
        },
        className: course.course_type,
        title: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tooltip title={course.name}>
              <Typography.Paragraph
                className="course-title"
                ellipsis={{ rows: 2 }}
              >
                {course.name}
              </Typography.Paragraph>
            </Tooltip>
          </div>
        ),
        align: "center",
      })),
    );
  }, [courses]);

  if (error) {
    return <Result status="error" title="Ошибка" subTitle={error.message} />;
  }

  return (
    <div ref={ref} style={{ height: "calc(100% - 30px" }}>
      <ProTableStyled
        rowKey="id"
        rowSelection={{
          onChange: (selectedRowKeys) => {
            setSomeSelected(selectedRowKeys.length > 0);
          },
        }}
        toolBarRender={(action, { selectedRowKeys, selectedRows }) => {
          if (!selectedRows) {
            throw new Error("selectedRows is undefined");
          }

          if (!selectedRowKeys) {
            throw new Error("selectedRows is undefined");
          }

          if (action === undefined) {
            throw new Error("action is undefined");
          }

          const selectedLearningMatrixRecordsIdsToUpdate =
            selectedRows.length === 1 ? selectedRows[0].ids : undefined;

          const selectedLearningMatrixRecordsIdsToDelete = selectedRows.flatMap(
            (record) => record.ids,
          );

          return [
            member.permissions.includes("learning_matrix_entry:delete") && (
              <LearningMatrixEntryDeleteButton
                key={"delete"}
                recordKeys={selectedLearningMatrixRecordsIdsToDelete}
                onAfterDelete={() => {
                  action.reloadAndRest?.();
                }}
              />
            ),
            member.permissions.includes("export:export_learning_matrix") && (
              <ExportButton type={"export_learning_matrix"} />
            ),

            member.permissions.includes("import:learning_matrix") && (
              <ImportButton
                type="learning_matrix"
                onAfterFinish={action.reload}
              />
            ),
            member.permissions.includes("learning_matrix_entry:update") && (
              <>
                <LearningMatrixEntryModalFormBatch
                  key={`update-batch-${selectedRowKeys.join("-")}`}
                  rest={{
                    type: "update",
                    recordKeys: selectedLearningMatrixRecordsIdsToUpdate,
                    onAfterFinish: () => {
                      action.reload();
                      action.clearSelected?.();
                    },
                  }}
                />
              </>
            ),
            member.permissions.includes("learning_matrix_entry:create") && (
              <LearningMatrixEntryModalFormBatch
                key={"create-batch"}
                rest={{
                  type: "create",
                  onAfterFinish: () => {
                    action.reload();
                  },
                }}
              />
            ),
          ];
        }}
        token={token}
        hasQueryParams
        options={false}
        pagination={{
          defaultPageSize: 100,
        }}
        style={{
          width: "calc(100vw - 200px - 32px)",
          maxWidth: "calc(1920px - 200px - 32px)",
        }}
        scroll={{
          x: 500,
          y: parentHeight - (total ? (someSelected ? 472 : 412) : 132),
        }}
        bordered={total ? true : false}
        request={async (params, sort, filter) => {
          const filterCourse = filter["course"];
          const filterOrgStructure = filter["org_structure"];

          delete filter["course"];
          delete filter["org_structure"];

          const axiosConfig: AxiosRequestConfig = {
            method: "POST",
            url: "/api/learning-matrix-entries/search",
            ...axiosConfigAdapter(params, sort, filter),
          };

          axiosConfig.data.includes.push({ relation: "course" });
          axiosConfig.data.includes.push(
            { relation: "org_structure" },
            { relation: "org_structure.tree_node.reverse_tree.is_an" },
          );

          if (filterCourse) {
            axiosConfig.data.filters.push({
              type: "and",
              nested: filterCourse.map((key, index) => {
                if (typeof key !== "string")
                  throw new Error("key is not a string");

                const [type, id] = key.split("#");

                return {
                  type: "or",
                  nested: [
                    { field: "course_type", operator: "=", value: type },
                    { field: "course_id", operator: "=", value: id },
                  ],
                };
              }),
            });
          }

          if (filterOrgStructure) {
            axiosConfig.data.filters.push({
              type: "and",
              nested: filterOrgStructure.map((key, index) => {
                if (typeof key !== "string")
                  throw new Error("key is not a string");

                const [type, id] = key.split("#");

                return {
                  type: "or",
                  nested: [
                    { field: "org_structure_type", operator: "=", value: type },
                    { field: "org_structure_id", operator: "=", value: id },
                  ],
                };
              }),
            });
          }

          return await axios
            .request<OrionRestIndexResponse<LearningMatrixEntry>>(axiosConfig)
            .then(({ data }) => {
              /** Courses */
              let newCourses = data.data.map(({ id, course, course_type }) => {
                if (!course) {
                  throw new Error(
                    `Курс не найден для записи learning-matrix-entry#${id}`,
                  );
                }

                return { ...course, course_type };
              });

              newCourses = newCourses.filter((el, index, self) => {
                return (
                  index ===
                  self.findIndex(
                    (el2) =>
                      el2.id === el.id && el2.course_type === el.course_type,
                  )
                );
              });
              setCourses(newCourses);

              /** Mutate */
              const records = data.data
                .map((record): Record => {
                  const { course_id, course, course_type } = record;

                  return {
                    ...record,
                    [`${course_type}#${course_id}`]: {
                      ...course,
                      retry_month_count: record.retry_month_count,
                    },
                    ids: [record.id],
                  };
                })
                .reduce((acc: any, record: any) => {
                  const existingIndex = acc.findIndex(
                    (item: any) =>
                      item.org_structure_type === record.org_structure_type &&
                      item.org_structure_id === record.org_structure_id,
                  );

                  if (existingIndex === -1) {
                    acc.push(record);
                  } else {
                    const existing = acc[existingIndex];

                    record.ids.push(...existing.ids);

                    acc[existingIndex] = {
                      ...existing,
                      ...record,
                    };
                  }

                  return acc;
                }, []);

              setTotal(data.meta.total);

              /** Return */
              return {
                data: records,
                success: true,
                total: data.meta.total,
              };
            })
            .catch((error) => {
              setError(error);
              throw error;
            });
        }}
        columns={[
          {
            dataIndex: "org_structure",
            title: () => `${t("Подразделение")} / ${t("Должность")}`,
            tooltip: true,
            fixed: "left",
            align: "center",
            renderText: (_, { org_structure, org_structure_type }) => {
              if (!org_structure) {
                return "-";
              }

              const treeNodeName =
                // @ts-ignore
                getTreeNodeNames(org_structure?.tree_node) +
                " " +
                org_structure?.name;

              return (
                <Typography.Text ellipsis={{ tooltip: true }}>
                  {<OrgStructureIcon type={org_structure_type} />}{" "}
                  {treeNodeName}
                </Typography.Text>
              );
            },
            filters: false,
            formItemProps: {
              tooltip: false,
            },
            renderFormItem(_, config) {
              return (
                <OrgStructureTreeSelect
                  labelWithPath
                  withType
                  {...config}
                  fieldProps={{ multiple: true }}
                />
              );
            },
          },
          {
            title: <span className="courses-group-column">Курс</span>,
            className: "fixed-scroll",
            children: coursesColumns,
            dataIndex: "course",
            filters: false,
            renderFormItem(_, config) {
              return (
                <CourseSelectWithExternal
                  whereHaveInLearningMatrix
                  hideAddInput
                  {...config}
                  mode="multiple"
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default LearningMatrix;
