import React, { useEffect } from "react";
import useSWR from "swr";
import axios from "@/axios";
import Spin from "@/shared/components/spin";
import SpaceContext, { SpaceContextValue } from "@/entities/space/lib/context";
import AccessDeniedResult from "@/entities/space/ui/access-denied-result";
import { Space } from "@/entities/space/lib/model";
import NotFoundResult from "../ui/not-found-result";
import * as Sentry from "@sentry/react";
import { useAuth } from "react-oidc-context";

type Props = {
  children: React.ReactNode;
};

const SpaceContextProvider: React.FC<Props> = ({ children }) => {
  const { data, isLoading, error, mutate } = useSWR(
    "/api/.well-known/space",
    (url) => axios.get<Space>(url).then((res) => res.data),
    { refreshInterval: 60 * 1000 },
  );
  const auth = useAuth();

  useEffect(() => {
    mutate();
  }, [auth.isAuthenticated]);

  useEffect(() => {
    Sentry.setTag("space", data?.uuid);
    Sentry.setExtra("space", data);
  }, [data]);

  if (error && error.response?.status === 404) return <NotFoundResult />;
  if (error) throw error;

  const allowedStatuses = ["to_update", "updating", "updated", "active"];

  if (data && !allowedStatuses.includes(data.status))
    return <AccessDeniedResult />;

  const value: SpaceContextValue = {
    ...data!,
    refresh: async () => {
      await mutate();
    },
    isLoading: isLoading,
  };

  return (
    <SpaceContext.Provider value={value}>{children}</SpaceContext.Provider>
  );
};

export default SpaceContextProvider;
