import React from "react";
import { BroadcastMessages } from "@/models";
import { message, Modal, Tooltip, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "@/axios.ts";

type BroadcastMessagesDeleteTableRowActionProps = {
  recordKey: BroadcastMessages["id"];
  onAfterRemove?: () => unknown | Promise<unknown>;
};

const BroadcastMessagesDeleteTableRowAction: React.FC<
  BroadcastMessagesDeleteTableRowActionProps
> = ({ recordKey, onAfterRemove }) => {
  const [modal, modalHolder] = Modal.useModal();

  const onDelete = async () => {
    return modal.confirm({
      title: `Вы уверены, что хотите удалить широковещательное сообщение?`,
      okText: "Удалить",
      okType: "danger",
      cancelText: "Отмена",
      onOk: async () =>
        await axios
          .delete(`/api/broadcast-messages/${recordKey}`)
          .then(async () => {
            onAfterRemove?.();
            message.success("Широковещательное сообщение успешно удалены");
          })
          .catch((err) => {
            message.error(
              err.response.data.message ??
                "Ошибка удаления широковещательного сообщения",
            );
          }),
    });
  };

  return (
    <>
      {modalHolder}
      <Tooltip title={"Удалить"} mouseEnterDelay={1}>
        <Typography.Link onClick={onDelete} type={"danger"}>
          <DeleteOutlined />
        </Typography.Link>
      </Tooltip>
    </>
  );
};

export default BroadcastMessagesDeleteTableRowAction;
export type { BroadcastMessagesDeleteTableRowActionProps };
