import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

const useValueEnum = (): Record<any, ReactNode> => {
  const { t } = useTranslation();

  return {
    consolidated: "Сводный отчёт",
    individual_education_progress: "Индивидуальный прогресс обучающегося",
    latest_added_members: "Последние добавленные сотрудники",
    completed_education_members: "Отчет о завершивших обучение сотрудниках",
    education_ending_tomorrow:
      "Обучение сотрудников будет просрочено в течениe 24 часов",
    overdue_education: "Просроченные обучения сотрудников",
    education_statistics_by_department: t(
      "Прогресс учащихся по подразделениям",
    ),
    repeated_education: "Повторное прохождение обучения",
    company_personnel_changes: "Кадровые изменения компании",
  };
};

export default useValueEnum;
