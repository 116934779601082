const bytesToHumanReadable = (value: number) => {
    const units = ["Б", "КБ", "МБ", "ГБ", "ТБ", "ПБ", "ЭБ", "ЗБ", "ИБ"];
    const unitsLength = units.length;
  
    let unitIndex = 0;
    let unit = units[unitIndex];
  
    while (value > 1024 && unitIndex < unitsLength - 1) {
      value /= 1024;
      unitIndex++;
      unit = units[unitIndex];
    }
  
    return `${value.toFixed(2)} ${unit}`;
  };

export default bytesToHumanReadable;
