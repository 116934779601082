import useFeatures from "@/entities/features/lib/use";
import useMode from "@/entities/mode/lib/use";
import useVersion from "@/entities/version/lib/use";
import Spin from "@/shared/components/spin";
import { useAuthRefreshLoading } from "./context-auth-refresh-loading";

type Props = {
  children: React.ReactNode;
};

const ContextDepsLoader: React.FC<Props> = ({ children }) => {
  const { isLoading: versionContextLoading } = useVersion();
  const { isLoading: modeContextLoading } = useMode();
  const { isLoading: featuresContextLoading } = useFeatures();
  const { isLoading: authRefreshLoading } = useAuthRefreshLoading();

  if (
    versionContextLoading ||
    modeContextLoading ||
    featuresContextLoading ||
    authRefreshLoading
  ) {
    return <Spin />;
  }

  return <>{children}</>;
};

export default ContextDepsLoader;
