import React from "react";
import { Flex, Space, theme } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import RolesForm from "@/entities/roles/form";
import { useNavigate } from "react-router-dom";

const Page: React.FC = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Space
        direction={"vertical"}
        style={{
          height: "100%",
          width: "calc(100vw - 200px - 32px)",
          maxWidth: "calc(1920px - 200px - 32px)",
          backgroundColor: token.colorBgContainer,
          padding: token.paddingMD,
          overflow: "auto",
        }}
      >
        <RolesForm
          rest={{
            type: "create",
            onAfterFinish: (data) => navigate(`/manage/roles/${data.id}`),
            onAfterCancel: () => navigate("/manage/roles"),
          }}
        />
      </Space>
    </Flex>
  );
};

export default Page;
