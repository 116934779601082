import React from "react";
import useSWR from "swr";
import axios from "@/axios";
import Spin from "@/shared/components/spin";
import ClientContext from "@/entities/client/lib/context";
import { Client } from "@/entities/client/lib/type";
import ErrorPage from "@/shared/pages/error.tsx";
import { Result } from "antd";

type Props = {
  children: React.ReactNode;
};

const ClientContextProvider: React.FC<Props> = ({ children }) => {
  const { data, isLoading, error } = useSWR(
    "/api/.well-known/client",
    (url) => axios.get<Client>(url).then((res) => res.data),
    { revalidateOnFocus: false },
  );

  if (isLoading) return <Spin />;
  if (error && error.response?.status === 404) {
    return (
      <Result
        status={"404"}
        title={"404 Не найдено"}
        subTitle={
          "Не удалось загрузить данные о клиенте, возможно вы перешли по неверной ссылке."
        }
      />
    );
  }
  if (error) return <ErrorPage errorMessage={error.message} />;
  if (!data) {
    return <ErrorPage errorMessage={"Не удалось загрузить данные о клиенте"} />;
  }

  return (
    <ClientContext.Provider value={data}>{children}</ClientContext.Provider>
  );
};

export default ClientContextProvider;
