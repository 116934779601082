import React, { useEffect, useState } from "react";
import { Test } from "@/models.ts";
import { Button, Flex, Grid, Space, Typography } from "antd";
import ExamTable from "@/entities/exam/ui/table.tsx";
import TestResult from "@/pages/space/education/[pool_participant_id]/tests/[test_id]/results/result.tsx";
import { useParams } from "react-router-dom";
import ExamButton from "./exam-button";
import ExamTriesCounter from "./exam-tries-counter";
import styled from "styled-components";

const TableStyledWrapper = styled.div`
  .ant-pro-card-body {
    padding: 0;
  }
`;

type TestDescriptionsProps = {
  test: Test;
  onTestSuccessFinish?: () => void;
  children: React.ReactNode;
};

const TestDescriptions: React.FC<TestDescriptionsProps> = ({
  test,
  onTestSuccessFinish,
  children,
}) => {
  const { pool_participant_id } = useParams();
  const screens = Grid.useBreakpoint();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isModalVisible && !screens.sm) {
      setIsModalVisible(false);
    }
  }, [screens.sm]);

  return (
    <>
      {isModalVisible && (
        <TestResult
          modal
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          test_id={test.id}
        />
      )}
      <Space
        direction={"vertical"}
        style={{ height: "100%", overflow: "auto" }}
      >
        <Flex wrap={screens.sm ? "nowrap" : "wrap"} justify="end">
          {children}
          <ExamButton
            poolParticipantId={pool_participant_id}
            testId={test.id}
          />
        </Flex>
        <Flex justify={"space-between"}>
          <ExamTriesCounter
            poolParticipantId={pool_participant_id}
            testId={test.id}
          />
        </Flex>
        {screens.sm && (
          <TableStyledWrapper>
            <ExamTable
              onLoad={(dataSource) => {
                if (dataSource.length && dataSource[0].status === "completed") {
                  onTestSuccessFinish?.();
                }
              }}
              testId={test.id}
              poolParticipantId={pool_participant_id}
              toolbar={{
                title: (
                  <Typography.Title level={5}>
                    Результаты теста
                  </Typography.Title>
                ),
                actions: [
                  test.show_results_format === "indicate" && (
                    <Button
                      key={"more"}
                      type={"link"}
                      onClick={() => setIsModalVisible(true)}
                    >
                      Подробнее
                    </Button>
                  ),
                ],
                settings: [],
              }}
              columns={[
                { dataIndex: "attempt_number" },
                { dataIndex: "spent_time" },
                { dataIndex: ["result", "current_score"] },
                { dataIndex: "ended_at" },
                { dataIndex: "status" },
              ]}
            />
          </TableStyledWrapper>
        )}
        {screens.sm === false && <TestResult test_id={test.id} />}
      </Space>
    </>
  );
};

export default TestDescriptions;
export type { TestDescriptionsProps };
