import { MemberImport } from "@/entities/import/lib/model";
import { Alert, Space, Typography, theme } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export type ImportResultProps = {
  result: MemberImport["result"];
};

const ButtonPoolParticipantResult: React.FC<ImportResultProps> = ({
  result,
}) => {
  const { token } = theme.useToken();

  if (result.pools && !Array.isArray(result.pools)) {
    result.pools = [result.pools];
  }

  return (
    <Space
      direction={"vertical"}
      style={{
        width: "100%",
        paddingBlock: token.padding,
        maxHeight: "50vh",
        overflow: "auto",
      }}
    >
      {result.pools
        ? (result.pools ?? []).map((result, index) => {
            return (
              <Space
                key={index}
                direction={"vertical"}
                style={{ width: "100%" }}
              >
                {result.added_pool_participant && (
                  <Alert
                    message={
                      <Typography.Text>
                        {result.added_pool_participant.text}
                      </Typography.Text>
                    }
                    showIcon
                    type={"success"}
                    description={
                      <Space direction={"vertical"}>
                        <Typography.Text>
                          Обучение назначено в потоках:
                        </Typography.Text>
                        {result.added_pool_participant.pools?.map(
                          (pool, index) => (
                            <Link
                              key={index}
                              to={`/manage/courses/${pool.course_id}/pools/${pool.pool_id}`}
                            >
                              {pool.pool_name}
                            </Link>
                          ),
                        )}
                      </Space>
                    }
                  />
                )}
                {result.no_added_pool_participant && (
                  <Alert
                    message={result.no_added_pool_participant}
                    showIcon
                    type={"error"}
                  />
                )}
              </Space>
            );
          })
        : "При импорте не требовалось назначать обучение"}
    </Space>
  );
};

export default ButtonPoolParticipantResult;
