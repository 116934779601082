import {
  ProDescriptions,
  ProDescriptionsProps,
} from "@ant-design/pro-components";
import { Typography } from "antd";
import { Exam } from "@/models";
import { dateSTime, toTime } from "@/shared/dayjs/lib/formats";
import examStatusValueEnum from "../lib/exam-status-value-enum";
import deepmergeProDescriptionsProps from "@/shared/ant-design-pro-components/descriptions/lib/deepmerge-props";

type ExamsDescriptionsProps = ProDescriptionsProps<Exam, any>;

const ExamDescriptions: React.FC<ExamsDescriptionsProps> = ({ ...props }) => {
  const defaultProps: Partial<typeof props> = {
    columns: [
      {
        title: <Typography.Text strong>Время</Typography.Text>,
        dataIndex: "spent_time",
        render: (_, { spent_time }) => {
          return spent_time ? toTime(spent_time) : "-";
        },
      },
      {
        title: <Typography.Text strong>Дата выполнения</Typography.Text>,
        dataIndex: "ended_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
      },
      {
        title: <Typography.Text strong>Балл</Typography.Text>,
        dataIndex: ["result", "current_score"],
      },
      {
        title: <Typography.Text strong>Результат</Typography.Text>,
        dataIndex: "status",
        valueType: "select",
        valueEnum: examStatusValueEnum,
      },
    ],
  };

  props = deepmergeProDescriptionsProps(defaultProps, props);
  return <ProDescriptions {...props} />;
};

export default ExamDescriptions;
