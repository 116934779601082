import React from "react";
import EmbedIsAnType from "@/entities/tree-node/lib/is-an-type";
import { theme } from "antd";

type EmbedIconProps = {
  type: EmbedIsAnType;
};

const iconsGlob = import.meta.glob<React.FC<any>>("@/entities/*/ui/icon.tsx", {
  eager: true,
  import: "default",
});

const TreeNodeIcon: React.FC<EmbedIconProps> = ({ type }) => {
  const Icon = iconsGlob[`/src/entities/${type}/ui/icon.tsx`];
  const { token } = theme.useToken();
  const getColorIcon = () => {
    switch (type) {
      case "lesson":
        return token.colorWarning;
      case "section":
        return token.colorPrimary;
      case "test":
        return token.colorSuccess;
      case "scorm":
        return token.colorError;
      case "longread":
        return token.colorWarning;
      default:
        throw new Error("Unsupported type");
    }
  };

  return <Icon style={{ color: getColorIcon() }} />;
};

export default TreeNodeIcon;
export type { EmbedIconProps };
