import React from "react";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Flex, Modal } from "antd";
import { deepmerge } from "deepmerge-ts";
import BroadcastMessagesForm, {
  BroadcastMessagesFormProps,
} from "@/entities/broadcast-messages/ui/form.tsx";

type BroadcastMessagesModalFormProps = BroadcastMessagesFormProps & {
  trigger?: React.ReactElement;
};

const BroadcastMessagesModalForm: React.FC<BroadcastMessagesModalFormProps> = ({
  rest,
  trigger,
  ...formProps
}) => {
  const [open, setOpen] = React.useState(false);

  const triggerText = rest.type === "create" ? "Создать" : "Редактировать";
  let disabled = rest.type === "update" && rest.recordKey === undefined;
  if (rest.type === "update" && typeof rest.recordKey === "object") {
    disabled = rest.recordKey.length !== 1;
  }

  const title =
    rest.type === "create"
      ? "Создание широковещательного сообщения"
      : "Редактирование широковещательного сообщения";

  if (rest.type === "update") {
    const restOnAfterUpdate = rest.onAfterUpdate;
    rest.onAfterUpdate = async (values) => {
      setOpen(false);
      await restOnAfterUpdate?.(values);
    };
  }

  if (rest.type === "create") {
    const restOnAfterCreate = rest.onAfterCreate;
    rest.onAfterCreate = async (values) => {
      setOpen(false);
      await restOnAfterCreate?.(values);
    };
  }

  const defaultFormProps: typeof formProps = {
    submitter: {
      render: (_, dom) => {
        return <Flex justify={"end"}>{dom}</Flex>;
      },
    },
  };

  formProps = deepmerge(defaultFormProps, formProps);

  return (
    <>
      {trigger ? (
        React.cloneElement(trigger, {
          disabled,
          onClick: () => setOpen(true),
        })
      ) : (
        <Button
          type={"primary"}
          disabled={disabled}
          onClick={() => setOpen(true)}
        >
          {triggerText}
        </Button>
      )}
      <Modal
        width={800}
        open={open}
        title={title}
        destroyOnClose
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <BroadcastMessagesForm {...formProps} rest={rest} />
      </Modal>
    </>
  );
};

export default BroadcastMessagesModalForm;
export type { BroadcastMessagesModalFormProps };
