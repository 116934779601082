import React from "react";
import WithAuth from "@/shared/components/with-auth";
import { Outlet } from "react-router-dom";
import SentryUserFeedbackModal from "@/shared/sentry/ui/user-feedback-modal.tsx";
import { FloatButton } from "antd";
import { BugOutlined } from "@ant-design/icons";

const Context: React.FC = () => {
  return (
    <>
      <SentryUserFeedbackModal
        modal
        trigger={<FloatButton icon={<BugOutlined />} />}
      />
      <WithAuth type={"required"}>
        <Outlet />
      </WithAuth>
    </>
  );
};

export default Context;
