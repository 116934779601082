import { IndicatorContentMetadata } from "@/pages/space/manage/dashboards/indicator-content-metadata.ts";
import React from "react";

type Indicator = IndicatorContentMetadata & {
  component: React.FC;
};

const indicatorsMetadata = import.meta.glob<IndicatorContentMetadata>(
  "@/pages/space/manage/dashboards/indicator-content-*.tsx",
  {
    eager: true,
    import: "metadata",
  },
);

const indicatorsComponents = import.meta.glob(
  "@/pages/space/manage/dashboards/indicator-content-*.tsx",
  {
    eager: true,
    import: "default",
  },
);

export const indicators: Indicator[] = Object.entries(indicatorsMetadata)
  .map(([path, metadata]) => {
    const component = indicatorsComponents[path] as React.FC;
    return { ...metadata, component };
  })
  .sort((a, b) => a.position - b.position);
