import React from "react";
import { Result } from "antd";

const NotFoundResult: React.FC = () => {
  return (
    <Result
      status="404"
      title="404 Пространство не найдено"
      subTitle="Извините, данное пространство не найдено. Если вы считаете, что это ошибка, обратитесь в службу поддержки."
    />
  );
};

export default NotFoundResult;
