import React from "react";
import {
  ProDescriptions,
  ProDescriptionsProps,
} from "@ant-design/pro-components";
import { License } from "@/models.ts";
import statusValueEnum from "@/entities/license/lib/status-value-enum.tsx";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import bytesToHumanReadable from "@/shared/helpers/bytes-to-human-readable.ts";

type LicenseDescriptionsProps = ProDescriptionsProps<License> & {
  dataSource: License;
};

const LicenseDescriptions: React.FC<LicenseDescriptionsProps> = ({
  ...props
}) => {
  return (
    <ProDescriptions<License>
      {...props}
      layout="horizontal"
      column={1}
      columns={[
        {
          dataIndex: "status",
          title: "Статус",
          valueEnum: statusValueEnum,
        },
        {
          dataIndex: "expires_at",
          valueType: "dateTime",
          fieldProps: { format: dateSTime },
          title: "Дата-время истечения активации",
        },
        {
          dataIndex: "max_active_members",
          title: "Максимальное количество активных участников",
          renderText: (text) => (text ? text : "Не ограничено"),
        },
        {
          dataIndex: "max_memory_usage",
          title: "Максимальный объем используемой памяти",
          renderText: (value: number) =>
            value ? bytesToHumanReadable(value) : "Не ограничено",
        },
      ]}
    />
  );
};

export default LicenseDescriptions;
