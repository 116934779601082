import { License } from "@/models";
import { ProSchema } from "@ant-design/pro-utils/es/typing";
import { Tag } from "antd";

const statusValueEnum: ProSchema<License>["valueEnum"] = {
  active: {
    text: <Tag color={"green"}>Активно</Tag>,
  },
  expired: {
    text: <Tag color={"red"}>Истекло</Tag>,
  },
  canceled: {
    text: <Tag color={"orange"}>Отменено</Tag>,
  },
};

export default statusValueEnum;
