import { Space, Typography } from "antd";
import { BellOutlined, NotificationOutlined } from "@ant-design/icons";

const valueEnum = {
  banner: {
    text: (
      <Space>
        <NotificationOutlined />
        <Typography.Text>Баннер</Typography.Text>
      </Space>
    ),
  },
  notification: {
    text: (
      <Space>
        <BellOutlined />
        <Typography.Text>Оповещение</Typography.Text>
      </Space>
    ),
  },
};

export default valueEnum;
