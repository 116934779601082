import React, { useRef } from "react";
import { Button } from "antd";
import dayjs from "dayjs";
import {
  ProForm,
  ProFormDateTimePicker,
  ProFormFieldProps,
} from "@ant-design/pro-components";
import { toISOString } from "@/shared/dayjs/lib/formats";

type DateTimePickerWithTimeControlsProps = React.ComponentProps<
  typeof ProFormDateTimePicker
> & {
  listName?: ProFormFieldProps["name"];
};

const DateTimePickerWithTimeControls: React.FC<
  DateTimePickerWithTimeControlsProps
> = ({ fieldProps, listName, ...props }) => {
  const form = ProForm.useFormInstance();
  const selectedDate = useRef<dayjs.Dayjs>();

  const formName = listName ? listName : props.name;

  const onClick = (mode: "start" | "end" | "now") => {
    const now = dayjs();

    let currentSelectedDate = form.getFieldValue(formName);

    if (mode === "now" || !selectedDate.current) {
      currentSelectedDate = now;
    } else {
      currentSelectedDate = selectedDate.current;
    }

    selectedDate.current = currentSelectedDate;

    const date =
      mode === "now"
        ? currentSelectedDate
        : mode === "start"
        ? dayjs(currentSelectedDate).startOf("day")
        : dayjs(currentSelectedDate).endOf("day");

    selectedDate.current = date;

    form.setFieldValue(formName, toISOString(date));
  };

  return (
    <ProFormDateTimePicker
      fieldProps={{
        onPickerValueChange(value) {
          selectedDate.current = value;
        },
        renderExtraFooter: () => {
          return (
            <>
              <Button type="link" onClick={() => onClick("now")}>
                Сейчас
              </Button>
              <Button type="link" onClick={() => onClick("start")}>
                Начало дня
              </Button>
              <Button type="link" onClick={() => onClick("end")}>
                Конец дня
              </Button>
            </>
          );
        },
        needConfirm: false,
        ...fieldProps,
      }}
      {...props}
    />
  );
};

export default DateTimePickerWithTimeControls;
