import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

const Page: React.FC = () => {
  return (
    <Link to="/spaces/create">
      <Button type={"primary"}>Создать пространство</Button>
    </Link>
  );
};

export default Page;
