import React from "react";

type ContextAuthRefreshLoadingValue = {
  isLoading: boolean;
};

const ContextAuthRefreshLoading = React.createContext<
  ContextAuthRefreshLoadingValue | undefined
>(undefined);

export const useAuthRefreshLoading = () =>
  React.useContext(ContextAuthRefreshLoading) as ContextAuthRefreshLoadingValue;

export default ContextAuthRefreshLoading;
export type { ContextAuthRefreshLoadingValue };
