import React from "react";
import {
  ModalForm,
  ModalFormProps,
  ProForm,
  ProFormProps,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import * as Sentry from "@sentry/react";
import { deepmerge } from "deepmerge-ts";
import { Alert, message, Space, Typography } from "antd";
import emailPattern from "@/shared/patterns/lib/email-pattern.tsx";
import axios from "@/axios.ts";

interface SendFeedbackParams {
  message: string;
  name?: string;
  email?: string;
  url?: string;
  source?: string;
}

type SentryFloatButtonPropsModal = {
  modal: true;
} & ModalFormProps<SendFeedbackParams>;

type SentryFloatButtonPropsDefault = {
  modal?: false;
} & ProFormProps<SendFeedbackParams>;

type SentryFloatButtonProps =
  | SentryFloatButtonPropsModal
  | SentryFloatButtonPropsDefault;

const SentryUserFeedbackModal: React.FC<SentryFloatButtonProps> = ({
  modal = false,
  ...props
}) => {
  let FormComponent:
    | typeof ProForm<SendFeedbackParams>
    | typeof ModalForm<SendFeedbackParams> = ProForm<SendFeedbackParams>;
  const user = Sentry.getCurrentScope().getUser();

  const defaultProps: ProFormProps<SendFeedbackParams> = {
    initialValues: {
      email: user?.email,
    },
    onFinish: async (values) => {
      return axios
        .post("/api/support/service-desk-tickets", values)
        .then(() => {
          message.success("Сообщение о технической проблеме отправлено");

          return true;
        })
        .catch(() => {
          message.error("Ошибка при отправке сообщения о технической проблеме");

          return false;
        });
    },
  };

  props = deepmerge(defaultProps, props);

  if (modal) {
    FormComponent = ModalForm<SendFeedbackParams>;

    props = deepmerge(
      {
        title: "Отправка сообщения о технической проблеме",
        submitter: {
          searchConfig: {
            submitText: "Отправить сообщение",
          },
        },
        modalProps: {
          destroyOnClose: true,
        },
      } as ModalFormProps<SendFeedbackParams>,
      props,
    );
  }

  return (
    <FormComponent {...(props as any)}>
      <Alert
        type={"info"}
        message={<Typography.Text strong>Важно</Typography.Text>}
        description={
          <>
            Отправляя данное сообщение, вы привлекаете команду поддержки к
            решению проблемы. Если проблема может быть решена администратором
            пространства – обратитесь к нему.
          </>
        }
        showIcon
        style={{ marginBottom: 16 }}
      />
      <ProFormText
        name={"email"}
        label={
          <Space direction={"vertical"}>
            <Typography.Text strong>Почта</Typography.Text>
            <Typography.Text italic>
              * Почта будет использована для уточнения деталей или уведомления о
              решении
            </Typography.Text>
          </Space>
        }
        required={false}
        rules={[
          { required: true, message: "Это поле обязательно" },
          { type: "email" },
          { pattern: emailPattern, message: "Некорректный формат почты" },
        ]}
      />
      <ProFormText
        name={"title"}
        label={
          <Space direction={"vertical"}>
            <Typography.Text strong>Тема</Typography.Text>
            <Typography.Text italic>* Кратко опишите проблему</Typography.Text>
          </Space>
        }
        required={false}
        rules={[{ required: true, message: "Это поле обязательно" }]}
      />
      <ProFormTextArea
        name={"description"}
        label={
          <Space direction={"vertical"}>
            <Typography.Text strong>Описание</Typography.Text>
            <Typography.Text italic>
              * Как можно подробнее опишите проблему, это поможет нам быстрее ее
              исправить
            </Typography.Text>
          </Space>
        }
        required={false}
        rules={[{ required: true, message: "Это поле обязательно" }]}
      />
    </FormComponent>
  );
};
export default SentryUserFeedbackModal;
export type { SentryFloatButtonProps };
