import React from "react";
import { ServiceAccount } from "@/models";
import {
  ProForm,
  ProFormProps,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { Button, Flex, message, theme } from "antd";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { deepmerge } from "deepmerge-ts";
import { RestProps } from "@/shared/rest/lib/types";
import typeEnumValue from "@/entities/service-account/lib/type-enum-value.ts";
import OneCHRMIntegrationAlert from "./onec-hrm-integration-alert";
import { Secrets } from "@/entities/service-account/ui/secrets-descriptions.tsx";
import emailPattern from "@/shared/patterns/lib/email-pattern.tsx";

type ServiceAccountFormProps = ProFormProps<ServiceAccount> & {
  rest: RestProps<ServiceAccount>;
  showSecretsModal: (secretsData: Secrets) => Promise<void>;
};

type ServiceAccountSecrets = ServiceAccount & Secrets;

const ServiceAccountForm: React.FC<ServiceAccountFormProps> = ({
  rest,
  showSecretsModal,
  ...props
}) => {
  const { token } = theme.useToken();

  const [form] = ProForm.useForm<ServiceAccount>();
  const type = ProForm.useWatch("type", form);

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps.onFinish = async (values) => {
      return axios
        .post<OrionRestCreateResponse<ServiceAccountSecrets>>(
          `/api/service-accounts`,
          values,
        )
        .then((res) => res.data.data)
        .then(async (values) => {
          message.success("Интеграция успешно создана");
          rest.onAfterCreate?.(values);
          showSecretsModal(values);
          return true;
        })
        .catch((err) => {
          console.error(err);
          message.error(
            err.response.data.message ?? "Ошибка при создании интеграции",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<ServiceAccount>>(
          `/api/service-accounts/${rest.recordKey}`,
        )
        .then((res) => res.data.data);
    };
    defaultProps.onFinish = async (values) => {
      return axios
        .put<OrionRestUpdateResponse<ServiceAccount>>(
          `/api/service-accounts/${rest.recordKey}`,
          values,
        )
        .then((res) => {
          message.success("Интеграция успешно обновлена");
          rest.onAfterUpdate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении интеграции: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <>
      <ProForm<ServiceAccount> {...props}>
        <ProFormText
          name={"name"}
          label={"Название"}
          rules={[{ required: true, max: 255 }]}
        />
        <ProFormSelect
          name={"type"}
          label={"Тип"}
          rules={[{ required: true }]}
          valueEnum={typeEnumValue}
        />
        <ProFormText
          name={"email"}
          label={"Почта для системных писем"}
          rules={[
            {
              required: true,
              type: "email",
              max: 255,
            },
            { pattern: emailPattern, message: "Некорректный формат почты" },
          ]}
        />
        {type === "1c:hrm" && (
          <OneCHRMIntegrationAlert
            style={{ marginBottom: token.marginLG }}
            showIcon
          />
        )}
      </ProForm>
    </>
  );
};

export default ServiceAccountForm;
export type { ServiceAccountFormProps };
