import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import axios from "@/axios";
import { Spin } from "antd";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { Automation } from "@/models";

const BreadcrumbItem: React.FC = () => {
  const { automation_id } = useParams();

  const {
    data: automation,
    error,
    isLoading,
  } = useSWR(`/api/automations/${automation_id!}`, async (url) => {
    return axios
      .get<OrionRestShowResponse<Automation>>(url)
      .then((res) => res.data.data);
  });

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!automation) throw new Error("Automation not found");

  return <>{automation.name}</>;
};

export default BreadcrumbItem;
