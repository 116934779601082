import React from "react";
import { message, Modal } from "antd";
import { Member } from "@/models";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";

type MemberResetPasswordButtonProps = {
  membersIds: Member["id"][];
  onAfterFinish: () => void;
};

const MemberResetPasswordButton: React.FC<MemberResetPasswordButtonProps> = ({
  membersIds,
  onAfterFinish,
}) => {
  const [modal, modalHolder] = Modal.useModal();

  const onResetPassword = async () => {
    return modal.confirm({
      title: "Вы уверены, что хотите сбросить пароль?",
      content: "Пользователи смогут войти в систему с новым паролем.",
      onOk: async () => {
        return axios
          .post("/api/members/reset-password", {
            resources: membersIds,
          })
          .then((res) => {
            message.success(res.data.message);
            return onAfterFinish();
          })
          .catch((err) => {
            message.error(err.response.data.message ?? err.message);
            console.error(err);
          });
      },
    });
  };

  return (
    <>
      {modalHolder}
      <Button
        type={"primary"}
        danger
        onClick={onResetPassword}
        disabled={membersIds.length === 0}
      >
        Сбросить пароль
      </Button>
    </>
  );
};

export default MemberResetPasswordButton;
export type { MemberResetPasswordButtonProps };
