import React from "react";
import { Button, Modal } from "antd";
import ReportForm, { ReportFormProps } from "@/entities/report/ui/form";

type ReportButtonProps = ReportFormProps;

const ReportButton: React.FC<ReportButtonProps> = ({ ...props }) => {
  const [open, setOpen] = React.useState(false);

  const onReset = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type={"primary"} onClick={() => setOpen(true)}>
        Выгрузить
      </Button>
      <Modal
        width={600}
        open={open}
        title={"Параметры выгрузки отчета"}
        destroyOnClose
        onCancel={() => setOpen(false)}
        footer={false}
      >
        <ReportForm {...props} onReset={onReset} />
      </Modal>
    </>
  );
};
export default ReportButton;
export type { ReportButtonProps };
