import React from "react";
import OrgStructureContainerContext from "@/entities/org-structure/lib/context";
import { OrgStructureContainersContextValue } from "@/entities/org-structure/lib/container-model";

const useOrgStructureContainer = (): OrgStructureContainersContextValue[] =>
  React.useContext(
    OrgStructureContainerContext,
  ) as OrgStructureContainersContextValue[];

export default useOrgStructureContainer;
