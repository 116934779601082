import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import axios from "@/axios";
import { Spin } from "antd";
import { Test } from "@/entities/test/lib/model";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { Exam } from "@/entities/exam/lib/model";
import { TestParticipant } from "@/models";

const BreadcrumbItem: React.FC = () => {
  const { exam_id } = useParams();
  const { data, error, isLoading } = useSWR(
    `/api/exams/${exam_id!}`,
    async (url) => {
      return axios
        .get<OrionRestShowResponse<Exam>>(url)
        .then((res) => res.data.data)
        .then(async (exam) => {
          return axios
            .get<OrionRestShowResponse<TestParticipant>>(
              `/api/test-participants/${exam.test_participant_id}`,
            )
            .then((res) => res.data.data)
            .then((testParticipant) => {
              return axios
                .get<OrionRestShowResponse<Test>>(
                  `/api/tests/${testParticipant.test_id}`,
                )
                .then((res) => res.data.data);
            });
        });
    },
  );

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!data) throw new Error("Test not found");

  return <>{data.name}</>;
};

export default BreadcrumbItem;
