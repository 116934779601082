import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import * as Sentry from "@sentry/react";

type SyncUserFromAuthToSentryProps = {
  children: React.ReactNode;
};

const SyncUserFromAuthToSentry: React.FC<SyncUserFromAuthToSentryProps> = ({
  children,
}) => {
  const auth = useAuth();

  useEffect(() => {
    const scope = Sentry.getCurrentScope();
    if (!scope) return;
    scope.setUser(
      auth.isAuthenticated
        ? {
            id: auth.user?.profile.sub,
            email: auth.user?.profile.email,
            username: auth.user?.profile.nickname,
          }
        : null,
    );
    scope.setExtra("profile", auth.isAuthenticated ? auth.user?.profile : null);
  }, [auth.isAuthenticated, auth.user]);

  return <>{children}</>;
};

export default SyncUserFromAuthToSentry;
export type { SyncUserFromAuthToSentryProps };
