import { useState } from "react";
import SentryUserFeedbackModal from "@/shared/sentry/ui/user-feedback-modal.tsx";

const useSentryUserFeedbackModal = () => {
  const [open, setOpen] = useState(false);

  const modalHolder = (
    <SentryUserFeedbackModal
      modal={true}
      open={open}
      onOpenChange={(value) => setOpen(value)}
    />
  );

  return [
    {
      open: () => {
        setOpen(true);
      },
    },
    modalHolder,
  ] as const;
};

export default useSentryUserFeedbackModal;
