import React, { useState } from "react";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import { Flex, theme, Space, Tooltip, Typography, message } from "antd";
import {
  DndContext,
  useSensor,
  useSensors,
  DragStartEvent,
  closestCorners,
  MouseSensor,
  DragOverlay,
  DragEndEvent,
  DragOverEvent,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { QuestionCircleOutlined } from "@ant-design/icons";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import SortableTreeList, {
  SWRHook,
  OrgStructureTreeListProps,
} from "@/entities/org-structure/ui/tree-list";
import Item from "@/entities/org-structure/ui/item";
import { t } from "i18next";
import { TreeNode } from "@/models";
import OrgStructureContainerContext from "@/entities/org-structure/lib/context";
import { OrgStructureContainersContextValue } from "@/entities/org-structure/lib/container-model";

const Page: React.FC = () => {
  const { token } = theme.useToken();

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
  );

  const swrRef = React.useRef<SWRHook>(null);

  const [activeItem, setActiveItem] = useState<TreeNode | null>(null);

  const [containers, setContainers] = useState<
    OrgStructureContainersContextValue[]
  >([]);

  const [disableDrag, setDisableDrag] = useState(false);

  const addContainer: OrgStructureTreeListProps["setContainers"] = (
    containerId,
    childItems,
    parentsIds,
  ): void => {
    setContainers((previousContainers) => {
      const existingContainerIndex = previousContainers.findIndex(
        (container) =>
          container.parentsIds.slice(1).join(",") ===
          parentsIds.slice(1).join(","),
      );

      if (existingContainerIndex !== -1) {
        const existingParentsIds =
          previousContainers[existingContainerIndex].parentsIds;

        let newContainers = [...previousContainers];

        if (existingParentsIds.join(",") !== parentsIds.join(",")) {
          newContainers = newContainers.filter(
            (container) =>
              !container.parentsIds
                .join(",")
                .includes(existingParentsIds.join(",")),
          );
        }

        newContainers[existingContainerIndex] = {
          id: containerId,
          items: childItems,
          parentsIds,
        };
        return newContainers;
      }

      return [
        ...previousContainers,
        { id: containerId, items: childItems, parentsIds },
      ];
    });
  };

  const onDragStart = (event: DragStartEvent) => {
    setActiveItem(event.active.data.current as TreeNode);
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (active.id && over?.id && active && over && active.id !== over.id) {
      const activeContainer = containers.find((container) =>
        container.items.find((item) => item.id === active.id),
      );

      const overContainer = containers.find((container) =>
        container.items.find((item) => item.id === over.id),
      );

      if (!activeContainer || !overContainer) return;

      const activeContainerIndex = containers.findIndex(
        (container) => container.id === activeContainer.id,
      );
      const overContainerIndex = containers.findIndex(
        (container) => container.id === overContainer.id,
      );

      const activeItemIndex = activeContainer.items.findIndex(
        (item) => item.id === active.id,
      );

      const overItemIndex = overContainer.items.findIndex(
        (item) => item.id === over.id,
      );

      if (activeItemIndex === -1 || overItemIndex === -1) return;

      if (overContainer.parentsIds.includes(active.id.toString() as any)) {
        return;
      }

      if (activeContainerIndex !== overContainerIndex) {
        let newItems = [...containers];
        const [removeditem] = newItems[activeContainerIndex].items.splice(
          activeItemIndex,
          1,
        );
        newItems[overContainerIndex].items.splice(
          overItemIndex,
          0,
          removeditem,
        );

        if (newItems[activeContainerIndex].items.length === 0) {
          // @ts-ignore
          newItems[activeContainerIndex].items = [{ id: "empty", is_an: {} }];
        }
        setContainers(newItems);
      }
    }
  };

  const onDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    setActiveItem(null);

    if (active.id && over?.id) {
      const activeContainer = containers.find((container) =>
        container.items.find((item) => item.id === active.id),
      );

      const overContainer = containers.find((container) =>
        container.items.find((item) => item.id === over.id),
      );

      if (!activeContainer || !overContainer) return;

      const activeContainerIndex = containers.findIndex(
        (container) => container.id === activeContainer.id,
      );
      const overContainerIndex = containers.findIndex(
        (container) => container.id === overContainer.id,
      );

      const activeItemIndex = activeContainer.items.findIndex(
        (item) => item.id === active.id,
      );

      const overItemIndex = overContainer.items.findIndex(
        (item) => item.id === over.id,
      );

      if (activeItemIndex === -1 || overItemIndex === -1) return;

      if (overContainer.parentsIds.includes(active.id.toString() as any)) {
        message.error(
          "Нельзя перенести подразделение во вложенные подразделения",
        );
        return;
      }

      let newItems = [...containers];

      if (activeContainerIndex === overContainerIndex) {
        let newItems = [...containers];
        newItems[activeContainerIndex].items = arrayMove(
          newItems[activeContainerIndex].items,
          activeItemIndex,
          overItemIndex,
        );

        setContainers(newItems);
      } else {
        const [removeditem] = newItems[activeContainerIndex].items.splice(
          activeItemIndex,
          1,
        );
        newItems[overContainerIndex].items.splice(
          overItemIndex,
          0,
          removeditem,
        );
        setContainers(newItems);
      }

      const activeItemContainer = newItems.find((container) =>
        container.items.find((item) => item.id === active.id),
      );

      const activeItem = activeItemContainer?.items.find(
        (item) => item.id === active.id,
      );

      const activeItemCurrentIndex = activeItemContainer?.items.findIndex(
        (item) => item.id === active.id,
      );

      activeItem!.parent_tree_node_id = activeItemContainer!.id;

      activeItem!.order_index = activeItemContainer?.items[
        activeItemCurrentIndex! - 1
      ]
        ? activeItemContainer?.items[activeItemCurrentIndex! - 1]?.order_index +
          1
        : 0;

      if (activeItem) {
        setDisableDrag(true);
        await axios
          .put<OrionRestIndexResponse<any>>(
            `/api/tree-nodes/${active.id}`,
            activeItem,
          )
          .then((res) => {
            message.success(
              `${t("Подразделение")}/${t("Должность")} успешно перемещено`,
            );
          })
          .catch((err) => {
            message.error(
              err.response.data.message ?? "Ошибка при перемещении",
            );
          })
          .finally(() => {
            swrRef.current?.mutate();
            setDisableDrag(false);
          });
      }
    }
  };

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Flex vertical style={{ width: "100%", height: "calc(100% - 30px" }}>
        <Space
          direction="horizontal"
          style={{
            width: "100%",
            backgroundColor: token.colorBgContainer,
            padding: token.padding,
            marginBottom: token.margin,
          }}
        >
          <Typography.Text>
            Подразделение может являться отдельным Юридическим лицом, цехом,
            сектором, отделом, группой людей или командой.{" "}
            <Tooltip
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={
                "* Подразделение — формальная группа в организации, которая отвечает за выполнение определённого набора задач.\n* Обособленное подразделение (ОП) — это часть компании, которая находится по другому юридическому адресу, но не является самостоятельным юрлицом."
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Typography.Text>
        </Space>
        <Flex
          gap={8}
          style={{
            width: "calc(100vw - 200px - 32px)",
            maxWidth: "calc(1920px - 200px - 32px)",
            height: "100%",
            backgroundColor: token.colorBgContainer,
            padding: token.paddingMD,
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
          >
            <OrgStructureContainerContext.Provider value={containers}>
              <SortableTreeList
                key={"org-structure"}
                disableDrag={disableDrag}
                parentTreeNodeId={null}
                swrRef={swrRef}
                setContainers={addContainer}
                parentsIds={[`null`]}
              />
            </OrgStructureContainerContext.Provider>
            <DragOverlay adjustScale={false}>
              {activeItem && (
                <Item
                  title={activeItem.is_an.name}
                  type={
                    activeItem.is_an_type === "department"
                      ? "department"
                      : "position"
                  }
                  style={{
                    listStyle: "none",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />
              )}
            </DragOverlay>
          </DndContext>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Page;
