import React from "react";
import { Mode } from "@/entities/mode/lib/type.ts";

type ModeContextValue = {
  value: Mode;
  isLoading: boolean;
};

const ModeContext = React.createContext<ModeContextValue | undefined>(
  undefined,
);

export default ModeContext;
export type { ModeContextValue };
