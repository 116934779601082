import { ProSchema } from "@ant-design/pro-utils/es/typing";
import { Space } from "@/entities/space/lib/model";
import { Tag } from "antd";

const statusValueEnum: ProSchema<Space>["valueEnum"] = {
  to_create: {
    text: <Tag color={"blue"}>Создать</Tag>,
  },
  creating: {
    text: <Tag color={"blue"}>Создается</Tag>,
  },
  created: {
    text: <Tag color={"blue"}>Создана</Tag>,
  },
  active: {
    text: <Tag color={"green"}>Активно</Tag>,
  },
  to_update: {
    text: <Tag color={"blue"}>Обновить</Tag>,
  },
  updating: {
    text: <Tag color={"blue"}>Обновляется</Tag>,
  },
  updated: {
    text: <Tag color={"blue"}>Обновлена</Tag>,
  },
  to_deactivate: {
    text: <Tag color={"blue"}>Деактивировать</Tag>,
  },
  deactivating: {
    text: <Tag color={"blue"}>Деактивируется</Tag>,
  },
  deactivated: {
    text: <Tag color={"orange"}>Деактивирована</Tag>,
  },
  to_delete: {
    text: <Tag color={"blue"}>Удалить</Tag>,
  },
  deleting: {
    text: <Tag color={"blue"}>Удаляется</Tag>,
  },
  deleted: {
    text: <Tag color={"red"}>Удалена</Tag>,
  },
};

export default statusValueEnum;
