import * as React from "react";
import { Outlet } from "react-router-dom";
import { Grid, Layout as AntLayout, Result, theme } from "antd";
import Menu from "@/pages/space/manage/menu";
import PageContainer from "@/shared/ant-design/page-container/ui";
import styled from "styled-components";

const { Sider: AntSider } = AntLayout;

const Sider = styled(AntSider)`
  width: 200px;
  height: 100%;
  overflow: auto;
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  @media screen and (min-width: 1920px) {
    left: calc((100% - 1920px) / 2) !important;
  }
`;

const { useBreakpoint } = Grid;

let Layout: React.FC = () => {
  const screens = useBreakpoint();
  const { token } = theme.useToken();

  if (!screens.lg) {
    return (
      <PageContainer
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Result
          status={"warning"}
          title={
            "Мобильное разрешение в данном разделе не поддерживается, перейдите в desktop-версию"
          }
        />
      </PageContainer>
    );
  }

  return (
    <AntLayout hasSider style={{ height: "100%" }}>
      <Sider style={{ background: token.colorBgContainer }}>
        <Menu />
      </Sider>

      <AntLayout.Content
        style={{
          marginLeft: 200,
          width: "100%",
          height: "100%",
        }}
      >
        <Outlet />
      </AntLayout.Content>
    </AntLayout>
  );
};

export default Layout;
