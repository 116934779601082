import React, { useEffect } from "react";
import { Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const Page: React.FC = () => {
  const { course_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/manage/courses/${course_id}?tab=pools`);
  }, []);

  return <Spin />;
};

export default Page;
