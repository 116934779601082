import React, { useState } from "react";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import ServiceAccountsTable from "@/entities/service-account/ui/table.tsx";

const Page: React.FC = () => {
  const [poolTemplateHasColumns, setPoolTemplateHasColumns] =
    useState<boolean>(true);

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <ServiceAccountsTable
        hasQueryParams
        options={{
          setting: {
            draggable: false,
            showListItemOption: false,
          },
          reload: false,
          density: false,
        }}
        columnsState={{
          persistenceKey: "service-account-table",
          onChange: (columnState) =>
            setPoolTemplateHasColumns(
              Object.values(columnState).some(({ show }) => show),
            ),
        }}
        rowSelection={poolTemplateHasColumns ? {} : false}
        columns={[
          { dataIndex: "name" },
          { dataIndex: "type" },
          { dataIndex: "email" },
          { dataIndex: "last_used_at" },
          { dataIndex: "created_at" },
        ]}
      />
    </Flex>
  );
};

export default Page;
