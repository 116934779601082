import React, { useEffect } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import type { ItemType as AntItemType } from "antd/es/menu/interface";

type ItemType = AntItemType & {
  path?: string;
  children?: ItemType[];
};

type NavigationMenuProps = Omit<MenuProps, "items" | "selectedKeys"> & {
  items: ItemType[];
};

const NavigationMenu: React.FC<NavigationMenuProps> = ({ items, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  const itemsMapper = (item: any) => {
    const result = item;

    if (item.path) {
      result.onClick = () => {
        if (location.pathname !== item.path) {
          navigate(item.path);
        }
      };
    }

    if (item.children) {
      result.children = item.children.map(itemsMapper);
    }

    return result;
  };

  items = items.map(itemsMapper);

  useEffect(() => {
    const findObjectByKey = (array: ItemType[]): ItemType | null => {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        if (
          item &&
          item.key &&
          location.pathname.search(
            new RegExp(String.raw`^${item.path}($|\/|\?)`, "g"),
          ) !== -1
        ) {
          return item;
        }

        if (item.children && item.children.length > 0) {
          const foundItem = findObjectByKey(item.children);
          if (foundItem) {
            return foundItem;
          }
        }
      }

      return null;
    };
    const selectedItem = findObjectByKey(items);
    setSelectedKeys(
      selectedItem && selectedItem.key ? [String(selectedItem.key)] : [],
    );
  }, [location]);

  return <Menu items={items} selectedKeys={selectedKeys} {...props} />;
};

export default NavigationMenu;
export type { NavigationMenuProps };
