import React from "react";
import { Button } from "antd";
import { ModalForm, ModalFormProps } from "@ant-design/pro-components";
import { deepmerge } from "deepmerge-ts";
import { ButtonProps } from "antd/es/button";
import PoolParticipantBatchUpdateForm, {
  PoolParticipantBatchUpdateFormProps,
} from "@/entities/pool-participant/ui/form-update-batch";

type PoolParticipantBatchUpdateModalFormProps = ModalFormProps & {
  formProps: PoolParticipantBatchUpdateFormProps;
  triggerProps?: ButtonProps;
  onAfterUpdate?: () => boolean;
};

const PoolParticipantBatchUpdateModalForm: React.FC<
  PoolParticipantBatchUpdateModalFormProps
> = ({ formProps, triggerProps, onAfterUpdate, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const defaultProps: ModalFormProps = {
    open,
    modalProps: { destroyOnClose: true, onCancel: () => setOpen(false) },
    title: "Изменение периода обучения",
    component: () => (
      <PoolParticipantBatchUpdateForm
        {...formProps}
        afterUpdate={(pool) => {
          setOpen(false);
          formProps?.afterUpdate?.(pool);
        }}
      />
    ),
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          if (onAfterUpdate?.()) return;
          setOpen(true);
        }}
        children={"Изменить период обучения"}
        {...triggerProps}
      />
      <ModalForm {...deepmerge(defaultProps, props)} />
    </>
  );
};

export default PoolParticipantBatchUpdateModalForm;
export type { PoolParticipantBatchUpdateModalFormProps };
