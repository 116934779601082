import React, { useEffect } from "react";
import { EditOutlined, HolderOutlined } from "@ant-design/icons";
import { List, Button, theme, Flex, message, Typography } from "antd";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import styled from "styled-components";
import OrgStructureEditInput from "@/entities/org-structure/ui/form";
import OrgStructureDeleteButton from "@/entities/org-structure/ui/delete-button";
import { TreeNode } from "@/models";
import OrgStructureIcon from "@/entities/org-structure/ui/icon";
import { OrgStructureContainersContextValue } from "../lib/container-model";
import useMe from "@/entities/me/lib/use";

const ItemStyledWrapper = styled.div`
  width: 100%;

  .ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-list-item:hover {
    .item-action {
      visibility: visible;
    }
  }

  .item-action {
    visibility: hidden;
  }
`;

type ItemProps = {
  id?: TreeNode["id"] | "empty";
  type?: "department" | "position";
  onClick?: () => void;
  item?: OrgStructureContainersContextValue["items"][number];
  editMode?: boolean;
  title: string;
  style?: React.CSSProperties;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  choized?: boolean;
  onAfterEdit?: () => void;
  toggleEditMode?: (enabled: boolean) => void;
  onDelete?: () => void;
};

const Item = React.forwardRef<HTMLDivElement, ItemProps>(
  (
    {
      onClick,
      title,
      style,
      attributes,
      listeners,
      item,
      choized,
      toggleEditMode,
      type,
      onAfterEdit,
      editMode: editModeProp,
    },
    ref,
  ) => {
    const { token } = theme.useToken();
    const [editMode, setEditMode] = React.useState(false);
    const member = useMe();

    useEffect(() => {
      toggleEditMode?.(editMode);
    }, [editMode]);

    const onAfterUpdate = () => {
      setEditMode(false);
      onAfterEdit?.();
    };

    const textColor = choized ? token.colorWhite : token.colorText;

    return (
      <ItemStyledWrapper
        ref={ref}
        {...(attributes ? attributes : {})}
        style={style}
      >
        {editMode ? (
          <OrgStructureEditInput
            type={type!}
            rest={{
              recordKey: item!.is_an.id,
              type: "update",
              value: title,
              onAfterUpdate: onAfterUpdate,
            }}
          />
        ) : (
          <List.Item
            style={{
              border: "1px solid",
              width: "100%",
              borderColor: token.colorBorder,
              borderRadius: token.borderRadius,
              paddingInline: token.paddingSM - 1,
              paddingBlock: token.paddingXXS,
              marginBlock: token.marginXXS,
              backgroundColor: choized
                ? token.colorPrimary
                : token.colorBgContainer,
            }}
            extra={
              member.permissions.includes("org_structure:update") && (
                <Button
                  size="small"
                  type="text"
                  icon={<HolderOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    color: textColor,
                    cursor: "grab",
                  }}
                  {...(listeners ? listeners : {})}
                />
              )
            }
            onClick={() => onClick?.()}
          >
            <Flex
              align="center"
              justify="space-between"
              style={{ width: "100%" }}
            >
              <Flex gap={token.paddingXS} style={{ maxWidth: "200px" }}>
                <OrgStructureIcon type={type!} style={{ color: textColor }} />
                <Typography.Text
                  style={{ color: textColor }}
                  ellipsis={{ tooltip: true }}
                >
                  {title}
                </Typography.Text>
              </Flex>

              {(listeners || choized) && (
                <Flex gap={token.paddingXXS} className="item-action">
                  {member.permissions.includes("org_structure:update") && (
                    <Button
                      type="text"
                      size="small"
                      icon={
                        <EditOutlined
                          style={{
                            color: textColor,
                          }}
                        />
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        if (editModeProp) {
                          message.warning("Режим редактирования уже включен");
                          return;
                        }
                        setEditMode(true);
                      }}
                      key={"edit"}
                    />
                  )}
                  {member.permissions.includes("org_structure:delete") && (
                    <OrgStructureDeleteButton
                      key={"delete"}
                      onAfterDelete={onAfterEdit}
                      recordKey={item!.is_an.id}
                      type={type!}
                      iconColor={textColor}
                    />
                  )}
                </Flex>
              )}
            </Flex>
          </List.Item>
        )}
      </ItemStyledWrapper>
    );
  },
);

export default Item;
export type { ItemProps };
