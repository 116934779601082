import React, { useEffect } from "react";
import useVersion from "@/entities/version/lib/use.tsx";
import useMode from "@/entities/mode/lib/use.tsx";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

const LayoutInfo: React.FC = () => {
  const version = useVersion();
  const mode = useMode();
  const { i18n } = useTranslation();

  useEffect(() => {
    console.log(
      `%cДобро пожаловать в Синица!\n%cВерсия: ${version.value}\nТип: ${mode.value}\nЯзык: ${i18n.language}`,
      "font-size: 16px;",
      "font-size: 10px;",
    );
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 3,
        right: 3,
        opacity: 0.2,
        zIndex: 9999,
      }}
    >
      <Typography.Text style={{ fontSize: 10 }}>
        {version.value} - {mode.value} – {i18n.language}
      </Typography.Text>
    </div>
  );
};

export default LayoutInfo;
