import React, { useRef } from "react";
import {
  ProFormDigit,
  ProFormInstance,
  ProFormProps,
  ProFormText,
  StepsForm,
} from "@ant-design/pro-components";
import {
  Button,
  Flex,
  message,
  Space as AntdSpace,
  theme,
  Typography,
} from "antd";
import axios from "@/axios";
import { FieldData } from "rc-field-form/es/interface";
import { useNavigate } from "react-router-dom";
import { Space } from "@/models";
import { OrionRestCreateResponse } from "@/shared/types/orion-rest";
import { Content } from "antd/es/layout/layout";
import ProFormPhone from "@/shared/ant-design-pro-components/form-phone/ui/component.tsx";
import useSWR from "swr";
import Spin from "@/shared/components/spin.tsx";
import useMode from "@/entities/mode/lib/use.tsx";

type Record = Space;

type FormProps = ProFormProps<Record>;

const Page: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const formRef = useRef<ProFormInstance>();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, paddingLG, paddingXL },
  } = theme.useToken();
  const mode = useMode();

  const baseHostSWRRes = useSWR("/api/.well-known/base-host", (url) =>
    axios.get<{ value: string }>(url).then((res) => res.data),
  );
  const baseHost = baseHostSWRRes.data?.value;

  const onFinish: FormProps["onFinish"] = async (values) => {
    setLoading(true);

    return axios
      .post<OrionRestCreateResponse<Space>>("/api/spaces", values)
      .then((response) => {
        message.success("Создание пространства успешно запущено");
        const space = response.data.data;
        navigate(`/spaces/${space.id}/status`);
      })
      .catch((reason) => {
        message.error(reason.response.data.message);

        formRef.current?.setFields(
          Object.entries(reason.response.data.errors).map(
            ([key, value]): FieldData => ({
              name: key,
              errors: value as string[],
            }),
          ),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (baseHostSWRRes.isLoading) return <Spin />;
  if (baseHostSWRRes.error) throw baseHostSWRRes.error;
  if (!baseHost) throw new Error("No baseHost");

  return (
    <AntdSpace direction={"vertical"} align="center">
      <Typography.Title level={1}>Создайте пространство</Typography.Title>
      <Content>
        <AntdSpace
          style={{
            padding: paddingXL,
            backgroundColor: colorBgContainer,
            paddingBottom: paddingLG,
          }}
        >
          <StepsForm<Record>
            formRef={formRef}
            submitter={{
              render: ({ onSubmit, step, onPre, form }) => {
                return (
                  <AntdSpace direction={"vertical"}>
                    <AntdSpace.Compact block direction={"horizontal"}>
                      <Flex gap={16} style={{ width: "100%" }}>
                        {[
                          step > 0 && (
                            <Button
                              key="pre"
                              block
                              size="large"
                              onClick={() => {
                                onPre?.();
                              }}
                            >
                              Назад
                            </Button>
                          ),
                          <Button
                            key="next"
                            type="primary"
                            block
                            loading={loading}
                            size="large"
                            onClick={() => {
                              onSubmit?.();
                            }}
                          >
                            {step === 1 ? "Создать" : "Далее"}
                          </Button>,
                        ]}
                      </Flex>
                    </AntdSpace.Compact>
                    <Typography.Text type={"secondary"}>
                      * Указанные данные можно будет изменить в профиле компании
                    </Typography.Text>
                  </AntdSpace>
                );
              },
            }}
            onFinish={onFinish}
            containerStyle={{ width: "612px" }}
            formProps={{
              initialValues: {
                slug: mode.value === "self-hosted" ? "unknown" : undefined,
              },
            }}
            stepsFormRender={(form, submitter) => {
              return (
                <AntdSpace>
                  <Flex vertical>
                    {form}
                    {submitter}
                  </Flex>
                </AntdSpace>
              );
            }}
          >
            <StepsForm.StepForm loading={loading}>
              <ProFormText
                name="contact_full_name"
                label="ФИО"
                rules={[
                  { required: true, max: 255 },
                  {
                    pattern: /^[^\s]+ [^\s]+( [^\s]+)?\s*$/,
                    message:
                      "Поле должно содержать Имя и Фамилию, Отчество — опционально",
                  },
                ]}
              />
              <ProFormText
                name="contact_email"
                label="Почта"
                rules={[{ required: true, max: 255 }, { type: "email" }]}
              />
              <ProFormPhone
                name="contact_phone_number"
                label="Телефон"
                rules={[{ required: true }]}
              />
            </StepsForm.StepForm>
            <StepsForm.StepForm loading={loading}>
              <ProFormText
                name="company_name"
                label="Название компании"
                rules={[{ required: true, max: 255 }]}
              />
              <ProFormDigit
                name="company_employees_count"
                label="Количество сотрудников"
                rules={[{ required: true }]}
                min={0}
              />
              <ProFormText
                hidden={mode.value === "self-hosted"}
                label="Адрес пространства"
                name="slug"
                rules={[
                  { required: true },
                  { min: 3, max: 255 },
                  {
                    pattern: /^[a-z0-9-]*$/i,
                    message:
                      "Поддерживаются только латинские буквы в нижнем регистре, цифры и тире",
                  },
                ]}
                fieldProps={{
                  suffix: `.${baseHost}`,
                }}
                tooltip={
                  "Адрес пространства - это доменное имя, по которому вы сможете войти в пространство"
                }
              />
            </StepsForm.StepForm>
          </StepsForm>
        </AntdSpace>
      </Content>
    </AntdSpace>
  );
};

export default Page;
