import React from "react";
import { ButtonProps } from "antd/es/button";
import { Course } from "@/models";
import { deepmerge } from "deepmerge-ts";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { Alert, message, Modal } from "antd";

type CourseStatusChangeButtonProps = ButtonProps & {
  afterUpdate?: () => unknown | Promise<unknown>;
  status: "to_archive" | "active";
} & (
    | {
        recordKey: Course["id"];
        recordKeys?: undefined;
      }
    | {
        recordKey?: undefined;
        recordKeys: Course["id"][];
      }
  );

const CourseStatusChangeButton: React.FC<CourseStatusChangeButtonProps> = ({
  recordKey,
  recordKeys = [],
  afterUpdate,
  status,
  ...props
}) => {
  const [modal, modalHolder] = Modal.useModal();

  if (recordKey) {
    recordKeys.push(recordKey);
  }

  const buttonText =
    status === "to_archive" ? "Архивировать" : "Разархивировать";
  const isArchiving = status === "to_archive";

  const onArchive = () => {
    modal.confirm({
      title: `Вы уверены, что хотите ${buttonText.toLocaleLowerCase()} выбранные курсы?`,
      okText: buttonText,
      okType: "danger",
      content: isArchiving && (
        <Alert
          type="error"
          message="При архивировании все потоки выбранных курса/курсов будут завершены"
          showIcon
        />
      ),
      cancelText: "Отмена",
      onOk: async () => {
        let data = recordKeys.reduce((acc, id) => {
          acc[id] = { status };
          return acc;
        }, {} as { [key: string]: Partial<Course> });

        await axios
          .patch(`/api/courses/batch`, {
            resources: data,
          })
          .then(async () => {
            afterUpdate?.();
          })
          .catch((err) => {
            message.error(
              err.response.data.message ?? "Ошибка изменения статуса",
            );
          });
      },
    });
  };

  const defaultProps: ButtonProps = {
    danger: isArchiving,
    type: "primary",
    onClick: onArchive,
    disabled: recordKeys.length === 0,
  };

  props = deepmerge(defaultProps, props);

  return (
    <>
      {modalHolder}
      <Button {...props}>{props.children ?? buttonText}</Button>
    </>
  );
};

export default CourseStatusChangeButton;
export type { CourseStatusChangeButtonProps };
