import { Import } from "@/entities/import/lib/model";
import { Alert, Space, theme, Typography } from "antd";
import React from "react";

export type ImportResultProps = {
  result: Import["result"];
};

const ButtonDefaultResult: React.FC<ImportResultProps> = ({ result }) => {
  const { token } = theme.useToken();

  if (!result) result = [];
  if (!Array.isArray(result)) result = [result];

  return (
    <Space
      direction={"vertical"}
      style={{
        width: "100%",
        paddingBlock: token.padding,
        maxHeight: "50vh",
        overflow: "auto",
      }}
    >
      {(result ?? []).map((result, index) => {
        if (result.errors) {
          return (
            <Alert
              key={index + result.message}
              message={result.message}
              showIcon
              type={"error"}
              description={
                <Space direction={"vertical"}>
                  {result.errors.map((error, index) => (
                    <Typography.Text key={error + index}>
                      {error}
                    </Typography.Text>
                  ))}
                </Space>
              }
            />
          );
        } else if (
          result.operation === "update" ||
          result.operation === "create"
        ) {
          return (
            <Alert
              key={index + result.message}
              message={result.message}
              showIcon
              type={result.operation === "create" ? "success" : "info"}
            />
          );
        }
      })}
    </Space>
  );
};

export default ButtonDefaultResult;
